import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getProgramOverviewData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const GridContainer = styled(Grid)`
    padding: 30px 20px 10px 20px;
`

const HeaderTypography = styled(Typography)`
    color: ${(props) => props.theme.sidebar.background};
`

const BodyTypography = styled(Typography)`
    color: grey;
    font-size: 14px;
`
const Greyul = styled.ul`
    color: grey;
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

function ProgramOverviewTab() {

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        //setLoading(true);
        try {
            const result = await getProgramOverviewData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Program Overview --> ', result.data);
                setPageData(result.data);
            }

        } catch (e) {
            console.log("Some error occurred while fetching program overview data")
        }
        //setTimeout(() => {setLoading(false)}, 200);
    }


    return (
        pageData ?
        <Card p={10}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={5}>
                        <GridContainer item xs={12} sm={6} md={6}>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Organization:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.org}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Name of the Program:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.program}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Contact Person(s):
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {pageData.contact_persons.map((person) =>
                                    <li>
                                        <BodyTypography variant="h6">
                                            {`${person.name}, ${person.desig}`}
                                        </BodyTypography>
                                    </li>
                                )}
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Objectives of the Program:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.objective}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Program Delivery:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {pageData.program_delivery.map((text) =>
                                    <li>
                                        <BodyTypography variant="h6">
                                            {text}
                                        </BodyTypography>
                                    </li>
                                )}
                            </Greyul>
                        </GridContainer>
                        {/*Second Column*/}
                        <GridContainer item xs={12} sm={6} md={6}>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Venue:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.venue}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Target Audiences:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.target_audiences.type}
                                    </BodyTypography>
                                </li>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.target_audiences.categories.join(", ")}
                                    </BodyTypography>
                                </li>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.target_audiences.countries.join(", ")}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Time Taken for the Program to be Completed:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.time_taken}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Cost of Use:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.cost_of_use}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Year of Implementation and Status:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.year_of_implement}
                                    </BodyTypography>
                                </li>
                                <li>
                                    <BodyTypography variant="h6">
                                        {pageData.status}
                                    </BodyTypography>
                                </li>
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    Reference Frameworks:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {
                                    pageData.frameworks.length == 0 ?
                                        <li>
                                            <BodyTypography variant="h6">
                                                None Used
                                            </BodyTypography>
                                        </li> :
                                        pageData.frameworks.map((text) =>
                                            <li>
                                                <BodyTypography variant="h6">
                                                    {text}
                                                </BodyTypography>
                                            </li>
                                        )
                                }
                            </Greyul>
                        </GridContainer>
                    </Grid>
                    <LoaderWithBackDrop loading={loading} />
                </Box>
        </CardContent>
    </Card>
    : <LoaderWithBackDrop loading={loading} />
    );
}

export default ProgramOverviewTab;