import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getEmployeesList = async (org_code, page_no, rows_per_page, order, order_by, search_string = '') => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/list',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        page_no,
        rows_per_page,
        order,
        order_by,
        search_string
    };
    return invokeApi(requestObj);
}

export const getEmployeesClassesList = async (org_code, page_no, rows_per_page, order, order_by) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/classes/list',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        page_no,
        rows_per_page,
        order,
        order_by
    };
    return invokeApi(requestObj);
}

export const getNonOrgProfilesList = async (org_code, page_no, rows_per_page) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/non_org/profiles/list',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        page_no,
        rows_per_page
    };
    return invokeApi(requestObj);
}

export const getPendingApprovalsList = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/pending_approvals_list',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getPendingActivationsList = async (org_code, page_no, rows_per_page, order, order_by, search_string = '') => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/list_pending_activation',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        page_no,
        rows_per_page,
        order,
        order_by,
        search_string
    };
    return invokeApi(requestObj);
}

export const resendActivation = async (org_code, email) => {
    console.log("")
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/resend_activation',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        email
    };
    return invokeApi(requestObj);
}

export const revokeLicense = async (org_code, email) => {
    console.log("")
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/employees/revoke_license',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        email,
    };
    return invokeApi(requestObj);
}
