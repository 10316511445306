import React, {useState} from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";

import Helmet from "react-helmet";

import {
    CardContent,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {makeStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import {toastWarning} from "../../utils/utils";
import {changePassword} from "../../services/auth.service";
import {store} from "react-notifications-component";
import {submitContactUsRequest} from "../../services/misc.service";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
    btn: {
        marginTop: 16
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(8, 15, 10),
        overflowY: 'auto'
    },
}));

function ContactUs() {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },
        onSubmit: async (values, { resetForm }) => {

            setLoading(true);

            try {
                const result = await submitContactUsRequest(values.name, values.email, values.message);
                if (result.data && result.data.success) {
                    store.addNotification({
                        title: "Request Submitted",
                        message: "Successfully Submitted, someone will contact back you soon regarding your query. \nThank you",
                        type: "success",
                        insert: "bottom",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 2000,
                            showIcon: true
                        }
                    });
                    resetForm();
                }


            } catch (e) {
                // already toasted the error
            }

            setLoading(false);
        }
    });

    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Contact Us" />
            <Typography variant="h3" gutterBottom display="inline">
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'contact_us')}`}
            </Typography>


            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={6}>

                    <div className={classes.modalPaper}>
                        {/*<h3 id="transition-modal-title">Change Password</h3>*/}
                        <br/>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="name"
                                        label="Name"
                                        type="text"
                                        id="name"
                                        autoComplete="current-password"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        type="text"
                                        id="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="message"
                                        label= {`${GET_APP_LOCALIZE_TEXT(pageLc, 'contact_us__message')}`}
                                        type="text"
                                        multiline={true}
                                        rows={6}
                                        id="message"
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                    />
                                </Grid>

                            </Grid>
                            <Button
                                style={{
                                    marginTop: 24,
                                    marginLeft: '30%',
                                    width: '40%'
                                }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Send
                            </Button>

                        </form>
                    </div>

                </Grid>
            </Grid>

            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default ContactUs;
