import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components/macro";
import {makeStyles} from "@material-ui/core/styles";
import { CSVLink } from 'react-csv';
import {useLocation} from "react-router-dom";

import Helmet from "react-helmet";

import {
    Grid,
    Divider as MuiDivider,
    Typography,
    TextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import TopSectionBadge from "./components/topSectionBadge.comp";
import {LicensesInfoService, LicensesPacksListService, PostUsersCVS} from "../../services/licenses.service";
import {useAuth} from "../../context/auth";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {toastDanger, toastSuccess, toastWarning} from "../../utils/utils";
import {ExcelRenderer} from "../../utils/excel_parser";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../utils/constants";
import ProfilesDataPreviewModal from "./components/profilesDataPreviewModal.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme)=>({
    imageDiv: {
        height: 60,
        marginTop: -10,
        cursor: "pointer"
    },
    section2_header: {
        textAlign: "left",
        margin: "20px 0px"
    },
    section3_header: {
        textAlign: "left",
        margin: "20px 0px",
        color: 'darkred',
    },
    error_logs: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'darkred',
            },
        },
    },
    csv_buttons: {
        marginLeft: '12px'
    }
}));

const employees_csv_columns = [
    {name: 'First Name', key: 0, json_key: 'first_name'},
    {name: 'Last Name', key: 1, json_key: 'last_name'},
    {name: 'Department', key: 2, json_key: 'department'},
    {name: 'Job Title', key: 3, json_key: 'job_title'},
    {name: 'Birthdate', key: 4, json_key: 'birthdate'},
    {name: 'Email Address', key: 5, json_key: 'email_address'},
    {name: 'Gender', key: 6, json_key: 'gender'},
]

const students_csv_columns = [
    {name: 'First Name', key: 0, json_key: 'first_name'},
    {name: 'Last Name', key: 1, json_key: 'last_name'},
    {name: 'Class Name', key: 2, json_key: 'class_name'},
    {name: 'Grade Level', key: 3, json_key: 'grade_level'},
    {name: 'Birthdate', key: 4, json_key: 'birthdate'},
    {name: `Parent's Email Address`, key: 5, json_key: 'email_address'},
    {name: 'Gender', key: 6, json_key: 'gender'},
]

function Licenses() {

    const classes = useStyles();

    const { authUser } = useAuth();
    const fileInput = useRef();
    const csvLink = useRef()

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [licensesInfo, setLicensesInfo] = useState({
        total_licenses: 0,
        used_licenses: 0,
    });
    const [errors, setErrors] = useState([]);
    const [csvData, setCsvData] = useState([
        ["First Name", "Last Name", "Department", "Job Title", "Birthdate (DD/MM/YYYY)", "Email Address", "Gender (Male / Female / Other)"],
        ["Adam", "Mike", "IT", "Manager","15/10/2002","mike@dq.com","male"]
    ]);

    const [openDataPreview, setOpenDataPreview] = useState(false);
    const [previewCols, setPreviewCols] = useState([]);
    const [previewRows, setPreviewRows] = useState([]);
    const [excelDataJson, setExcelDataJson] = useState({});
    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);

    useEffect( () => {
        loadLicensesInfoData().then(d => console.log('licenses info data loaded!', d));
        if (org_type+'' === ORG_TYPE.SCHOOL+'') {
            setCsvData([
                ["First Name", "Last Name", "Class Name", "Class Level", "Birthdate (DD/MM/YYYY)", "Parent's Email Address", "Gender (Male / Female / Other)"],
                ["Adam", "Mike", "IT", "Grade A","15/10/2011","mike@dq.com","male"]
            ])
        }
    }, []);

    const prepareAndDownloadCsv = async () => {
        csvLink.current.link.click();
    }

    const renderFile = (fileObj) => {
        let csv_columns;
        if (org_type+'' === ORG_TYPE.SCHOOL+'') {
            csv_columns = students_csv_columns;
        } else {
            csv_columns = employees_csv_columns;
        }

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, 'data', (err, resp) => {
            if(err){
                console.log(err);
            }
            else{
                let rows = [];
                for (let i=1; i<resp.rows.length; i++) {
                    if (resp.rows[i].length > 0 && resp.rows[i][0] !== '') {
                        let row = [];

                        for (let col of csv_columns) {
                            let colData = '';
                            if (resp.rows[i][col.key]) {
                                if (col.data_parser) {
                                    colData = col.data_parser(resp.rows[i][col.key]);
                                } else {
                                    if (typeof resp.rows[i][col.key] === 'string') {
                                        colData = resp.rows[i][col.key].trim();
                                    } else {
                                        colData = resp.rows[i][col.key];
                                    }
                                }
                            }

                            row.push(colData);
                        }
                        rows.push(row);
                    }
                }

                setPreviewCols(csv_columns);
                setPreviewRows(rows);


                // prepare data for json to post of server
                let data = [];
                for (let row of rows) {
                    let row_obj = {};
                    for (const [ci, col] of csv_columns.entries()) {
                        row_obj[col.json_key] = row[ci]
                    }
                    data.push(row_obj);
                }
                setExcelDataJson(data);
                console.log('data to post to server -> ', data);

                setOpenDataPreview(true);

                setLoading(false);
            }
        }).then(rs=>console.log('finished processing excel file ==> rs => ', rs));
    }

    const processUploadData = async () => {
        setLoading(true);
        try {
            const rslt = await PostUsersCVS(authUser.org_code, excelDataJson)
            console.log('response from csv upload sevice -> ', rslt);
            if (rslt.data) {
                const {total_licenses, used_licenses, errors} = rslt.data;
                setLicensesInfo({total_licenses, used_licenses});
                setErrors(errors);
                if(errors.length > 0)
                {
                    toastDanger('Server Response!', 'Invalid records found! Scroll down the page to see details.');
                }
                else
                {
                    toastSuccess('File uploaded successfully');
                }
            }
        } catch (e) {
            // error already toasted
        }

        setOpenDataPreview(false);
        setLoading(false);
    }

    const fileHandler = (event) => {
        if(event.target.files.length){
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            setLoading(true);

            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if(['xlsx', 'csv'].includes(fileName.slice(fileName.lastIndexOf('.')+1))){
                renderFile(fileObj)
            }
            else{
                setLoading(false);
                toastWarning('invalid file type! please upload .xlsx/.csv file only');
            }
        }
    }

    const openFileBrowser = () => {
        fileInput.current.click();
    }

    const loadLicensesInfoData = async () => {
        setLoading(true);

        try {

            const result = await LicensesInfoService(authUser.org_code);
            if (result.data) {
                setLicensesInfo(result.data);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Licenses" />

            <ProfilesDataPreviewModal
                open={openDataPreview}
                processData={processUploadData}
                columns={previewCols}
                rows={previewRows}
                handleClose={ () => setOpenDataPreview(false) }
            />

            <Grid container spacing={6}>
                <Grid item xs={4} sm={4} md={2} lg={1} >
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__total_licenses')}`}
                        value={licensesInfo.total_licenses}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__used_licenses')}`}
                        value={licensesInfo.used_licenses}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__unused_licenses')}`}
                        value={licensesInfo.total_licenses - licensesInfo.used_licenses}
                    />
                </Grid>

            </Grid>


            <Divider my={6} />

            <Typography variant="h3" className={classes.section2_header}>
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__add_users')}`}
            </Typography>

            <Typography variant="h5" className={classes.section2_header}>
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__download_the_csv_file')}`}
                <Button
                    className={classes.csv_buttons}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={prepareAndDownloadCsv}
                >
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__download_csv')}`}
                </Button>
                <CSVLink
                    data={csvData}
                    filename='profiles_data.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
            </Typography>

            <Typography variant="h5" className={classes.section2_header}>
                {
                    org_type+'' === ORG_TYPE.SCHOOL+'' ?
                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__fill_out_the_csv_file_completely__school')}`
                    :
                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__fill_out_the_csv_file_completely')}`
                }
            </Typography>

            <Typography variant="h5" className={classes.section2_header}>

                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__upload_the_csv_file')}`}
                <Button
                    className={classes.csv_buttons}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={openFileBrowser}
                >
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__upload_csv')}`}
                </Button>
                <input type="file" hidden onChange={fileHandler} ref={fileInput} onClick={(event)=> { event.target.value = null }} style={{"padding":"10px"}} />
            </Typography>

            <Typography variant="h5" className={classes.section2_header}>
                {
                    org_type+'' === ORG_TYPE.SCHOOL+'' ?
                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__the_system_will_then_send_out_activation_emails__school')}`
                        :
                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__the_system_will_then_send_out_activation_emails')}`
                }
            </Typography>

            <Typography variant="h5" className={classes.section2_header}>
                {GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__as_long_as_you_have_unused_licenses_p1')} <a href="mailto:contact@dqforall.com">contact@dqforall.com</a> {GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__as_long_as_you_have_unused_licenses_p2')}
            </Typography>

            {
                errors.length > 0 ?
                    <>
                        <Divider my={6} />
                        <Typography variant="h3" className={classes.section3_header}>
                            {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__errors')}`}
                        </Typography>
                        <TextField
                            className={classes.error_logs}
                            id="error-logs"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={errors.join('\n')}
                            multiline={true}
                            variant="outlined"
                            style={{width: '100%'}}
                        />
                    </>
                :
                    null
            }
            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default Licenses;
