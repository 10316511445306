import React, {useRef} from "react";
import styled, { withTheme } from "styled-components/macro";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Line } from "react-chartjs-2";
import {calculateRgba} from "react-spinners/helpers";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
`;

const colors = [
  "#C71585",
  "#800080",
  "#00008B",
  "#87CEEB",
  "#0D98BA",
  "#2E8B57",
  "#9ACD32",
  "#FFCC00",
  "#FFA500",
  "#FF5349",
  "#FF0000",
]

const LineChart = React.forwardRef((props, ref) => {

  const {data, radarRef} = props;

  const final_data = {
    labels: data.labels ? data.labels : [],
    datasets: data.graphData ? data.graphData.map((dataItem) => {
      const color = dataItem.index < 0 || dataItem.index > (colors.length-1) ? "" : colors[dataItem.index];
      return {
        label: dataItem.country ? dataItem.country : "",
        borderColor: calculateRgba(color, dataItem.opacity),
        fill: false,
        lineTension: 0.1,
        borderWidth: 4,
        data: dataItem.data ? dataItem.data : [],
        hidden: dataItem.show ? !dataItem.show : false,
      }
    }) : []
  };



  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      },
      onClick: (e, item) => {
        const radarChart = radarRef ? radarRef.current.chartInstance.config.data : undefined;
        const dataItem = radarRef ? radarChart.datasets[item.datasetIndex] : {hidden: false};
        const lineChart = ref.current.chartInstance.config.data;
        const dataItem2 = lineChart.datasets[item.datasetIndex];

        if (item.hidden === false || item.hidden === null) {
          dataItem.hidden = true;
          dataItem2.hidden = true;
        } else {
          dataItem.hidden = false;
          dataItem2.hidden = false;
        }
        if(radarRef) radarRef.current.chartInstance.update();
        ref.current.chartInstance.update();
      },
    },
    responsive: true,
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart'
    // },

    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            color: "rgba(0,0,0,0)",
          },
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          ticks: {
            suggestedMin: 0,
          },
          padding: 100,
        }
      ]
    }
  };

  return (
      <ChartWrapper>
        <Line data={final_data} options={options} ref={ref}/>
      </ChartWrapper>
  );
})

export default LineChart;
