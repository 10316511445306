import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getDqModules, getImpactRatingData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {makeStyles, withStyles} from "@material-ui/core/styles";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));


const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;
  
  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

function Dq24Tab(props) {

    const classes = useStyles();
    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);


    useEffect( () => {
        loadPageData().then(d => console.log('DQ Modules Fetched!', d));
    }, []);

    const loadPageData = async () => {
        //setLoading(true);
        try {
            const result = await getDqModules(authUser.org_code);
            if (result.data) {
                console.log('Result Data of DQ Modules --> ', result.data);
                setPageData(result.data);
            }

        } catch (e) {
            console.log("Some error occurred while fetching dq modules")
        }
        //setTimeout(() => {setLoading(false)}, 200);
    }

    let learning_outcomes = 3

    return (
        pageData ?
        <Card mb={3}>
            <CardContent>
                <ColouredTypography mb={10} variant="h6" color="grey" wrapped="true" font="14px">
                    A module is the unit of the Program that is designed to deliver a minimum of {learning_outcomes} predefined learning outcomes.
                    To be considered a module, the lesson will take more than 20 minutes, but less than 1.5 hours to complete.
                </ColouredTypography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell align="center">Learning Module ID</CustomTableCell>
                            <CustomTableCell align="center">Title of Learning Topic</CustomTableCell>
                            <CustomTableCell align="center">List of Learning Objectives</CustomTableCell>
                            <CustomTableCell align="center">Target Group Age</CustomTableCell>
                            <CustomTableCell align="center">DQ24 Alignment</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageData.map((item, index) => (
                                <CustomTableRow key={index}>
                                    <CustomTableCell component="th" scope="row">
                                        <Bold>{item.module_id}</Bold>
                                    </CustomTableCell>
                                    <CustomTableCell >
                                        <Bold>{item.title}</Bold>
                                        {
                                            item.desc ?
                                            <>
                                                <Bold> - </Bold>
                                                {item.desc}
                                            </>
                                            : null
                                        }
                                    </CustomTableCell>
                                    <CustomTableCell>{item.objectives.join('\n')}</CustomTableCell>
                                    <CustomTableCell>{item.target_age}</CustomTableCell>
                                    <CustomTableCell>{item.alignment}</CustomTableCell>
                                </CustomTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <LoaderWithBackDrop loading={loading} />
            </CardContent>
        </Card>
        :  <LoaderWithBackDrop loading={loading} />
    );
}



export default Dq24Tab;