import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent,
    Link
} from "@material-ui/core";

import { Tab } from "../../../components"
import TabChild from "../../../components/Tabs/header";


import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import DigitalSkillsTab from "./components/DigitalSkills.tab";
import CyberRisksTab from "./components/CyberRisks.tab";

const TabsComponents = (pageLc, org_type) => [
    {
        title: "Digital Skills Level",
        link: `/${pageLc}/${org_type}/home/skills`,
        tag: <DigitalSkillsTab/>,
    },
    {
        title: "Cyber-Risks Level",
        link: `/${pageLc}/${org_type}/home/cyber`,
        tag: <CyberRisksTab/>,
    },

]

const tabsIndices = {
    "skills": 0,
    "cyber": 1,
}

function Baselining() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const org_type = location.pathname.split('/')[2];
    const tab_name = location.pathname.split('/')[4];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Baselining" />
            <Tab
                titles={TabsComponents(pageLc, org_type).map((component) => component.title)}
                links={TabsComponents(pageLc, org_type).map((component) => component.link)}
                value={tabsIndices[tab_name]}
            >
            {TabsComponents(pageLc, org_type).map((component) => component.tag)}
            </Tab>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Baselining;
