import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { spacing } from "@material-ui/system";

import {Bar, Radar} from "react-chartjs-2";
import Button from "@material-ui/core/Button";
import {grey} from "@material-ui/core/colors";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
`;

const colors = [
    "#C7158533",
    "#80008033",
    "#00008B33",
    "#87CEEB33",
    "#0D98BA33",
    "#2E8B5733",
    "#9ACD3233",
    "#FFCC0033",
    "#FFA50033",
    "#FF534933",
    "#FF000033",
]

const RadarChart = React.forwardRef((props, ref) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {data} = props;

    let colorIndex = 0;

    const final_data = {
    labels: data.labels ? data.labels : [],
    datasets: data.graphData ? data.graphData.map((dataItem) => {
        const color = dataItem.index < 0 || dataItem.index > (colors.length-1) ? "" : colors[dataItem.index];
        return {
            label: dataItem.country,
            backgroundColor: color,
            borderColor: color,
            pointBackgroundColor: color,
            pointBorderColor: "#ffffff",
            pointHoverBackgroundColor: "#ffffff",
            pointHoverBorderColor: color,
            pointRadius: 4,
            data: dataItem.data,
            borderWidth: 0,
            hidden: !dataItem.show,
        }
    }) : [],
    };

    const options = {
    maintainAspectRatio: false,
    scale: {
      ticks: {
        beginAtZero: false,
        max: 200,
        min: 95,
      }
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      }
    },
    };

    return (
      <React.Fragment>
          <ChartWrapper>
            <Radar data={final_data} options={options} ref={ref} />
          </ChartWrapper>
      </React.Fragment>
    );
})

export default RadarChart;
