import React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import Switch from '@material-ui/core/Switch';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {logoutUser} from "../../../services/auth.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {

  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const { setAuthToken, setAuthUser } = useAuth();
  const { settings, setSettings } = useGlobalState();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setAuthToken(null);
      setAuthUser(null);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
      // history.push('/login');
    } catch (e) {
      // already toasted the error
    }
  };

  const handleChangePassword = () => {
    setAnchorMenu(null);
    history.push('/password/change');
  }

  const darkModeTrigger = () => {
    const new_mode = !settings.dark_mode;
    setSettings({
      ...settings,
      dark_mode: new_mode
    })
  }


  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleChangePassword}>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'main__change_password')}`}</MenuItem>
        <MenuItem onClick={darkModeTrigger}>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'main__dark_mode')}`}<Switch
            checked={settings.dark_mode}
            name="checkedB"
            color="primary"
        /></MenuItem>
        <MenuItem onClick={handleLogout}>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'main__sign_out')}`}</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
