import React, {useRef} from "react";
import styled, { withTheme } from "styled-components/macro";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Line } from "react-chartjs-2";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
`;

const colors = [
  "#C71585",
  "#800080",
  "#00008B",
  "#87CEEB",
  "#0D98BA",
  "#2E8B57",
  "#9ACD32",
  "#FFCC00",
  "#FFA500",
  "#FF5349",
  "#FF0000",
]

const LineChart = (props) => {

  const {chartRef, data} = props;
  const barRef = useRef();

  const final_data = {
    labels: data.labels ? data.labels : [],
    datasets: data.graphData ? data.graphData.map((dataItem) => {
      const color = dataItem.index < 0 || dataItem.index > (colors.length-1) ? "" : colors[dataItem.index];
      return {
        label: dataItem.country ? dataItem.country : "",
        borderColor: color,
        fill: false,
        lineTension: 0.1,
        borderWidth: 4,
        data: dataItem.data ? dataItem.data : [],
        hidden: dataItem.show ? !dataItem.show : false,
      }
    }) : []
  };



  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      },

    },
    responsive: true,
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart'
    // },

    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            color: "rgba(0,0,0,0)",
          },
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,

          },
          padding: 100,
        }
      ]
    }
  };

  return (
      <ChartWrapper>
        <Line data={final_data} options={options} ref={barRef}/>
      </ChartWrapper>
  );
}

export default LineChart;
