import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tab } from "../../../components"

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import AcquisitionTab from "./components/Acquisition.tab";
import ImprovementTab from "./components/Improvement.tab";
import IntentionTab from "./components/Intention.tab";
import styled from "styled-components/macro";
import {Box, Typography as MuiTypography} from "@material-ui/core";
import {spacing} from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`


const TabsComponents = [
    {
        title: "Skill Acquisition",
        tag: <AcquisitionTab/>,
    },
    {
        title: "Skill Improvement",
        tag: <ImprovementTab/>,
    },
    {
        title: "Intention for change",
        tag: <IntentionTab/>,
    },
]
function Efficacy() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {

    }, []);

    return (
        <React.Fragment>
            <Helmet title="Assessment Efficacy"/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        Section 2: Assessment
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the DQ Impact Rating of the Program
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.3 Efficacy Assessment: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            How well the Program helps improve participants' digital competence across knowledge, skills, and attitudes
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents.map((component) => component.title)}
                >
                {TabsComponents.map((component) => component.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Efficacy;
