import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import AddOrderModal from './AddOrderModal.comp';

import {listAllOrders, withdrawOrder, createOrder} from "../../../services/orders.service";
import {
    LICENSES_ORDER_STATUS,
    LICENSES_ORDER_STATUS_VERBOSE
} from "../../../utils/constants";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import {useAuth} from "../../../context/auth";

import {
    Add as AddIcon,
} from "@material-ui/icons";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {AccountServices} from "../../../services/organization.service";
import {toastWarning} from "../../../utils/utils";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '-20px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#3463ee',
        cursor: 'pointer',
        '&:hover': {
            color: '#53acf5',
        }
    }
}));


function OrdersListView() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [showAddOrderModal, setShowAddOrderModal] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {

            const result = await listAllOrders(authUser.org_code);
            if (result.data && result.data.orders) {
                setPageData(result.data.orders);
                console.log('result.data.orders --> ', result.data.orders);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const openCreateModal = () => {
        setShowAddOrderModal(true);
    }


    const clickActionAddOrder = () => {
        setShowAddOrderModal(true);
    }

    // service to create the order
    const createNewOrder = async (licenses_count, order_details) => {
        setLoading(true);

        try {
            const result = await createOrder(authUser.org_code, order_details, licenses_count);
            if (result.data && result.data.order) {
                let existingData = pageData;
                existingData.push(result.data.order);
                setPageData(existingData);
            }

        } catch (e) {
            // error already toasted
        }

        setShowAddOrderModal(false);
        setLoading(false);
    }

    //service to withdraw order
    const withdrawOrderAction = async (id) => {
        setLoading(true);

        try {
            const result = await withdrawOrder(authUser.org_code, id);
            if (result.data && result.data.success) {
                let existingData = pageData;
                for (let item of existingData) {
                    if (item.id === id) {
                        item.status = LICENSES_ORDER_STATUS.WITHDRAWN
                    }
                }
                setPageData(existingData);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }


    return (
        <React.Fragment>
            <div className={classes.headerInputGroup}>
                <Button variant="contained" color="primary"
                        onClick={clickActionAddOrder}
                >
                    <AddIcon />
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__create_order')}`}
                </Button>
            </div>

            <AddOrderModal
                open={showAddOrderModal}
                createOrderCallback={createNewOrder}
                handleClose={ () => setShowAddOrderModal(false) }
            />

            <Card mb={6}>

                <Paper>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>Sr#</CustomTableCell>
                                <CustomTableCell>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__order_id')}`}</CustomTableCell>
                                <CustomTableCell align="center">{`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__licenses_count')}`}</CustomTableCell>
                                <CustomTableCell align="center">{`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__status')}`}</CustomTableCell>
                                <CustomTableCell align="center">{`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__withdraw')}`}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pageData.map( (item, index) => (
                                    <CustomTableRow key={index} >
                                        <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                        <CustomTableCell>{item.id}</CustomTableCell>
                                        <CustomTableCell align="center">{item.license_count}</CustomTableCell>
                                        <CustomTableCell align="center">{LICENSES_ORDER_STATUS_VERBOSE(pageLc)[item.status]}</CustomTableCell>
                                        <CustomTableCell align="center">
                                            {
                                                item.status === LICENSES_ORDER_STATUS.PENDING ? <Button
                                                        onClick={ () => withdrawOrderAction(item.id) }
                                                        className={classes.d_act_btn}>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__withdraw')}`}</Button> : `${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__not_applicable')}`
                                            }
                                        </CustomTableCell>
                                    </CustomTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <LoaderWithBackDrop loading={loading} />

                </Paper>
            </Card>

        </React.Fragment>

    );
}

export default OrdersListView;