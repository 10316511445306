import {DEFAULT_LOCALE, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../utils/constants";

function urlWithLC(url, locale, replace) {
    const path_parts = url.split('/');
    if(replace)
        path_parts[1] = locale;
    else
        path_parts.splice(1, 0, locale);
    return path_parts.join('/')
}

export const urlRewriteUtil = (location, history) => {
    const currentUrl = location.pathname + location.search;
    const active_lc = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let locale = location.pathname.split('/')[1];
    let replace = false;
    if (!LOCALES_ENABLED.includes(locale)){
        if(locale.length === 1 || locale.length === 2) {
            replace = true;
        }
        locale = undefined;
    }
    if(active_lc)
    {
        if(locale)
        {
            if(active_lc !== locale)
            {
                //Language is changed through URL, store new LC in localstorage
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
                console.log("Language is changed through URL")
            }
        }
        else
        {
            //Rewrite URL with active_lc
            const newUrl = urlWithLC(currentUrl, active_lc, replace);
            history.push(newUrl);
            if(!replace)
            {
                console.log("Local language exists in memory, and no locale in URL")
            }
            else {
                console.log("Local language exists in memory, but wrong locale in URL")
            }
        }
    }
    else
    {
        if(locale)
        {
            //Store locale in localStorage
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            console.log("Correct Locale in URL, but not in memory")
        }
        else
        {
            //Store en as LC in localStorage and rewrite URL
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, DEFAULT_LOCALE);
            const newUrl = urlWithLC(currentUrl, DEFAULT_LOCALE, replace);
            history.push(newUrl);
            if(!replace)
            {
                console.log("No Local language exists in memory, and no locale in URL")
            }
            else {
                console.log("No Local language exists in memory, and wrong locale in URL")
            }
        }
    }


    // const locale = location.pathname.split('/')[1];
    // if (locale.length === 1 || locale.length === 2) {
    //     if (LOCALES_ENABLED.includes(locale)) {
    //         localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
    //     } else {
    //         // replace locale with english
    //         const path_parts = location.pathname.split('/');
    //         path_parts[1] = DEFAULT_LOCALE;
    //         localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, DEFAULT_LOCALE);
    //         history.push(path_parts.join('/'));
    //     }
    // }
    // else {
    //     // check active locale from local-storage
    //     const active_lc = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    //     if (active_lc && active_lc.length === 2 && LOCALES_ENABLED.includes(active_lc)) {
    //         history.push(`/${active_lc}${location.pathname}`);
    //     } else {
    //         localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, DEFAULT_LOCALE);
    //         history.push(`/en${location.pathname}`);
    //     }
    // }
}