import React from "react";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

import {
    Settings,
    Aperture,
    User,
    Users,
    PieChart,
    Mail,
    Package,
    Repeat,
    Home,
    Key,
    BarChart2,
    TrendingUp,
    BookOpen,
} from "react-feather";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../../utils/constants";




const dashboardSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    path: "/home",
    icon: <Home />,
    containsHome: true,
}};

const baselineSection = (locale) => { return {
    //id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    id: "Overview (Baselining)",
    path: "/home",
    icon: <Home />,
    containsHome: true,
}};

const partnerProgramOverview = (locale) => { return {
    // id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    id: 'Program Overview',
    path: "/partner/program_overview",
    icon: <BookOpen />,
    containsHome: false,
}};

const partnerBaseline = (locale, include_cyber = true) => {
    const obj = {
        // id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
        id: 'Baselining',
        path: "/partner/home",
        icon: <Home />,
        containsHome: true,
        children: [
            {
                path: "/partner/home/skills",
                name: `Digital Skills`,
            },
        ],
    }
    if(include_cyber)
    {
        obj.children.push({
            path: "/partner/home/cyber",
            name: `Cyber-Risks`,
        })
    }
    return obj;
};

const partnerAssessment = (locale) => { return {
    // id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    id: 'Assessment',
    path: "/partner/assessment",
    icon: <BarChart2 />,
    containsHome: false,
    children: [
        {
            path: "/partner/assessment/content",
            name: `Content`,
        },
        {
            path: "/partner/assessment/reach",
            name: `Reach`,
        },
        {
            path: "/partner/assessment/efficacy",
            name: `Efficacy`,
        },
    ],
}};

const partnerEnhancement = (locale) => { return {
    // id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    id: 'Enhancement',
    path: "/partner/enhancement",
    icon: <TrendingUp />,
    containsHome: false,
    children: [
        {
            path: "/partner/enhancement/content",
            name: `Content`,
        },
        {
            path: "/partner/enhancement/reach",
            name: `Reach`,
        },
        {
            path: "/partner/enhancement/efficacy",
            name: `Efficacy`,
        },
    ],
}};



const EmployeesSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__employees')}`,
    path: "/employees",
    icon: <User />,
    children: [
        {
            path: "/employees/progress",
            name: "Employees Progress",
        },
        {
            path: "/employees/scores",
            name: "Employees Scores",
        },
        {
            path: "/employees_approval",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__pending_approvals')}`,
        },
    ],
}};

const StudentsSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__students')}`,
    path: "/students",
    icon: <User />,
    children: [
        {
            path: "/students/classes",
            name: "Classes",
        },
        {
            path: "/students/list",
            name: "Students List",
        },
        {
            path: "/students/activation",
            name: "Pending Activations",
        },
        // {
        //     path: "/students/approval",
        //     name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__pending_approvals')}`,
        // },
    ],
}}

const NonOrgSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__dq_stats')}`,
    path: "/non_org",
    icon: <PieChart />,
    children: [
        {
            path: "/non_org_profiles",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__profiles')}`,
        },
        {
            path: "/non_org_stats",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__stats')}`,
        }
    ],
}};

const LicensesSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__licenses')}`,
    path: "/licenses",
    icon: <Key />,
}};

const OrdersSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__orders')}`,
    path: "/orders",
    icon: <Package />,
}};

const ContactUsSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'contact_us')}`,
    path: "/contact_us",
    icon: <Mail />,
}};


const usersSection = {
    id: "Portal Users",
    path: "/users",
    header: "Portal Extras",
    icon: <Users />,
    children: [
        {
            path: "/users/list",
            name: "List",
        }
    ],
};

const uiConfigSection = {
    id: "UI Config",
    path: "/ui_config",
    icon: <Aperture />,
    children: [
        {
            path: "/ui_config/area",
            name: "Areas",
        },
        {
            path: "/ui_config/badge",
            name: "Badges",
        }
    ],
};

const CallbacksSection = {
    id: "Callbacks",
    path: "/callbacks",
    icon: <Repeat />,
};


const SettingsSection = {
    id: "Settings",
    path: "/settings",
    icon: <Settings />,
};




// Routes visible in the sidebar
export const sidebarSections = (location) => {


    const pageLc = location.pathname.split('/')[1];
    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);
    const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_code);

    let sections = [];

    if (org_type+'' === ORG_TYPE.COMPANY+'') {
        sections = [
            baselineSection(pageLc),
            EmployeesSection(pageLc),
            LicensesSection(pageLc),
            OrdersSection(pageLc),
            ContactUsSection(pageLc),
            // usersSection,
            // uiConfigSection,
            // SettingsSection,
        ]
    }
    if (org_type+'' === ORG_TYPE.SCHOOL+'') {
        sections = [
            baselineSection(pageLc),
            StudentsSection(pageLc),
            LicensesSection(pageLc),
            // OrdersSection(pageLc),
            ContactUsSection(pageLc),
            // usersSection,
            // uiConfigSection,
            // SettingsSection,
        ]
    }

    if (org_type+'' === ORG_TYPE.DEFAULT+'') {
        sections = [
            partnerProgramOverview(pageLc),
            partnerBaseline(pageLc, org_code.toLowerCase() !== "amf"),
            partnerAssessment(pageLc),
            partnerEnhancement(pageLc),
            // usersSection,
            // CallbacksSection,
            // SettingsSection,
        ];
    }

    if (org_code && org_code.toLowerCase() === 'dq') {
        sections.push(NonOrgSection(pageLc));
    }

    if (sections.length === 0) {
        sections.push(dashboardSection(pageLc));
    }

    return sections;
};
