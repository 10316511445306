import { invokeApi } from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

export const loginUser = (email, password, remember_me) => {
    // const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/login',
        method : 'POST',
        // headers : {
        //     'xc-dq-locale': locale
        // }

    };
    const postData = {
        email,
        password,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const loginUserViaAccessToken = (token) => {
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/login_via_access_token',
        method : 'POST',
        // headers : {
        //     'xc-dq-locale': locale
        // }
    };
    const postData = {
        token
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const registerUser = (email, password, remember_me) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    // let requestObj = {
    //     path   : '/admin/auth/register',
    //     method : 'POST',
    //     headers : {
    //     'xc-dq-locale': locale
    // }
    // };
    // const postData = {
    //     email,
    //     password,
    //     remember_me
    // };
    // requestObj['postData'] = postData;
    //
    // return invokeApi(requestObj);
}

export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/auth/logout',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        all_devices: true
    };

    return invokeApi(requestObj);
};

export const changePassword = (old_password, new_password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/password/change',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            // 'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        old_password,
        new_password
    };

    return invokeApi(requestObj);
};

export const initResetPassword = (email) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/init/reset/password',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            // 'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        email
    };

    return invokeApi(requestObj);
}

export const resetPassword = (reset_code, password) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);

    let requestObj = {
        path   : '/analytics/auth/password/reset',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            // 'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        reset_code,
        password
    };

    return invokeApi(requestObj);
}




export const confirmAccessCode = (access_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/access_code_confirmation',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            // 'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        access_code
    };

    return invokeApi(requestObj);
}

export const registerCompany = (postData) => {
    let requestObj = {
        path   : '/analytics/auth/register_company',
        method : 'POST'
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const registerSchool = (postData) => {
    let requestObj = {
        path   : '/analytics/auth/register_school',
        method : 'POST'
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const resendAccessCode = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    //const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/analytics/auth/resend_access_code',
        method : 'POST',
        headers : {
            'Authorization' : `Bearer ${token}`,
            // 'xc-dq-locale': locale
        }
    };

    return invokeApi(requestObj);
};

