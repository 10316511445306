import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getCyberRisksData, getDigitalSkillsData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
    Icon, makeStyles, Button as MuiButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import {CustomSelect} from "./Select.comp";
import {X} from "react-feather";
import {toastDanger} from "../../../../utils/utils";
import RadarChart from "./RadarChart";
import MicroBadgeInfoTable from "./MicroBadgeInfoTable.comp";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>


function splitCountries(countries, default_countries = [])
{
    const sublist = []
    const length = countries.length;
    const min_count = 3;

    if(default_countries.length > 0)
    {
        sublist.push(default_countries)
        default_countries.map((country) => countries.indexOf(country)).forEach((removeIndex) => countries.splice(removeIndex, 1)) //Optimized
        sublist.push(countries);
    }
    else
    {
        sublist.push(countries.slice(0, Math.min(min_count, countries.length)));
        sublist.push(countries.slice(Math.min(min_count, countries.length), length));
    }
    return sublist;
}

function getCountriesAverage(countries, avgData) {
    if(countries.length === 0)
        return 0;
    //Else
    let sum = 0;
    countries.forEach((country, index) => {
        sum += Number(avgData[index])
    })
    const avg = Number((sum / countries.length).toFixed(2));
    return avg;
}

function CyberRisksTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    // const [defaultCountries, setDefaultCountries] = useState([]);
    // const [additionalCountries, setAdditionalCountries] = useState([]);
    const [risks, setRisks] = useState([]);

    const [selectedRisks, setSelectedRisks] = useState([]);
    // const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([])
    const [errorMessage, setErrorMessage] = useState('');

    const selectAllRisksOption = "Overall Cyber Risks"
    // const primarySideHeader = "Select a Country"
    const secondarySideHeader= "Select a Cyber Risk"

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        loadPageData().then(d => console.log('Cyber Risks Data Fetched!', d));
    }, []);


    function initializeData(data) {
        setSelectedRisks([data.risks[0]]);
        setSelectedCategories(["National", "Global"])
        // const countriesSubList = splitCountries(data.countries, data.default_countries);
        // setDefaultCountries(countriesSubList[0])
        // setAdditionalCountries(countriesSubList[1]);
        // setSelectedCountries([countriesSubList[0][0]])
        setRisks(data.risks);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getCyberRisksData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Cyber Risks --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("Cyber Risks Data Unavailable");
            }
        } catch (e) {
            console.log("Some error occurred while fetching cyber risks data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: "National Average",
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].national_data.average);
            }, 0.0) / selectedRisks.length : 0,
        },
        // {
        //     title: "Average of selected countries",
        //     value:
        //         pageData && selectedRisks.length > 0 && selectedCountries.length > 0 ?
        //             getCountriesAverage(selectedCountries, selectedCountries.map(
        //                     (country) => selectedRisks.reduce(
        //                         (total, current) => {
        //                             return parseFloat(total) + parseFloat(pageData.data[current].countries_data[country].average);
        //                         }, 0.0) / selectedRisks.length
        //                 )
        //             ) : 0,
        // },
        {
            title: "Global Average",
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].global_data.average);
            }, 0.0) / selectedRisks.length : 0,
        }
    ]

    // function onAddCountry(event, setState) {
    //     if(selectedCategories.concat(defaultCountries).length === 11)
    //     {
    //         toastDanger("Error", 'Maximum adding limit reached. Remove first.');
    //     }
    //     else
    //     {
    //         const country = event.target.value;
    //         removeItemFromState(setAdditionalCountries, country)
    //         addItemToState(setDefaultCountries, country)
    //         onSelectCountry(country, true)
    //
    //         setState((prevState) => ({
    //             ...prevState,
    //             [country]: true,
    //         }));
    //     }
    // }
    //
    // function onRemoveCountry(country, setState) {
    //
    //     removeItemFromState(setDefaultCountries, country)
    //     addItemToState(setAdditionalCountries, country, true)
    //     onSelectCountry(country, false)
    //
    //     setState((prevState) => ({
    //         ...prevState,
    //         [country]: false,
    //     }));
    // }
    //
    // function onSelectCountry(item, isChecked) {
    //     if(isChecked) {
    //         if(!selectedCountries.includes(item)) {
    //             addItemToState(setSelectedCountries, item)
    //         }
    //     }
    //     else
    //     {
    //         if(selectedCountries.includes(item)) {
    //             removeItemFromState(setSelectedCountries, item)
    //         }
    //     }
    // }

    function onSelectIndicator(item, isChecked, state, setState) {

        if(isChecked) {
            setSelectedRisks([item])
            setState(Object.fromEntries(risks.map(
                (risk) => risk == item ? [risk, true] : [risk, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    const lineRef = React.createRef();

    function sumSelectedRisksAnnualScores(category, country = "")
    {
        let temp = []
        selectedRisks.forEach((risk) => {
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    objs.push(...pageData.data[risk].global_data.annual_scores)
                    break;
                case "National":
                    objs.push(...pageData.data[risk].national_data.annual_scores)
                    break;
                case "Country":
                    objs.push(...pageData.data[risk].countries_data[country].annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};

    if(pageData)
    {
        if(pageData)
        {
            //Data Sum For Annual Scores of Countries
            // selectedCountries.forEach((country) => {
            //     summed_scores[country] = {}
            //     const temp = sumSelectedRisksAnnualScores("Country", country)
            //     summed_scores[country].annual_scores = temp.map((obj) => {
            //         return {
            //             year: obj.year,
            //             val: Number((obj.val / obj.count).toFixed(2))
            //         }
            //     })
            // })
            //Data Sum For Annual Scores of Program, Global and National
            selectedCategories.forEach((category) => {
                summed_scores[category] = {}
                const temp = sumSelectedRisksAnnualScores(category)
                summed_scores[category].annual_scores = temp.map((obj) => {
                    return {
                        year: obj.year,
                        val: Number((obj.val / obj.count).toFixed(2))
                    }
                })
            })

            //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
            years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
                (final, current) => {
                    const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                    return final.concat(items_to_add);
                }, [])
            years.sort(function(a, b){return a - b})
        }
    }

    const indicator_header = selectedRisks.length === risks.length ? selectAllRisksOption : (selectedRisks.length === 0 ? "No Risks" : selectedRisks);

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            // primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={[]}
                            secondaryData={risks}
                            // onCheckPrimary={onSelectCountry}
                            onCheckSecondary={onSelectIndicator}
                            // primaryColourize={true}
                            // primaryColourizeIndex={selectedCategories.length}
                            // primaryFooter={CustomSelect}
                            // primaryFooterData={{
                            //     title: "Add Country",
                            //     data: additionalCountries,
                            // }}
                            // primaryFooterCallback={onAddCountry}
                            // primaryAction={X}
                            // primaryActionData={{style: {cursor: "pointer"}}}
                            // primaryActionCallback={onRemoveCountry}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"grey"} align={"center"} alignText={"justify"} py={10}>
                                        What is the level of the exposure to cyber-risks of the target population compared to the
                                        national and global averages?
                                    </ColouredTypography>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        Have experience {indicator_header} in the past year (%)
                                    </ColouredTypography>
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data.map((badge) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/badge_data.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        value={badge.value.toFixed(2)}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                Annual Score Trend
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <LineChart
                                                ref={lineRef}
                                                data={{
                                                    labels: [...years],
                                                    graphData: [...selectedCategories.map((country) => {
                                                        const annual_scores_objs = []
                                                        if(summed_scores[country])
                                                        {
                                                            annual_scores_objs.push(...summed_scores[country].annual_scores)
                                                        }
                                                        const annual_scores_years = annual_scores_objs.map((obj) => obj.year)
                                                        const score_values = years.map((year) => {
                                                            const index = annual_scores_years.indexOf(year)
                                                            if(index === -1)
                                                                return 0;
                                                            else
                                                                return annual_scores_objs[index].val ? annual_scores_objs[index].val : 0;
                                                        })
                                                        return {
                                                            index: [...selectedCategories].indexOf(country),
                                                            country: country,
                                                            data: [...score_values],
                                                            show: selectedCategories.includes(country),
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/*<Button mt={5} variant="contained">*/}
                                    {/*    Download Data*/}
                                    {/*</Button>*/}
                                    <Spacer mb={10}/>
                                    <MicroBadgeInfoTable/>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <MicroBadgeInfoTable/>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default CyberRisksTab;