import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "./Badge.comp";
import HorizontalBarChart from "./HorizontalBarChart";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {getEnhancementContent} from "../../../../services/partner.service";
import styled from "styled-components/macro";
import {useAuth} from "../../../../context/auth";
import {DQ24_Titles} from "../../../../utils/constants";
import XLSX from "xlsx";


const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>



const descriptions = [
    "Digital Citizen Identity",
    "Balanced",
    "Title",
    "Title",
    "Title",
    "Title",
    "Title",
    "Title",
]

function Enhancement() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [selectedDQ, setSelectedDQ] = useState(undefined);
    const [DQs, setDQs] = useState([]);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Enhancement Content Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementContent(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Enhancement Content --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement content data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function initializeData(data) {
        setSelectedDQ(data[0].title)
        setDQs(data.map((item) => item.title))
        setPageData(data);
    }

    const primarySideHeader = "Select a DQ Skill"

    function onSelectDQ (selected_item, is_checked, state, setState) {
        if (is_checked) {
            setSelectedDQ(selected_item)
            setState(Object.fromEntries(DQs.map(
                (dq) => dq == selected_item ? [dq, true] : [dq, false]
            )));
        } else {
            setState((prevState) => ({
                ...prevState,
                [selected_item]: true,
            }));
        }
    }

    const data = pageData ? pageData.filter((item) => item.title == selectedDQ)[0] : undefined;

    const mainChart = {
        header: "",
        labels: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.microBadge;
            }
        ) : [],
        data: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.value;
            }
        ) : [],
        modules: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.associatedModules;
            }
        ) : [],
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const columns = ["Microbadge", "Associated Modules", "Comprehensiveness"]
        if(pageData)
        {
            pageData.forEach((category) => {
                const final_array = [[`Alignment: ${category.alignment}%`], []]
                final_array.push(columns)
                category.graphData.forEach((item) => {
                    const {microBadge = "", associatedModules = "", value = ""} = item;
                    final_array.push([microBadge, associatedModules, value.toString()])
                })
                const worksheet = XLSX.utils.aoa_to_sheet(final_array);
                worksheet["!cols"] = columns.map((column, index) => {
                    return { wch: Math.max(15, column.length) }
                });
                XLSX.utils.book_append_sheet(workbook, worksheet, category.title);
            })
        }
        XLSX.writeFile(workbook, "Enhancement Content.xlsx");
    }

    return (
        pageData ?
            <React.Fragment>
                <Paper px={5} container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={2}>
                            <SidePanel
                                primaryHeader={primarySideHeader}
                                primaryData={DQs}
                                secondaryData={[]}
                                onCheckPrimary={onSelectDQ}
                                onCheckSecondary={() => {}}
                                primarySingleSelectIndex={0}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} align={"right"}>
                            <Card>
                                <CardContent>
                                    <Box component="container" sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={3} align={"center"} alignItems={"center"}>
                                            <Grid item sm={12} md={4} lg={3}>
                                                <ColouredTypography mt={5} mb={5} variant={"body1"} align={"center"} size={"16px"} wrapped={"true"} textAlign={"center"}>
                                                    <Bold>{data.title}: </Bold>{DQ24_Titles[data.title]}
                                                </ColouredTypography>
                                                <Badge
                                                    title="Total Alignment"
                                                    value={`${data.alignment}%`}
                                                />
                                            </Grid>
                                            {/*<Divider orientation="vertical" flexItem />*/}
                                            <Grid item sm={12} md={8} lg={9}>
                                                <Card>
                                                    <CardContent>
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                                                The degree of alignment of the program's learning messages with DQ (%)
                                                            </ColouredTypography>
                                                            <Spacer my={10}/>
                                                            <Grid container>
                                                                <Grid item xs={9}>
                                                                    <HorizontalBarChart
                                                                        labels={mainChart.labels}
                                                                        values={mainChart.data}
                                                                        color={true}
                                                                        threshold={50}
                                                                        suggestedMax={100}
                                                                    />
                                                                </Grid>
                                                                <Grid container item xs={3} alignItems={"space-evenly"} style={{height: "300px"}}>
                                                                    <Grid item xs={12}>
                                                                        <ColouredTypography variant={"h4"} size={"10px"} align={"center"}>
                                                                            Associated Modules
                                                                        </ColouredTypography>
                                                                    </Grid>
                                                                    {mainChart.modules.map((string) => {
                                                                        return <Grid item xs={12}>
                                                                            <ColouredTypography variant={"body1"} color={"grey"}>
                                                                                {string}
                                                                            </ColouredTypography>
                                                                        </Grid>
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Button mt={5} variant="contained" onClick={onClickDownload}>
                                        Download Data
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
                <LoaderWithBackDrop loading={loading} />
            </React.Fragment>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default Enhancement;
