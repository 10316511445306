import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {
    getComprehensivenessData,
} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    Paper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles, Button as MuiButton, Divider,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import HorizontalBarChart from "./HorizontalBarChart";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Grid = styled(MuiGrid)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textalign ? props.textalign : "left"};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function ComprehensiveTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getComprehensivenessData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Comprehensiveness --> ', result.data);
                setPageData(result.data);
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching comprehensiveness data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const final_array = []
        let max_column_width = 10;
        const columns = ["Category", "DQ Code", "Comprehensiveness"]
        final_array.push(columns)
        pageData.digital.concat(pageData.beyond).filter((item) => item.Value !== 0).forEach((item) => {
            final_array.push([item["Comprehensiveness"], item["DQ#"], item["Value"]])
            max_column_width = Math.max(max_column_width, item["Comprehensiveness"].length, item["DQ#"].length)
        })
        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = columns.map((column, index) => {
            return { wch: index == 0 ? max_column_width : column.length }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Comprehensiveness");
        XLSX.writeFile(workbook, "Assessment Content.xlsx");
    }

    const badge_data = [
        {
            title: "Comprehensive Score",
            value: pageData ? pageData.score : "0",
        },
    ]

    const mainChart = {
        header: "",
        labels: pageData ? pageData.digital.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 1", "DQ 2", "DQ 3", "DQ 4", "DQ 5", "DQ 6", "DQ 7", "DQ 8", "DQ11"],
        data: pageData ? pageData.digital.filter((item) => item.Value !== 0).map((item) => item.Value*100) : [0, 0, 0, 0, 0, 0, 0, 0, 0],
    }

    const secondChart = {
        header: "",
        labels: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 9", "DQ 10", "DQ 12", "DQ 13", "DQ 14", "DQ 15", "DQ 16"],
        data: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item.Value*100) : [0, 0, 0, 0, 0, 0, 0],
    }

    function headers_callback(value, index) {
        if(index == 0)
            return "Less Coverage";
        else if(index == 99)
            return "More Coverage";
        else
            return undefined;
    }

    return (
        pageData ?
        <Paper>
            <Card>
                <CardContent>
                    <Box sx={{ flexGrow: 1 }} align={"right"}>
                        <Grid container align={"center"} alignItems={"center"}>
                            {badge_data.map((badge, index) => (
                                    <Grid key={index} item xs={12} sm={5} md={4} lg={3}>
                                        <Badge
                                            title={badge.title}
                                            value={badge.value.toFixed(2)}
                                        />
                                        <Typography>
                                            Out of 5.00
                                        </Typography>
                                    </Grid>
                                )
                            )}
                            <Grid item xs sm md lg>
                                <ColouredTypography my={5} mr={10} variant={"body1"} custom_color={"dimgrey"} size={"16px"} textalign={"justify"}>
                                    Does the program's learning content comprehensively and appropriately cover digital
                                    citizenship competencies in the IEEE DQ Global Standards (IEEE 3527.1)?
                                </ColouredTypography>
                            </Grid>
                        </Grid>
                        <Spacer mb={5}/>

                        <Spacer mb={10}/>
                        {/*MainChart*/}
                        <Grid container alignItems={"center"}>
                            <Grid item xs={5} sm={5} md={4} lg={3}>
                                <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>
                                    Digital Citizenship
                                </ColouredTypography>
                            </Grid>
                            <Grid item xs={7} sm={7} md={8} lg={9}>
                                <ColouredTypography variant={"h4"} textalign={"center"}>
                                    Curriculum Coverage
                                </ColouredTypography>
                                <HorizontalBarChart
                                    labels={mainChart.labels}
                                    values={mainChart.data}
                                    color={true}
                                    threshold={50}
                                    datalabels={true}
                                    percentage={true}
                                    xtickslabels={true}
                                    suggestedMax={100}
                                    barsGap={65}
                                    headers_callback={headers_callback}
                                />
                            </Grid>
                        </Grid>
                        {/*Divider*/}
                        {/*<Grid container alignItems={"center"}>*/}
                        {/*    <Grid item xs={5} sm={5} md={4} lg={3}>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={7} sm={7} md={8} lg={9}>*/}
                        {/*        <Divider/>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        {/*SecondChart*/}
                        <Grid container alignItems={"center"}>
                            <Grid item xs={5} sm={5} md={4} lg={3}>
                                <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>
                                    Beyond DC
                                </ColouredTypography>
                            </Grid>
                            <Grid item xs={7} sm={7} md={8} lg={9}>
                                <HorizontalBarChart
                                    labels={secondChart.labels}
                                    values={secondChart.data}
                                    color={true}
                                    threshold={50}
                                    datalabels={true}
                                    percentage={true}
                                    xtickslabels={false}
                                    suggestedMax={100}
                                    barsGap={65}
                                />
                            </Grid>
                        </Grid>
                        <Button mt={5} variant="contained" onClick={onClickDownload}>
                            Download Data
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
        :
        <Paper>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ComprehensiveTab;