
const lc_en = {
    menu__hello_text: `Hello`,
    menu__guest_text: `Guest`,
    menu__home: `Home`,
    menu__register: `Register`,
    menu__settings: `Settings`,
    menu__logout: `Logout`,
    uo__welcome_dct_text: `Welcome to the <br>Digital Citizenship Test!`,
    uo__start_test_text: `Start Test`,
    post_q_landing__congrats: `Congratulations!`,
    post_q_landing__finished_test_message_text: `You have finished the test.<br>In order to get your score, you'll need to sign up for an account.`,
    post_q_landing__finished_test_short_message: `You have finished the test.`,
    post_q_landing__if_under_18_text: `If you are under the age of 18, you’ll need your parent’s or guardian’s email and consent to sign up.`,
    post_q_landing__signup: `Sign Up`,
    post_q_landing__no_thanks: `No, thanks`,
    persist_guest__fname: `First Name`,
    persist_guest__lname: `Last Name`,
    persist_guest__email_address: `Email Address`,
    persist_guest__require_parent_email_text: `(Parent/Guardian email address required if you’re under 18 years of age.)`,
    persist_guest__password: `Password`,
    persist_guest__confirm_password: `Confirm Password`,
    persist_guest__have_access_code: `Have a Access Code? Click to Enter!`,
    persist_guest__access_code_span: `Access Code <span style="font-weight: bold;">(click here to hide)</span>`,
    persist_guest__accept_terms_of_use: `I have read and accept the <br><a href="/terms_of_use" target="_blank"> TERMS of USE </a> and<a href="/privacy_policy" target="_blank"> PRIVACY POLICY</a>.`,
    persist_guest__register: `Register`,
    persist_guest__already_have_account: `Already have an account?`,
    persist_guest__login_here: `Login here`,
    persist_guest__login: `Login`,
    persist_guest__not_have_an_account: `Don't have an account?`,
    persist_guest__register_here: `Register here`,
    uo__register_as_individual: `Register as an INDIVIDUAL`,
    uo__register_as_company: `Register as a COMPANY`,
    uo__register_as_school: `Register as a SCHOOL`,
    uo__not_found: `Not Found`,
    persist_guest__privacy_policy: `Privacy Policy`,
    persist_guest__terms_of_use: `Terms of Use`,
    persist_guest__wrong_pass_user_not_found: `wrong username/email entered: user not found`,
    persist_guest__plz_fill_req_fields: `Please fill all the required fields`,
    uo__plz_accept_terms_of_use: `Please Accept the Terms of Use`,
    uo__pass_mismatch_plz_retype: `Password Mismatch! Please re-type`,
    uo__err_loading_guest: `Error Loading Guest User!`,
    uo__plz_set_ur_new_pass: `Please set your new password.`,
    uo__no_assessment_available_chk_later: `NO Assessments available at the moment, Please check again later`,
    uo__dq_under_maintainance_txt: `The DQ Assessment is currently under maintenance.`,
    uo__not_available_try_later: `Not Available at the moment, Try back later!`,
    uo__submit: `Submit`,
    uo__view_score: `View Score`,
    uo__admin_login: `Admin Login`,
    uo__plz_wait: `Please wait...`,
    uo__loading: `Loading…`,
    uo__loaded: `Loaded`,
    result_quiz__require_attention: `Requires Attention!`,
    result_quiz__bellow_avg: `Below Average!`,
    result_quiz__good_job: `Good Job!`,
    result_quiz__excellent: `Excellent!`,
    result_quiz__require_attention_br: `Requires<br>Attention`,
    result_quiz__above_avg_br: `Above<br>Average`,
    result_quiz__bellow_avg_br: `Below<br>Average`,
    result_quiz__excellent_br: `Excellent`,
    result_quiz__mydq_score: `My DQ Score`,
    result_quiz__mydq_badges: `My DQ Badges`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `You are ready to use technology. You understand how to use technology safely. Continue building your digital skills to thrive.`,
    result_quiz__your_score_is_above_average_rubric: `Your score is above average compared to the global average. We recommend that you keep building a comprehensive set of digital citizenship skills.`,
    result_quiz__your_score_is_less_than_average_rubirc: `Your score is less than average compared to the global average. We recommend that you enhance your digital citizenship skills if you are actively using technology.`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `You may potentially be exposed to one or more cyber-risks and unhealthy habits related to your digital usage. We recommend that you check your cyber-risk exposure level.`,
    result_quiz__your_score_span: `Your score is in the `,
    result_quiz__dct_discalimer_html_text: `*Disclaimer: This Digital Citizenship Test was developed to raise awareness about the importance of digital citizenship and may not be accurate in identifying your cyber-risk exposure and digital skill level. If you are interested in getting a full assessment, you can learn more <a href="/assessment" target="_blank">here</a>.`,
    result_quiz__click_message_dct_type: `Click ‘Next’ to find out what Digital Citizen Type you are`,
    result_quiz__next: `Next`,
    result_quiz__carefree_citizen: `Carefree Citizen!`,
    result_quiz__feel_comfort_online_text: `Feels more comfortable online than offline`,
    result_quiz__invlove_with_risks_text: `Potentially involved with various cyber-risks`,
    result_quiz__ordinary_citizen: `Ordinary Citizen!`,
    result_quiz__avg_tech_user_message_text: `Average technology user who may not be fully aware of the risks nor opportunities`,
    result_quiz__tend_to_follow_algo: `Tend to passively follow the algorithm`,
    result_quiz__always_on_citizen: `Always-On Citizen!`,
    result_quiz__like_comm_with_others: `Likely communicate with others a lot online`,
    result_quiz__maynot_consider_risks: `May not consider much about potential cyber-risks`,
    result_quiz__catious_citizen: `Cautious Citizen!`,
    result_quiz__aware_of_risks_not_fully_explore: `Being aware of potential cyber-risks and not fully exploring the digital world`,
    result_quiz__likely_having_risks_in_dw: `Likely having low risks in the digital world`,
    result_quiz__digilog_citizen: `DigiLog Citizen!`,
    result_quiz__balanced_digital_citizen: `Well-rounded digital citizen who balances online and offline lives`,
    result_quiz__use_tech_mindfully: `Uses technology mindfully`,
    result_quiz__learn_more_html_text: `Learn more at <a href="/" target="_blank">DQ Index</a>.`,
    result_quiz__done: `Done`,
    dq_home__min_duration: `MIN`,
    dq_home__dc_test: `Digital Citizenship Test`,
    dq_home__bellow_avg: `Below Avg`,
    dq_home__avg: `Average`,
    dq_home__excellent: `Excellent`,
    dq_home__top_percent: `top`,
    dq_home__bottom_percent: `bottom`,
    dq_home__dc_score_helps_what_message: `The Digital Citizenship Score helps you to understand your digital skill level for using technology safely and responsibly. It also helps you to identify your Digital Citizen type.`,
    dq_home__share: `Share`,
    dq_home__retake: `Retake`,
    dq_home__myscore_comparison: `My Score Comparison`,
    dq_home__myscore: `My Score`,
    dq_home__national_avg: `National Avg.`,
    dq_home__global_avg: `Global Avg.`,
    dq_home__retake_test: `Retake Test`,
    dq_demographic__gender: `Gender`,
    dq_demographic__male: `Male`,
    dq_demographic__female: `Female`,
    dq_demographic__other: `Other`,
    dq_demographic__year_of_birth: `Year of Birth`,
    dq_demographic__country: `Country`,
    dq_country__afghanistan: `Afghanistan`,
    dq_country__aland_islands: `Åland Islands`,
    dq_country__albania: `Albania`,
    dq_country__algeria: `Algeria`,
    dq_country__american_samoa: `American Samoa`,
    dq_country__andorra: `AndorrA`,
    dq_country__angola: `Angola`,
    dq_country__anguilla: `Anguilla`,
    dq_country__antarctica: `Antarctica`,
    dq_country__antigua_and_barbuda: `Antigua and Barbuda`,
    dq_country__argentina: `Argentina`,
    dq_country__armenia: `Armenia`,
    dq_country__aruba: `Aruba`,
    dq_country__australia: `Australia`,
    dq_country__austria: `Austria`,
    dq_country__azerbaijan: `Azerbaijan`,
    dq_country__bahamas: `Bahamas`,
    dq_country__bahrain: `Bahrain`,
    dq_country__bangladesh: `Bangladesh`,
    dq_country__barbados: `Barbados`,
    dq_country__belarus: `Belarus`,
    dq_country__belgium: `Belgium`,
    dq_country__belize: `Belize`,
    dq_country__benin: `Benin`,
    dq_country__bermuda: `Bermuda`,
    dq_country__bhutan: `Bhutan`,
    dq_country__bolivia: `Bolivia`,
    dq_country__bosnia_and_herzegovina: `Bosnia and Herzegovina`,
    dq_country__botswana: `Botswana`,
    dq_country__bouvet_island: `Bouvet Island`,
    dq_country__brazil: `Brazil`,
    dq_country__british_indian_ocean_territory: `British Indian Ocean Territory`,
    dq_country__brunei_darussalam: `Brunei Darussalam`,
    dq_country__bulgaria: `Bulgaria`,
    dq_country__burkina_faso: `Burkina Faso`,
    dq_country__burundi: `Burundi`,
    dq_country__cambodia: `Cambodia`,
    dq_country__cameroon: `Cameroon`,
    dq_country__canada: `Canada`,
    dq_country__cape_verde: `Cape Verde`,
    dq_country__cayman_islands: `Cayman Islands`,
    dq_country__central_african_republic: `Central African Republic`,
    dq_country__chad: `Chad`,
    dq_country__chile: `Chile`,
    dq_country__china: `China`,
    dq_country__christmas_island: `Christmas Island`,
    dq_country__cocos_keeling_islands: `Cocos (Keeling) Islands`,
    dq_country__colombia: `Colombia`,
    dq_country__comoros: `Comoros`,
    dq_country__congo: `Congo`,
    dq_country__congo_the_democratic_republic_of_the: `Congo, The Democratic Republic of the`,
    dq_country__cook_islands: `Cook Islands`,
    dq_country__costa_rica: `Costa Rica`,
    dq_country__cote_divoire: `Cote D'Ivoire`,
    dq_country__croatia: `Croatia`,
    dq_country__cuba: `Cuba`,
    dq_country__cyprus: `Cyprus`,
    dq_country__czech_republic: `Czech Republic`,
    dq_country__denmark: `Denmark`,
    dq_country__djibouti: `Djibouti`,
    dq_country__dominica: `Dominica`,
    dq_country__dominican_republic: `Dominican Republic`,
    dq_country__ecuador: `Ecuador`,
    dq_country__egypt: `Egypt`,
    dq_country__el_salvador: `El Salvador`,
    dq_country__equatorial_guinea: `Equatorial Guinea`,
    dq_country__eritrea: `Eritrea`,
    dq_country__estonia: `Estonia`,
    dq_country__ethiopia: `Ethiopia`,
    dq_country__falkland_islands_malvinas: `Falkland Islands (Malvinas)`,
    dq_country__faroe_islands: `Faroe Islands`,
    dq_country__fiji: `Fiji`,
    dq_country__finland: `Finland`,
    dq_country__france: `France`,
    dq_country__french_guiana: `French Guiana`,
    dq_country__french_polynesia: `French Polynesia`,
    dq_country__french_southern_territories: `French Southern Territories`,
    dq_country__gabon: `Gabon`,
    dq_country__gambia: `Gambia`,
    dq_country__georgia: `Georgia`,
    dq_country__germany: `Germany`,
    dq_country__ghana: `Ghana`,
    dq_country__gibraltar: `Gibraltar`,
    dq_country__greece: `Greece`,
    dq_country__greenland: `Greenland`,
    dq_country__grenada: `Grenada`,
    dq_country__guadeloupe: `Guadeloupe`,
    dq_country__guam: `Guam`,
    dq_country__guatemala: `Guatemala`,
    dq_country__guernsey: `Guernsey`,
    dq_country__guinea: `Guinea`,
    dq_country__guinea_bissau: `Guinea-Bissau`,
    dq_country__guyana: `Guyana`,
    dq_country__haiti: `Haiti`,
    dq_country__heard_island_and_mcdonald_islands: `Heard Island and Mcdonald Islands`,
    dq_country__holy_see_vatican_city_state: `Holy See (Vatican City State)`,
    dq_country__honduras: `Honduras`,
    dq_country__hong_kong: `Hong Kong`,
    dq_country__hungary: `Hungary`,
    dq_country__iceland: `Iceland`,
    dq_country__india: `India`,
    dq_country__indonesia: `Indonesia`,
    dq_country__iran_islamic_republic_of: `Iran, Islamic Republic Of`,
    dq_country__iraq: `Iraq`,
    dq_country__ireland: `Ireland`,
    dq_country__isle_of_man: `Isle of Man`,
    dq_country__israel: `Israel`,
    dq_country__italy: `Italy`,
    dq_country__jamaica: `Jamaica`,
    dq_country__japan: `Japan`,
    dq_country__jersey: `Jersey`,
    dq_country__jordan: `Jordan`,
    dq_country__kazakhstan: `Kazakhstan`,
    dq_country__kenya: `Kenya`,
    dq_country__kiribati: `Kiribati`,
    dq_country__korea_democratic_peoples_republic_of: `Korea, Democratic People's Republic of`,
    dq_country__korea_republic_of: `Korea, Republic of`,
    dq_country__kuwait: `Kuwait`,
    dq_country__kyrgyzstan: `Kyrgyzstan`,
    dq_country__lao_peoples_democratic_republic: `Lao People's Democratic Republic`,
    dq_country__latvia: `Latvia`,
    dq_country__lebanon: `Lebanon`,
    dq_country__lesotho: `Lesotho`,
    dq_country__liberia: `Liberia`,
    dq_country__libyan_arab_jamahiriya: `Libyan Arab Jamahiriya`,
    dq_country__liechtenstein: `Liechtenstein`,
    dq_country__lithuania: `Lithuania`,
    dq_country__luxembourg: `Luxembourg`,
    dq_country__macao: `Macao`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `Macedonia, The Former Yugoslav Republic of`,
    dq_country__madagascar: `Madagascar`,
    dq_country__malawi: `Malawi`,
    dq_country__malaysia: `Malaysia`,
    dq_country__maldives: `Maldives`,
    dq_country__mali: `Mali`,
    dq_country__malta: `Malta`,
    dq_country__marshall_islands: `Marshall Islands`,
    dq_country__martinique: `Martinique`,
    dq_country__mauritania: `Mauritania`,
    dq_country__mauritius: `Mauritius`,
    dq_country__mayotte: `Mayotte`,
    dq_country__mexico: `Mexico`,
    dq_country__micronesia_federated_states_of: `Micronesia, Federated States of`,
    dq_country__moldova_republic_of: `Moldova, Republic of`,
    dq_country__monaco: `Monaco`,
    dq_country__mongolia: `Mongolia`,
    dq_country__montserrat: `Montserrat`,
    dq_country__morocco: `Morocco`,
    dq_country__mozambique: `Mozambique`,
    dq_country__myanmar: `Myanmar`,
    dq_country__namibia: `Namibia`,
    dq_country__nauru: `Nauru`,
    dq_country__nepal: `Nepal`,
    dq_country__netherlands: `Netherlands`,
    dq_country__netherlands_antilles: `Netherlands Antilles`,
    dq_country__new_caledonia: `New Caledonia`,
    dq_country__new_zealand: `New Zealand`,
    dq_country__nicaragua: `Nicaragua`,
    dq_country__niger: `Niger`,
    dq_country__nigeria: `Nigeria`,
    dq_country__niue: `Niue`,
    dq_country__norfolk_island: `Norfolk Island`,
    dq_country__northern_mariana_islands: `Northern Mariana Islands`,
    dq_country__norway: `Norway`,
    dq_country__oman: `Oman`,
    dq_country__pakistan: `Pakistan`,
    dq_country__palau: `Palau`,
    dq_country__palestinian_territory_occupied: `Palestinian Territory, Occupied`,
    dq_country__panama: `Panama`,
    dq_country__papua_new_guinea: `Papua New Guinea`,
    dq_country__paraguay: `Paraguay`,
    dq_country__peru: `Peru`,
    dq_country__philippines: `Philippines`,
    dq_country__pitcairn: `Pitcairn`,
    dq_country__poland: `Poland`,
    dq_country__portugal: `Portugal`,
    dq_country__puerto_rico: `Puerto Rico`,
    dq_country__qatar: `Qatar`,
    dq_country__reunion: `Reunion`,
    dq_country__romania: `Romania`,
    dq_country__russian_federation: `Russian Federation`,
    dq_country__rwanda: `RWANDA`,
    dq_country__saint_helena: `Saint Helena`,
    dq_country__saint_kitts_and_nevis: `Saint Kitts and Nevis`,
    dq_country__saint_lucia: `Saint Lucia`,
    dq_country__saint_pierre_and_miquelon: `Saint Pierre and Miquelon`,
    dq_country__saint_vincent_and_the_grenadines: `Saint Vincent and the Grenadines`,
    dq_country__samoa: `Samoa`,
    dq_country__san_marino: `San Marino`,
    dq_country__sao_tome_and_principe: `Sao Tome and Principe`,
    dq_country__saudi_arabia: `Saudi Arabia`,
    dq_country__senegal: `Senegal`,
    dq_country__serbia_and_montenegro: `Serbia and Montenegro`,
    dq_country__seychelles: `Seychelles`,
    dq_country__sierra_leone: `Sierra Leone`,
    dq_country__singapore: `Singapore`,
    dq_country__slovakia: `Slovakia`,
    dq_country__slovenia: `Slovenia`,
    dq_country__solomon_islands: `Solomon Islands`,
    dq_country__somalia: `Somalia`,
    dq_country__south_africa: `South Africa`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `South Georgia and the South Sandwich Islands`,
    dq_country__spain: `Spain`,
    dq_country__sri_lanka: `Sri Lanka`,
    dq_country__sudan: `Sudan`,
    dq_country__suriname: `Suriname`,
    dq_country__svalbard_and_jan_mayen: `Svalbard and Jan Mayen`,
    dq_country__swaziland: `Swaziland`,
    dq_country__sweden: `Sweden`,
    dq_country__switzerland: `Switzerland`,
    dq_country__syrian_arab_republic: `Syrian Arab Republic`,
    dq_country__taiwan_province_of_china: `Taiwan, Province of China`,
    dq_country__tajikistan: `Tajikistan`,
    dq_country__tanzania_united_republic_of: `Tanzania, United Republic of`,
    dq_country__thailand: `Thailand`,
    dq_country__timor_leste: `Timor-Leste`,
    dq_country__togo: `Togo`,
    dq_country__tokelau: `Tokelau`,
    dq_country__tonga: `Tonga`,
    dq_country__trinidad_and_tobago: `Trinidad and Tobago`,
    dq_country__tunisia: `Tunisia`,
    dq_country__turkey: `Turkey`,
    dq_country__turkmenistan: `Turkmenistan`,
    dq_country__turks_and_caicos_islands: `Turks and Caicos Islands`,
    dq_country__tuvalu: `Tuvalu`,
    dq_country__uganda: `Uganda`,
    dq_country__ukraine: `Ukraine`,
    dq_country__united_arab_emirates: `United Arab Emirates`,
    dq_country__united_kingdom: `United Kingdom`,
    dq_country__united_states: `United States`,
    dq_country__united_states_minor_outlying_islands: `United States Minor Outlying Islands`,
    dq_country__uruguay: `Uruguay`,
    dq_country__uzbekistan: `Uzbekistan`,
    dq_country__vanuatu: `Vanuatu`,
    dq_country__venezuela: `Venezuela`,
    dq_country__viet_nam: `Viet Nam`,
    dq_country__virgin_islands_british: `Virgin Islands, British`,
    dq_country__virgin_islands_us: `Virgin Islands, U.S.`,
    dq_country__wallis_and_futuna: `Wallis and Futuna`,
    dq_country__western_sahara: `Western Sahara`,
    dq_country__yemen: `Yemen`,
    dq_country__zambia: `Zambia`,
    dq_country__zimbabwe: `Zimbabwe`,
    dq_demographic__postal_code: `Postal Code`,
    dq_demographic__next: `Next`,
    dq_demographic__plz_fill_req_fields: `Please fill all the required fields`,
    uo__lets_start: `Let's Start`,
    uo__dct_test_intro_message_text: `This test will help you check your level of digital citizenship, to understand whether you are ready to use digital devices and media safely and responsibly.`,
    quiz_result_see_your_score: `See Your Score!`,
    post_quiz_resume_test: `Resume Test`,
    uo__username: `Username`,
    uo__failed_to_proceed: `Failed to Proceed!`,
    uo__save_failed_try_again: `Save Failed! Try Again...`,
    uo__continue_as_guest: `Continue as a Guest?`,
    uo__guest_login: `Guest login`,
}


const lc_ko = {
    menu__hello_text: `안녕하세요`,
    menu__guest_text: `손님`,
    menu__home: `홈 화면`,
    menu__register: `등록하기`,
    menu__settings: `설정`,
    menu__logout: `로그아웃하기`,
    uo__welcome_dct_text: `디지털 시민의식 테스트에 오신 것을 환영합니다!`,
    uo__start_test_text: `무료 테스트 시작하기`,
    post_q_landing__congrats: `축하합니다!`,
    post_q_landing__finished_test_message_text: `테스트를 마쳤습니다.<br>점수를 확인하기 위해서 계정을 만들어주세요.`,
    post_q_landing__finished_test_short_message: `테스트를 마쳤습니다.`,
    post_q_landing__if_under_18_text: `18세 이하의 사용자는 부모님이나 보호자의 이메일 동의가 필요합니다.`,
    post_q_landing__signup: `가입하기`,
    post_q_landing__no_thanks: `괜찮습니다.`,
    persist_guest__fname: `이름`,
    persist_guest__lname: `성`,
    persist_guest__email_address: `이메일 주소`,
    persist_guest__require_parent_email_text: `(18세 이하는 부모님/보호자의 이메일 동의가 필요합니다)`,
    persist_guest__password: `비밀번호`,
    persist_guest__confirm_password: `비밀번호 확인`,
    persist_guest__have_access_code: `접속 코드가 있나요? 클릭하여 들어가세요.`,
    persist_guest__access_code_span: `접속 코드<span style="font-weight: bold;">(가리기)</span>`,
    persist_guest__accept_terms_of_use: `나는 <br><a href="/terms_of_use" target="_blank"> 이용약관</a>과 <a href="/privacy_policy" target="_blank"> 개인정보 정책</a>을 읽어보았고 이에 동의합니다.`,
    persist_guest__register: `등록하기`,
    persist_guest__already_have_account: `이미 계정이 있나요?`,
    persist_guest__login_here: `로그인하기`,
    persist_guest__login: `로그인`,
    persist_guest__not_have_an_account: `계정이 없다면`,
    persist_guest__register_here: `등록하기`,
    uo__register_as_individual: `개인으로 등록하기`,
    uo__register_as_company: `기업으로 등록하기`,
    uo__register_as_school: `학교로 등록하기`,
    uo__not_found: `찾을 수 없음`,
    persist_guest__privacy_policy: `개인정보 정책`,
    persist_guest__terms_of_use: `이용약관`,
    persist_guest__wrong_pass_user_not_found: `잘못된 아이디/이메일입니다. 사용자를 찾을 수 없습니다.`,
    persist_guest__plz_fill_req_fields: `필요한 정보를 기입해주세요.`,
    uo__plz_accept_terms_of_use: `이용약관에 동의해주세요.`,
    uo__pass_mismatch_plz_retype: `비밀번호 오류! 다시 입력해주세요.`,
    uo__err_loading_guest: `잘못된 접근입니다!`,
    uo__plz_set_ur_new_pass: `새로운 비밀번호를 설정해주세요.`,
    uo__no_assessment_available_chk_later: `현재 평가가 불가능합니다. 나중에 다시 시도해주세요.`,
    uo__dq_under_maintainance_txt: `DQ 평가가 현재 점검 중입니다.`,
    uo__not_available_try_later: `현재 이용이 불가능합니다. 나중에 다시 시도해주세요.`,
    uo__submit: `제출하기`,
    uo__view_score: `점수 확인하기`,
    uo__admin_login: `관리자 로그인`,
    uo__plz_wait: `기다려주세요.`,
    uo__loading: `로딩 중`,
    uo__loaded: `로딩 완료`,
    result_quiz__require_attention: `주의가 필요합니다!`,
    result_quiz__bellow_avg: `평균 이하입니다!`,
    result_quiz__good_job: `좋습니다!`,
    result_quiz__excellent: `훌륭합니다!`,
    result_quiz__require_attention_br: `주의가<br>필요합니다`,
    result_quiz__above_avg_br: `평균<br>이상입니다 `,
    result_quiz__bellow_avg_br: `평균<br>이하입니다`,
    result_quiz__excellent_br: `훌륭합니다`,
    result_quiz__mydq_score: `나의 DQ 점수`,
    result_quiz__mydq_badges: `나의 DQ 배지`,
    result_quiz__you_are_ready_to_use_tech_rubirc: `귀右ㅏ는 기슬을 사용힐 준비가 되었습니 다． 귀하는 기술을 인전하게 사용하는 방법을 이해합니 다． 더 좋은 결과를 위해 계속해서 디지 털 기술을 쌓아보세요．`,
    result_quiz__your_score_is_above_average_rubric: `귀右『의 점수는 글로벌 평균보다 높습니다． 포괄적인 디지털 시민권 기술을 계속 쌓는 것이 좇습니다．`,
    result_quiz__your_score_is_less_than_average_rubirc: `귀하의 점수는 글로벌 평균보다 낮습니다． 기술을 적극적으로 사용하는 경우 디지털 시 민권 기술을 힝상하는 것이 좇습니다．`,
    result_quiz__you_may_potentially_be_exposed_rubirc: `귀하는 디지털 사용과 관련된 하나 이상의 사이버 위헝 및 건강에 해로운 습관에 잠재적으로 노출될 수 있습니다． 사이버 위험 노출 수준을 확인하는 것이 좇습니다．`,
    result_quiz__your_score_span: `당신의 점수는`,
    result_quiz__dct_discalimer_html_text: `*주의: 이 디지털 시민의식 테스트는 디지털 시민의 중요성에 대한 인식을 높이기 위해 개발되었으며, 사이버 위험도 및 디지털 역량 수준을 확인하는 데 정확하지 않을 수 있습니다. 전체 평가에 관심이 있는 경우 <a href="/assessment" target="_blank">여기</a>에서 더 알아볼 수 있습니다.`,
    result_quiz__click_message_dct_type: `당신의 디지털 시민 타입을 알고 싶다면 '다음'을 클릭하세요.`,
    result_quiz__next: `다음`,
    result_quiz__carefree_citizen: `골수 디지털 네이게이터`,
    result_quiz__feel_comfort_online_text: `당신은 오프라인보다 온라인에서 생활하는 것이 더 편함.`,
    result_quiz__invlove_with_risks_text: `하지만 다양한 디지털 위험에 이미 노출되어 있을 수 있음.`,
    result_quiz__ordinary_citizen: `평범한 디지털 유저`,
    result_quiz__avg_tech_user_message_text: `디지털 세상의 위험도, 그렇다고 기회도 완전히 잘 알지는 못함.`,
    result_quiz__tend_to_follow_algo: `디지털에서 추천하는 알고리즘을 수동적으로 따르는 경향이 있음.`,
    result_quiz__always_on_citizen: `상시접속형 디지털 관종`,
    result_quiz__like_comm_with_others: `온라인으로 사람들과 소통하는 것에 많은 시간을 투자함.`,
    result_quiz__maynot_consider_risks: `디지털 위험 따위는 크게 안중에 없음.`,
    result_quiz__catious_citizen: `조심성 많은 디지털 경계형`,
    result_quiz__aware_of_risks_not_fully_explore: `디지털 위험을 잘 인지하고 있고, 디지털 이용에 대한 경계심을 가지고 있어 쉽게 위험에 빠지지는 않음.`,
    result_quiz__likely_having_risks_in_dw: `그렇지만 디지털을 제대로 이용하지도 않음.`,
    result_quiz__digilog_citizen: `균형 잡힌 디지로그형`,
    result_quiz__balanced_digital_citizen: `온라인과 오프라인의 삶을 균형 잡을 줄 아는 모범적인 디지털 시민.`,
    result_quiz__use_tech_mindfully: `기술을 제대로 이해하고, 신중하게 사용할 줄 알음.`,
    result_quiz__learn_more_html_text: `<a href="/" target="_blank">DQ 인덱스</a>에 대해 더 알아보세요.`,
    result_quiz__done: `완료`,
    dq_home__min_duration: `분`,
    dq_home__dc_test: `디지털 시민의식  테스트`,
    dq_home__bellow_avg: `평균 이하`,
    dq_home__avg: `평균`,
    dq_home__excellent: `평균 이상`,
    dq_home__top_percent: `상위`,
    dq_home__bottom_percent: `하위`,
    dq_home__dc_score_helps_what_message: `디지털 시민의식 점수는 당신이 얼마나 디지털 기술을 안전하고 책임감 있게 사용할 수 있는 역량이 있는지에 대해 알려줍니다. 또한 당신의 디지털 시민 타입을 확인해보세요.`,
    dq_home__share: `공유하기`,
    dq_home__retake: `다시하기`,
    dq_home__myscore_comparison: `점수 비교하기`,
    dq_home__myscore: `나의 점수`,
    dq_home__national_avg: `국가 평균`,
    dq_home__global_avg: `세계 평균`,
    dq_home__retake_test: `테스트 다시 하기`,
    dq_demographic__gender: `성별`,
    dq_demographic__male: `남성`,
    dq_demographic__female: `여성`,
    dq_demographic__other: `기타`,
    dq_demographic__year_of_birth: `출생연도`,
    dq_demographic__country: `국가`,
    dq_country__afghanistan: `아프가니스탄`,
    dq_country__aland_islands: `올란드 제도`,
    dq_country__albania: `알바니아`,
    dq_country__algeria: `알제리`,
    dq_country__american_samoa: `사모아`,
    dq_country__andorra: `안도라`,
    dq_country__angola: `앙골라`,
    dq_country__anguilla: `앵귈라`,
    dq_country__antarctica: `남극`,
    dq_country__antigua_and_barbuda: `앤티가바부다`,
    dq_country__argentina: `아르헨티나`,
    dq_country__armenia: `아르메니아`,
    dq_country__aruba: `아루바`,
    dq_country__australia: `호주`,
    dq_country__austria: `오스트리아`,
    dq_country__azerbaijan: `아제르바이잔`,
    dq_country__bahamas: `바하마`,
    dq_country__bahrain: `바레인`,
    dq_country__bangladesh: `방글라데시`,
    dq_country__barbados: `바베이도스`,
    dq_country__belarus: `벨라루스`,
    dq_country__belgium: `벨기에`,
    dq_country__belize: `벨리즈`,
    dq_country__benin: `베냉`,
    dq_country__bermuda: `버뮤다`,
    dq_country__bhutan: `부탄`,
    dq_country__bolivia: `볼리비아`,
    dq_country__bosnia_and_herzegovina: `보스니아 헤르체고비나`,
    dq_country__botswana: `보츠와나`,
    dq_country__bouvet_island: `부벳 섬`,
    dq_country__brazil: `브라질`,
    dq_country__british_indian_ocean_territory: `영국령 인도양 준주`,
    dq_country__brunei_darussalam: `브루나이`,
    dq_country__bulgaria: `불가리아`,
    dq_country__burkina_faso: `부르키나파소`,
    dq_country__burundi: `부룬디`,
    dq_country__cambodia: `캄보디아`,
    dq_country__cameroon: `카메룬`,
    dq_country__canada: `캐나다`,
    dq_country__cape_verde: `카보베르데`,
    dq_country__cayman_islands: `케이맨 제도`,
    dq_country__central_african_republic: `중앙 아프리카 공화국`,
    dq_country__chad: `차드`,
    dq_country__chile: `칠레`,
    dq_country__china: `중국`,
    dq_country__christmas_island: `크리스마스 섬`,
    dq_country__cocos_keeling_islands: `코코스 (킬링) 제도`,
    dq_country__colombia: `콜롬비아`,
    dq_country__comoros: `코모로`,
    dq_country__congo: `콩고`,
    dq_country__congo_the_democratic_republic_of_the: `콩고 민주공화국`,
    dq_country__cook_islands: `쿡 제도`,
    dq_country__costa_rica: `코스타리카`,
    dq_country__cote_divoire: `코트디부아르`,
    dq_country__croatia: `크로아티아`,
    dq_country__cuba: `쿠바`,
    dq_country__cyprus: `키프로스`,
    dq_country__czech_republic: `체코`,
    dq_country__denmark: `덴마크`,
    dq_country__djibouti: `지부티`,
    dq_country__dominica: `도미니카`,
    dq_country__dominican_republic: `도미니카 공화국`,
    dq_country__ecuador: `에콰도르`,
    dq_country__egypt: `이집트`,
    dq_country__el_salvador: `엘살바도르`,
    dq_country__equatorial_guinea: `적도 기니`,
    dq_country__eritrea: `에리트레아`,
    dq_country__estonia: `에스토니아`,
    dq_country__ethiopia: `에티오피아`,
    dq_country__falkland_islands_malvinas: `포클랜드 제도 (말비나스)`,
    dq_country__faroe_islands: `페로 제도`,
    dq_country__fiji: `피지`,
    dq_country__finland: `핀란드`,
    dq_country__france: `프랑스`,
    dq_country__french_guiana: `프랑스령 기아나`,
    dq_country__french_polynesia: `프랑스령 폴리네시아`,
    dq_country__french_southern_territories: `프랑스 남부 영토`,
    dq_country__gabon: `가봉`,
    dq_country__gambia: `감비아`,
    dq_country__georgia: `그루지야`,
    dq_country__germany: `독일`,
    dq_country__ghana: `가나`,
    dq_country__gibraltar: `지브롤터`,
    dq_country__greece: `그리스`,
    dq_country__greenland: `그린란드`,
    dq_country__grenada: `그라나다`,
    dq_country__guadeloupe: `과들루프`,
    dq_country__guam: `괌`,
    dq_country__guatemala: `과테말라`,
    dq_country__guernsey: `건지`,
    dq_country__guinea: `기니`,
    dq_country__guinea_bissau: `기니비사우`,
    dq_country__guyana: `가이아나`,
    dq_country__haiti: `아이티`,
    dq_country__heard_island_and_mcdonald_islands: `허드 아일랜드와 맥도날드 제도`,
    dq_country__holy_see_vatican_city_state: `바티칸 시국`,
    dq_country__honduras: `온두라스`,
    dq_country__hong_kong: `홍콩`,
    dq_country__hungary: `헝가리`,
    dq_country__iceland: `아이슬란드`,
    dq_country__india: `인도`,
    dq_country__indonesia: `인도네시아`,
    dq_country__iran_islamic_republic_of: `이란`,
    dq_country__iraq: `이라크`,
    dq_country__ireland: `아일랜드`,
    dq_country__isle_of_man: `맨 섬`,
    dq_country__israel: `이스라엘`,
    dq_country__italy: `이탈리아`,
    dq_country__jamaica: `자메이카`,
    dq_country__japan: `일본`,
    dq_country__jersey: `저지 섬`,
    dq_country__jordan: `요르단`,
    dq_country__kazakhstan: `카자흐스탄`,
    dq_country__kenya: `케냐`,
    dq_country__kiribati: `키리바시`,
    dq_country__korea_democratic_peoples_republic_of: `조선민주주의인민공화국`,
    dq_country__korea_republic_of: `대한민국`,
    dq_country__kuwait: `쿠웨이트`,
    dq_country__kyrgyzstan: `키르기스스탄`,
    dq_country__lao_peoples_democratic_republic: `라오스 인민민주공화국`,
    dq_country__latvia: `라트비아`,
    dq_country__lebanon: `레바논`,
    dq_country__lesotho: `레소토`,
    dq_country__liberia: `라이베리아`,
    dq_country__libyan_arab_jamahiriya: `리비아 아랍 자마히리야`,
    dq_country__liechtenstein: `리히텐슈타인`,
    dq_country__lithuania: `리투아니아`,
    dq_country__luxembourg: `룩셈부르크`,
    dq_country__macao: `마카오`,
    dq_country__macedonia_the_former_yugoslav_republic_of: `마케도니아`,
    dq_country__madagascar: `마다가스카르`,
    dq_country__malawi: `말라위`,
    dq_country__malaysia: `말레이시아`,
    dq_country__maldives: `몰디브`,
    dq_country__mali: `말리`,
    dq_country__malta: `몰타`,
    dq_country__marshall_islands: `마셜 제도`,
    dq_country__martinique: `마르티니크`,
    dq_country__mauritania: `모리타니`,
    dq_country__mauritius: `모리셔스`,
    dq_country__mayotte: `마요트`,
    dq_country__mexico: `멕시코`,
    dq_country__micronesia_federated_states_of: `미크로네시아, 연방연방국가`,
    dq_country__moldova_republic_of: `몰도바 공화국`,
    dq_country__monaco: `모나코`,
    dq_country__mongolia: `몽골`,
    dq_country__montserrat: `몬세라트`,
    dq_country__morocco: `모로코`,
    dq_country__mozambique: `모잠비크`,
    dq_country__myanmar: `미얀마`,
    dq_country__namibia: `나미비아`,
    dq_country__nauru: `나우루`,
    dq_country__nepal: `네팔어`,
    dq_country__netherlands: `네덜란드`,
    dq_country__netherlands_antilles: `네덜란드 안틸레스`,
    dq_country__new_caledonia: `누벨칼레도니`,
    dq_country__new_zealand: `뉴질랜드`,
    dq_country__nicaragua: `니카라과`,
    dq_country__niger: `니제르`,
    dq_country__nigeria: `나이지리아`,
    dq_country__niue: `니우에`,
    dq_country__norfolk_island: `노퍽 섬`,
    dq_country__northern_mariana_islands: `북마리아나 제도`,
    dq_country__norway: `노르웨이`,
    dq_country__oman: `오만`,
    dq_country__pakistan: `파키스탄`,
    dq_country__palau: `팔라우`,
    dq_country__palestinian_territory_occupied: `팔레스타인`,
    dq_country__panama: `파나마`,
    dq_country__papua_new_guinea: `파푸아뉴기니`,
    dq_country__paraguay: `파라과이`,
    dq_country__peru: `페루`,
    dq_country__philippines: `필리핀`,
    dq_country__pitcairn: `핏케언`,
    dq_country__poland: `폴란드`,
    dq_country__portugal: `포르투갈`,
    dq_country__puerto_rico: `푸에르토리코`,
    dq_country__qatar: `카타르`,
    dq_country__reunion: `레위니옹`,
    dq_country__romania: `루마니아`,
    dq_country__russian_federation: `러시아 연방`,
    dq_country__rwanda: `르완다`,
    dq_country__saint_helena: `세인트 헬레나`,
    dq_country__saint_kitts_and_nevis: `세인트 키츠 네비스`,
    dq_country__saint_lucia: `세인트루시아`,
    dq_country__saint_pierre_and_miquelon: `생 피에르와 미켈론`,
    dq_country__saint_vincent_and_the_grenadines: `세인트 빈센트와 그레나딘`,
    dq_country__samoa: `사모아`,
    dq_country__san_marino: `산마리노`,
    dq_country__sao_tome_and_principe: `상 토메와 프린시페`,
    dq_country__saudi_arabia: `사우디아라비아`,
    dq_country__senegal: `세네갈`,
    dq_country__serbia_and_montenegro: `세르비아 몬테네그로`,
    dq_country__seychelles: `세이셸`,
    dq_country__sierra_leone: `시에라리온`,
    dq_country__singapore: `싱가포르`,
    dq_country__slovakia: `슬로바키아`,
    dq_country__slovenia: `슬로베니아`,
    dq_country__solomon_islands: `솔로몬 제도`,
    dq_country__somalia: `소말리아`,
    dq_country__south_africa: `남아프리카 공화국`,
    dq_country__south_georgia_and_the_south_sandwich_islands: `사우스 조지아 및 사우스 샌드위치 제도`,
    dq_country__spain: `스페인`,
    dq_country__sri_lanka: `스리랑카`,
    dq_country__sudan: `수단`,
    dq_country__suriname: `수리남`,
    dq_country__svalbard_and_jan_mayen: `스발바르와 얀 마옌`,
    dq_country__swaziland: `스와질란드`,
    dq_country__sweden: `스웨덴`,
    dq_country__switzerland: `스위스`,
    dq_country__syrian_arab_republic: `시리아`,
    dq_country__taiwan_province_of_china: `대만`,
    dq_country__tajikistan: `타지키스탄`,
    dq_country__tanzania_united_republic_of: `탄자니아`,
    dq_country__thailand: `태국`,
    dq_country__timor_leste: `동티모르`,
    dq_country__togo: `토고`,
    dq_country__tokelau: `토켈라우`,
    dq_country__tonga: `통가`,
    dq_country__trinidad_and_tobago: `트리니다드 토바고`,
    dq_country__tunisia: `튀니지`,
    dq_country__turkey: `터키`,
    dq_country__turkmenistan: `투르크메니스탄`,
    dq_country__turks_and_caicos_islands: `터크스 케이커스 제도`,
    dq_country__tuvalu: `투발루`,
    dq_country__uganda: `우간다`,
    dq_country__ukraine: `우크라이나`,
    dq_country__united_arab_emirates: `아랍 에미리트 연합국`,
    dq_country__united_kingdom: `영국`,
    dq_country__united_states: `미국`,
    dq_country__united_states_minor_outlying_islands: `미국령 제도`,
    dq_country__uruguay: `우루과이`,
    dq_country__uzbekistan: `우즈베키스탄`,
    dq_country__vanuatu: `바누아투`,
    dq_country__venezuela: `베네수엘라`,
    dq_country__viet_nam: `베트남`,
    dq_country__virgin_islands_british: `버진 아일랜드, 영국`,
    dq_country__virgin_islands_us: `버진 아일랜드, 미국`,
    dq_country__wallis_and_futuna: `월리스와 푸투나`,
    dq_country__western_sahara: `서사하라`,
    dq_country__yemen: `예멘`,
    dq_country__zambia: `잠비아`,
    dq_country__zimbabwe: `짐바브웨`,
    dq_demographic__postal_code: `우편번호`,
    dq_demographic__next: `다음`,
    dq_demographic__plz_fill_req_fields: `필수 정보를 기입해주세요.`,
    uo__lets_start: `시작해봅시다.`,
    uo__dct_test_intro_message_text: `이 테스트는 여러분의 디지털 시민의식 수준을 확인하도록 돕고, 여러분이 디지털 기기와 미디어를 안전하고 책임감 있게 사용할 준비가 되어 있는지 알려줍니다.`,
    quiz_result_see_your_score: `여러분의 점수를 확인하세요!`,
    post_quiz_resume_test: `테스트 계속하기`,
    uo__username: `사용자 이름`,
    uo__failed_to_proceed: `진행하지 못했습니다!`,
    uo__save_failed_try_again: `저장 실패! 다시 시도하십시오...`,
    uo__continue_as_guest: `게스트로 계속하시겠습니까?`,
    uo__guest_login: `게스트 로그인`,
}


export const LC_VALUES_APP = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::app:: keys are in sync');
    } else {
        console.log('lc_values::app:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();

