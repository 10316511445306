import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import BarChart from "./BarChart";
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {X} from "react-feather";
import {CustomSelect} from "./Select.comp";
import XLSX from "xlsx";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAuth} from "../../../../context/auth";
import {getEnhancementAdoptionGender} from "../../../../services/partner.service";
import {alphaNumericSort, predefinedSort} from "../../../../utils/utils";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

function ByGenderTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [instituteCodes, setInstituteCodes] = useState(undefined);
    const [modules, setModules] = useState(undefined);

    const regionHeader = "Select a region";
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({region: undefined});

    const postalHeader = "Select a postal code";
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState({postal: undefined});

    const areaHeader = "Select an institution code";
    const [defaultAreas, setDefaultAreas] = useState([]);
    const [additionalAreas, setAdditionalAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState({area: undefined});

    const moduleHeader = "Select a module";
    const [defaultModules, setDefaultModules] = useState([]);
    const [additionalModules, setAdditionalModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState({module: undefined});

    const [graphData, setGraphData] = useState([]);
    const [header, setHeader] = useState("");

    useEffect( () => {
        loadPageData().then(d => console.log('Enhancement Adoption Gender Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionGender(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Enhancement Adoption Gender --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption gender data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function initializeData(result) {
        const {regions: _regions, postal_codes, codes, modules: _modules, data} = result;
        if(data)
        {
            setPageData(data);
        }
        if(_modules)
        {
            setModules(_modules);
        }
        if(codes)
        {
            setInstituteCodes(codes);
        }
        if(postal_codes)
        {
            setPostalCodes(postal_codes);
        }
        if(_regions)
        {
            _regions.sort().sort(predefinedSort(["All Regions"]))
            const regionSubLists = splitList(_regions, subListDivision)
            setDefaultRegions(regionSubLists[0]);
            setAdditionalRegions(regionSubLists[1])
            setRegions(_regions);
            setSelectedRegion({region: regionSubLists[0][0]})
        }
    }

    useEffect(() => {
        if(selectedRegion.region && postalCodes)
        {
            const postals = postalCodes[selectedRegion.region];
            postals.sort((a, b) => a - b).sort(predefinedSort(["All Postal Codes"]))
            const postalSubLists = splitList(postals, subListDivision)
            setDefaultPostal(postalSubLists[0]);
            setAdditionalPostal(postalSubLists[1]);
            setSelectedPostal({postal: postalSubLists[0][0]});
        }
        else
        {
            setDefaultPostal([])
            setAdditionalPostal([])
            setDefaultAreas([])
            setAdditionalAreas([])
            setDefaultModules([])
            setGraphData([])
            setHeader("");
        }

    }, [selectedRegion])

    useEffect(() => {
        if(selectedPostal.postal && instituteCodes)
        {
            const _codes = instituteCodes[selectedRegion.region][selectedPostal.postal]
            _codes.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["All Institution Codes"]))
            const areaSubLists = splitList(_codes, subListDivision)
            setDefaultAreas(areaSubLists[0]);
            setAdditionalAreas(areaSubLists[1]);
            setSelectedArea({area: areaSubLists[0][0]});
        }
        else
        {
            setDefaultAreas([])
            setAdditionalAreas([])
            setDefaultModules([])
            setGraphData([]);
            setHeader("")
        }
    }, [selectedPostal])

    useEffect(() => {
        if(selectedArea.area && modules)
        {
            const _modules = modules[selectedRegion.region][selectedPostal.postal][selectedArea.area]
            _modules.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["All Modules"]))
            setDefaultModules(_modules);
            setSelectedModule({module: _modules[0]});
        }
        else
        {
            setDefaultModules([])
            setGraphData([]);
            setHeader("")
        }
    }, [selectedArea])

    useEffect(() => {
        if(selectedModule.module && pageData)
        {
            setGraphData(pageData[selectedRegion.region][selectedPostal.postal][selectedArea.area][selectedModule.module]);
            setHeader(`${selectedRegion.region} - ${selectedPostal.postal} - ${selectedArea.area} - ${selectedModule.module}`);
        }
        else
        {
            setGraphData([]);
            setHeader("")
        }
    }, [selectedModule])

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onAddRegion(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setState({
            ...Object.fromEntries(defaultRegions.map((region) => [region, false])),
            [item]: true,
        });
        setSelectedRegion({region: item});
    }

    function onRemoveRegion(item, state, setState) {

        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setState({
            ...Object.fromEntries(newDefaultRegions.map((region, index) => index === 0 ? [region, true] : [region, false])),
        });
        setSelectedRegion({region: newDefaultRegions[0]});
    }

    function onSelectRegion(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedRegion({region: item})
            setState(Object.fromEntries(defaultRegions.map(
                (region) => region === item ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onAddPostal(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setState({
            ...Object.fromEntries(defaultPostal.map((postal) => [postal, false])),
            [item]: true,
        });
        setSelectedPostal({postal: item});
    }

    function onRemovePostal(item, state, setState) {

        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setState({
            ...Object.fromEntries(newDefaultPostal.map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
        });
        setSelectedPostal({postal: newDefaultPostal[0]});
    }

    function onSelectPostal(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedPostal({postal: item})
            setState(Object.fromEntries(defaultPostal.map(
                (postal) => postal === item ? [postal, true] : [postal, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onAddArea(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalAreas, setAdditionalAreas);
        addItemToState(item, defaultAreas, setDefaultAreas);
        setState({
            ...Object.fromEntries(defaultAreas.map((area) => [area, false])),
            [item]: true,
        });
        setSelectedArea({area: item});
    }

    function onRemoveArea(item, state, setState) {

        const newDefaultAreas = removeItemFromState(item, defaultAreas, setDefaultAreas)
        addItemToState(item, additionalAreas, setAdditionalAreas, true)
        setState({
            ...Object.fromEntries(newDefaultAreas.map((area, index) => index === 0 ? [area, true] : [area, false])),
        });
        setSelectedArea({area: newDefaultAreas[0]});
    }

    function onSelectArea(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedArea({area: item})
            setState(Object.fromEntries(defaultAreas.map(
                (area) => area === item ? [area, true] : [area, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onSelectModule(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedModule({module: item})
            setState(Object.fromEntries(defaultModules.map(
                (module) => module === item ? [module, true] : [module, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    let sums = {
        participants: 0,
    }
    if(graphData)
    {
        graphData.forEach((item) => {
            if(item.gender === "Total")
                sums.participants = item.participants;
        })
    }

    const onClickDownload = (event) => {

        const genders = pageData ? pageData["All Regions"]["All Postal Codes"]["All Institution Codes"]["All Modules"].map((item) => item.gender).filter((item) => item.toLowerCase() !== "total") : []
        const workbook = XLSX.utils.book_new();
        const final_array = []
        const columns = ["Region", "Postal Code", "Institution Code", "Module", ...genders]
        final_array.push(columns)

        let max_region_column_width = 10;
        let max_institute_column_width = 10;

        regions.forEach((region) => {
            if (region !== "All Regions") {
                const postals = postalCodes[region];
                postals.forEach((postal) => {
                    if(postal !== "All Postal Codes")
                    {
                        const institutes = instituteCodes[region][postal];
                        institutes.forEach((institute) => {
                            if(institute !== "All Institution Codes")
                            {
                                const _modules = modules[region][postal][institute];
                                _modules.forEach((module) => {
                                    if(module !== "All Modules")
                                    {
                                        const values = genders.map(() => "0");
                                        pageData[region][postal][institute][module].forEach((item) => {
                                            const index = genders.indexOf(item.gender);
                                            if(index !== -1)
                                            {
                                                values.splice(index, 1, item.participants.toString())
                                            }
                                        })
                                        final_array.push([region, postal, institute, module, ...values])
                                    }
                                })
                                max_institute_column_width = Math.max(max_institute_column_width, institute.length)
                            }
                        })
                    }
                })
                max_region_column_width = Math.max(max_region_column_width, region.length)
            }
        })
        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = columns.map((column, index) => {
            let width;
            switch(column)
            {
                case "Region":
                    width = Math.max(max_region_column_width, 10);
                    break;
                case "Institution Code":
                    width = Math.max(max_institute_column_width, 10);
                    break;
                default:
                    width = Math.max(column.length, 10);
                    break;
            }
            return { wch: width }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Participants By Gender");
        XLSX.writeFile(workbook, "Enhancement Adoption - By Gender.xlsx");
    }

    return (
        pageData ?
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultRegions, defaultPostal, defaultAreas, defaultModules]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal, onSelectArea, onSelectModule]}
                        headers = {[regionHeader, postalHeader, areaHeader, moduleHeader]}
                        colourize = {[false, false, false, false]}
                        actions = {[X, X, X]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal, onRemoveArea]}
                        footers = {[CustomSelect, CustomSelect, CustomSelect]}
                        footersData = {[
                            {
                                title: "Add Region",
                                data: additionalRegions,
                            },
                            {
                                title: "Add Postal",
                                data: additionalPostal,
                            },
                            {
                                title: "Add Institute",
                                data: additionalAreas,
                            },
                        ]}
                        footersCallbacks = {[onAddRegion, onAddPostal, onAddArea]}
                        defaultCheckIndices = {[]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper container mt={10} mb={5}>
                        <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                            {header === "" ?
                                (!selectedRegion.region ?
                                    "Select a region to display data"
                                    : !selectedPostal.postal ?
                                        "Select a postal code to display data"
                                        : !selectedArea.area ?
                                            "Select an institution code to display data"
                                            : "Select a module to display data"
                                )
                                : header
                            }
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <BarChart
                                            labels={[...graphData.filter((item) => item.gender !== "Total").map((item) => item.gender)]}
                                            values={[...graphData.filter((item) => item.gender !== "Total").map((item) => item.participants)]}
                                            color={true}
                                            threshold={sums.participants*0.3}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell align="center">
                                                        <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                            Gender
                                                        </DefaultColouredTypography>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                            # of participants
                                                        </DefaultColouredTypography>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                            % of participants
                                                        </DefaultColouredTypography>
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    graphData.map((item, index) => (
                                                        item.gender !== "Total" ?
                                                            <CustomTableRow key={index}>
                                                                <CustomTableCell align="center">{item.gender}</CustomTableCell>
                                                                <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                            :
                                                            <CustomTableRow key={"sum"}>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{item.gender}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{item.participants}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>
                                                                        {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                    </Bold>
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <ColouredTypography variant="body1" color={"#ff69b4"} weight={"bold"} textAlign={"center"}>
                            {`Requires Attention`}
                            <ColouredTypography variant="body1" component={"span"}>
                                , if the female or male participants are
                                <ColouredTypography variant="body1" weight={"bold"} component={"span"}>
                                    {` less than 30% `}
                                </ColouredTypography>
                                of the total participants.
                            </ColouredTypography>
                        </ColouredTypography>
                        <Box mt={5} mr={5} align={"right"}>
                            <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                Download Data
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
        :
        <Paper container>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ByGenderTab;