import React from "react";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  border: 1px solid grey;
  border-radius: 20px;
  aspect-ratio: 2 / 1;
  margin-left: 10px;
  margin-right: 10px;
  width: 200px;
  height: 140px;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const H4 = styled(Box)`
  display: flex;
  height: 70%;
  justify-content: center;
  align-items: center;
`

const H2 = styled(Box)`
  display: flex;
  height: 40%;
  justify-content: center;
  align-items: center;
`


export const Badge = ({ title, value }) => {
    return (
        <Card mb={3} style={{minWidth: "fit-content"}}>
            <CardContent>
                <H4>
                    <DefaultColouredTypography variant="h4" mb={5} align={'center'} fontWeight="fontWeightBold">
                        {title}
                    </DefaultColouredTypography>
                </H4>
                <H2>
                    <ColouredTypography variant="h2" mb={3} align={'center'}>
                        <Box fontWeight="fontWeightBold">{value}</Box>
                    </ColouredTypography>
                </H2>
            </CardContent>
        </Card>
    );
};
