import React, {useEffect} from 'react';
import { Route, useHistory, useLocation  } from 'react-router-dom';
import {urlRewriteUtil} from "../Wrapper/common.wrapper";

const RouteWithLayout = props => {

    const history = useHistory();
    const location = useLocation();
    const { layout: Layout, component: Component, ...rest } = props;

    useEffect( () => {
        urlRewriteUtil(location, history);
    }, [] )

    return (
        <Route
          {...rest}
          render={matchProps => (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )}
        />
    );
};

export default RouteWithLayout;
