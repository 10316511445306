import React from "react";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;


const TopSectionBadge = ({ title, value }) => {
    return (
        <Card mb={3}>
            <CardContent>
                <Typography variant="h4" mb={8} align={'center'}>
                    {title}
                </Typography>
                <Typography variant="h2" mb={5} align={'center'} color={'primary'}>
                    <Box fontWeight="fontWeightBold">{value}</Box>
                </Typography>

            </CardContent>
        </Card>
    );
};

export default TopSectionBadge;
