import {store} from "react-notifications-component";

export const toastSuccess = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "success",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true
        }
    });
}

export const toastWarning = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true
        }
    });
}

export const toastDanger = (title, message) => {
    store.addNotification({
        title: title,
        message,
        type: "danger",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true
        }
    });
}

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])

}

export const getPresenceMap = (simple_list) => {
    let dict = {};
    for (let item of simple_list) {
        dict[item] = true;
    }
    return dict;
}

export function predefinedSort(order_list, key, reverse = false)
{
    const order = order_list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1}), {});
    const getVal = item => {
        if(key)
            return  order[item[key]] || Infinity
        else //else is redundant here i.e. can be removed
            return order[item] || Infinity
    }

    return (a, b) => {
        const a_index = getVal(a);
        const b_index = getVal(b);

        if(a_index === Infinity && b_index === Infinity) {
            return 0;
        }
        else { //else is redundant here i.e. can be removed
            if(reverse)
            {
                return b_index - a_index;
            }
            else
            {
                return a_index - b_index;
            }
        }
    }
}

export function alphaNumericSort(key = undefined, numeric = false) //numeric makes numbers sort first than alphabets
{
    if(key)
    {
        return (a, b) => a[key].localeCompare(b[key], undefined, {
            numeric: numeric,
            sensitivity: 'base'
        });
    }
    else
    {
        return (a, b) => a.localeCompare(b, undefined, {
            numeric: numeric,
            sensitivity: 'base'
        });
    }
}

export function getUniqueValues(array) //also handles getting unique values from nested array
{
    return [...new Set([].concat(...array))];
}