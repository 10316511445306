import axios from 'axios';
import { store } from 'react-notifications-component';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

axios.defaults.headers.post['Content-Type'] = 'application/json';
const base_uri = process.env.REACT_APP_MYDQ_API;

const toastError = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 2000,
            showIcon: true
        }
    });
}

export async function invokeApi(
    {
        path,
        method = 'GET',
        headers = {},
        queryParams = {},
        postData = {},
    }
) {
    let reqObj = {
        method  : method,
        url     : base_uri + path,
        headers : headers
    };

    reqObj['params'] = queryParams;

    if (method === 'POST') {
        reqObj['data'] = postData;
    }

    let results = undefined;

    try {
        results = await axios(reqObj);
        return results.data;
    } catch (error) {

        if (error.response.status === 499) throw new Error('Permission denied');

        if(error.response.status === 401) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            window.location.reload();
        }

        if (!(error.response && error.response.data && error.response.data.message)) {
            toastError('NETWORK ERROR : Some Thing Went Wrong!');
            throw new Error('NETWORK ERROR : Some Thing Went Wrong!');

        } else {
            toastError(error.response.data.message);
            throw new Error(error.response.data.message);
        }
    }
}
