import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { withRouter } from "react-router";
import { store } from 'react-notifications-component';

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {resetPassword} from '../../../services/auth.service';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Helmet from "react-helmet";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PasswordReset = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            reset_code: '',
            password: '',
            confirm_password: ''
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            if (values.password !== values.confirm_password) {
                return store.addNotification({
                    title: "Password MissMatch",
                    message: "Please provide the same password in both password fields!",
                    type: "warning",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
            }

            try {
                const result = await resetPassword(values.reset_code, values.password);
                console.log('result.data -> ', result.data);

                store.addNotification({
                    title: "Password Updated",
                    message: "Successfully Changed Password",
                    type: "success",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });

                props.history.push('/login');
            } catch (e) {
                // already toasted the error
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <Helmet title="DQ-PRO | Reset Password" />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="reset_code"
                            label="Reset Code"
                            type="text"
                            id="reset_code"
                            autoComplete="current-password"
                            onChange={formik.handleChange}
                            value={formik.values.reset_code}
                        />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            id="confirm_password"
                            autoComplete="current-password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                        />
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Set Password
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                Back to Login Page
                            </Link>
                        </Grid>
                    </Grid>

                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default withRouter(PasswordReset);