import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {
    getAssessmentIntention,
} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles,
    withStyles, Button as MuiButton,
} from "@material-ui/core";

import ColoredLinearProgress from "./ColouredLinearProgress.comp";

import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {useAuth} from "../../../../context/auth";
import XLSX from "xlsx";
import {alphaNumericSort, predefinedSort} from "../../../../utils/utils";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;

  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const LinearProgress = styled(ColoredLinearProgress)`
  height: 14px;
  border-radius: 3px;
`

function LinearProgressWithLabel(props) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={8}>
                <LinearProgress variant="determinate" {...props} />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value,)}%`}</Typography>
            </Grid>
        </Grid>
    );
}

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function AcquisitionTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [regions, setRegions] = useState([]);
    const [categories, setCategories] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Intention Data Fetched!', d));
    }, []);

    const selectAllRegionsOption = "Entire Region"

    function initializeData(data) {
        data.regions.sort(predefinedSort([selectAllRegionsOption]))
        setSelectedRegion([selectAllRegionsOption])
        setSelectedCategory(data.categories[0])
        setRegions(data.regions)
        setCategories(data.categories)
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentIntention(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Intention --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching intention data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const primarySideHeader = "Select a Region"
    const secondarySideHeader= "Select a Skill Category"

    function onSelectRegion (selected_item, is_checked, state, setState) {
        if(is_checked) {
            setSelectedRegion([selected_item])
            setState(Object.fromEntries(regions.map(
                (region) => region == selected_item ? [region, true] : [region, false]
            )));
        }
        else
        {
            if(selected_item == selectAllRegionsOption)
            {
                setSelectedRegion([])
            }
            else {
                if (selectedRegion.includes(selected_item)) {
                    let copyArray = [...selectedRegion]
                    copyArray.splice(copyArray.indexOf(selected_item), 1)
                    setSelectedRegion(copyArray)
                }
            }
        }
    }

    function onSelectSkillCategory (selected_item, is_checked, state, setState) {
        if(is_checked)
        {
            setSelectedCategory(selected_item)
            setState(Object.fromEntries(categories.map(
                (category) => category == selected_item ? [category, true] : [category, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [selected_item]: true,
            }));
        }
    }

    let sums = {
        participants: 0,
        skills: 0,
    }

    const items = [];
    if(pageData) {
        selectedRegion.forEach((region) => {
            pageData.data[region][selectedCategory].forEach((item) => {
                console.log(region, item);
                sums.participants += item.participants;
                sums.skills += item.skills;
                const index = items.findIndex((x) => x.topic === item.topic);
                if(index === -1)
                {
                    items.push({...item})
                }
                else
                {
                    items[index].participants += item.participants;
                    items[index].skills += item.skills;
                }
            })
        })
    }
    const header = {
        region: selectedRegion.length == regions.length ? selectAllRegionsOption : (selectedRegion.length == 0 ? "No Region" : selectedRegion),
        category: selectedCategory ? selectedCategory : "No Category",
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const columns = ["Region", "Topic", "Participants", "Intention for Change Participants", "Intention for Change Rate"]
        categories.forEach((category) => {
            const final_array = []
            let max_column_width = 10;
            final_array.push(columns)
            regions.forEach((region) => {
                pageData.data[region][category].forEach((item) => {
                    final_array.push([region, item.topic, item.participants.toString(), item.skills.toString(), `${Math.round(item.skills/item.participants*100)}%`])
                })
                max_column_width = Math.max(max_column_width, region.length)
            })
            const worksheet = XLSX.utils.aoa_to_sheet(final_array);
            worksheet["!cols"] = columns.map((column, index) => {
                return { wch: index == 0 ? max_column_width : Math.max(10, column.length) }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, category);
        })
        XLSX.writeFile(workbook, "Assessment Efficacy - Intention for Change.xlsx");
    }


    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={[...regions]}
                            secondaryData={categories}
                            onCheckPrimary={onSelectRegion}
                            onCheckSecondary={onSelectSkillCategory}
                            primarySingleSelectIndex={0}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} align={"right"}>
                        <Card mb={3}>
                            <CardContent>
                                <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"} align={"center"} alignText={"justify"} mb={10}>
                                    What is the proportion of the participants who have shown the intention to change behaviour in at least one digital skill positively after completing the Program?
                                </ColouredTypography>
                                <ColouredTypography mb={10} variant="h4">
                                    {`${header.region} - ${header.category}`}
                                </ColouredTypography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell align="center">
                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                    Topic
                                                </DefaultColouredTypography>
                                            </CustomTableCell>
                                            <CustomTableCell align="center">
                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                    Participants
                                                </DefaultColouredTypography>
                                            </CustomTableCell>
                                            <CustomTableCell align="center">
                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                    Intention for Change
                                                </DefaultColouredTypography>
                                            </CustomTableCell>
                                            <CustomTableCell align="center">
                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                    Intention for Change Rate
                                                </DefaultColouredTypography>
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            items.sort(alphaNumericSort("topic", true)).sort(predefinedSort(["Total"], "topic", true)).map((item, index) => (
                                                <CustomTableRow key={index}>
                                                    {
                                                        item.topic == "Total" ?
                                                            <>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{item.topic}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{item.participants}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{item.skills}</Bold>
                                                                </CustomTableCell>
                                                            </>
                                                            :
                                                            <>
                                                                <CustomTableCell align="center">{item.topic}</CustomTableCell>
                                                                <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                <CustomTableCell align="center">{item.skills}</CustomTableCell>
                                                            </>

                                                    }
                                                    <CustomTableCell align="center">
                                                        <LinearProgressWithLabel value={Math.round(item.skills/item.participants*100)}>
                                                        </LinearProgressWithLabel>
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                <Button mt={5} variant="contained" onClick={onClickDownload}>
                                    Download Data
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading} />
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default AcquisitionTab;