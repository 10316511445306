import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const LicensesInfoService = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/licenses/info',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const LicensesPacksListService = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/licenses/packs_list',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const PostUsersCVS = async (org_code, data) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/licenses/post_users_csv',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    const o_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);
    let org_type;
    if (o_type+'' === ORG_TYPE.SCHOOL+'') {
        org_type = ORG_TYPE.SCHOOL;
    } else {
        org_type = ORG_TYPE.COMPANY;
    }

    requestObj['postData'] = {
        org_code,
        org_type,
        data
    };
    return invokeApi(requestObj);
}