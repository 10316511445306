import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {CustomSelect} from "./Select.comp";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import {alphaNumericSort, predefinedSort, toastDanger} from "../../../../utils/utils";
import {X} from "react-feather";
import XLSX from "xlsx";
import {useAuth} from "../../../../context/auth";
import {getEnhancementAdoptionRegion} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color :props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

function ByRegionTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [instituteCodes, setInstituteCodes] = useState(undefined);

    const regionHeader = "Select a region";
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const postalHeader = "Select a postal code";
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState(undefined);

    const areaHeader = "Select an institute code";
    const [defaultAreas, setDefaultAreas] = useState([]);
    const [additionalAreas, setAdditionalAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(undefined);
    const [graphData, setGraphData] = useState([]);
    const [header, setHeader] = useState("");

    useEffect( () => {
        loadPageData().then(d => console.log('Enhancement Adoption Region Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionRegion(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Enhancement Adoption Region --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption region data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function initializeData(result) {
        const {regions: _regions, postal_codes, codes, data} = result;
        if(data)
        {
            setPageData(data);
        }
        if(codes)
        {
            setInstituteCodes(codes);
        }
        if(postal_codes)
        {
            setPostalCodes(postal_codes);
        }
        if(_regions)
        {
            _regions.sort().sort(predefinedSort(["All Regions"]))
            const regionSubLists = splitList(_regions, subListDivision)
            setDefaultRegions(regionSubLists[0]);
            setAdditionalRegions(regionSubLists[1])
            setRegions(_regions);
            setSelectedRegion(regionSubLists[0][0])
        }
    }

    useEffect(() => {
        if(selectedRegion && postalCodes && pageData)
        {
            const postals = postalCodes[selectedRegion];
            postals.sort((a, b) => a - b);
            const postalSubLists = splitList(postals, subListDivision)

            setDefaultPostal(postalSubLists[0]);
            setAdditionalPostal(postalSubLists[1]);
            setGraphData(pageData[selectedRegion].sort(alphaNumericSort("title", true)));
            setHeader(selectedRegion)
        }
        else {
            setDefaultPostal([])
            setAdditionalPostal([])
            setDefaultAreas([])
            setAdditionalAreas([])
            setGraphData([])
            setHeader("");
        }
    }, [selectedRegion])

    useEffect(() => {
        if(selectedPostal && instituteCodes && pageData)
        {
            const areaSubLists = splitList(instituteCodes[selectedPostal], subListDivision)
            setDefaultAreas(areaSubLists[0]);
            setAdditionalAreas(areaSubLists[1]);
            setGraphData(pageData[selectedPostal].sort(alphaNumericSort("title", true)));
            setHeader(selectedPostal);
        }
        else {
            setDefaultAreas([])
            setAdditionalAreas([])
            if(selectedRegion && pageData) {
                setGraphData(pageData[selectedRegion].sort(alphaNumericSort("title", true)));
                setHeader(selectedRegion)
            }
            else {
                setGraphData([]);
                setHeader("")
            }
        }
    }, [selectedPostal])

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onAddRegion(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setState({
            ...Object.fromEntries(defaultRegions.map((region) => [region, false])),
            [item]: true,
        });
        setSelectedRegion(item);
    }

    function onRemoveRegion(item, state, setState) {

        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setState({
            ...Object.fromEntries(newDefaultRegions.map((region, index) => index === 0 ? [region, true] : [region, false])),
        });
        setSelectedRegion(newDefaultRegions[0]);
    }

    function onSelectRegion(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedRegion(item)
            setState(Object.fromEntries(defaultRegions.map(
                (region) => region === item ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onAddPostal(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setState({
            ...Object.fromEntries(defaultPostal.map((postal) => [postal, false])),
            [item]: true,
        });
        setSelectedPostal(item);
    }

    function onRemovePostal(item, state, setState) {

        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setState({
            ...Object.fromEntries(newDefaultPostal.map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
        });
        setSelectedPostal(newDefaultPostal[0]);
    }

    function onSelectPostal(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedPostal(item)
            setState(Object.fromEntries(defaultPostal.map(
                (region) => region === item ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onAddArea(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalAreas, setAdditionalAreas);
        addItemToState(item, defaultAreas, setDefaultAreas);
        setState({
            ...Object.fromEntries(defaultAreas.map((area) => [area, false])),
            [item]: true,
        });
        setSelectedArea(item);
    }

    function onRemoveArea(item, state, setState) {

        const newDefaultAreas = removeItemFromState(item, defaultAreas, setDefaultAreas)
        addItemToState(item, additionalAreas, setAdditionalAreas, true)
        setState({
            ...Object.fromEntries(newDefaultAreas.map((area, index) => index === 0 ? [area, true] : [area, false])),
        });
        setSelectedArea(newDefaultAreas[0]);
    }

    function onSelectArea(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedArea(item)
            setState(Object.fromEntries(defaultAreas.map(
                (area) => area === item ? [area, true] : [area, false]
            )));
        }
        // else
        // {
        //     setState({
        //         ...state,
        //         [item]: true
        //     });
        // }
    }

    let sums = {
        participants: 0,
    }
    if(graphData)
    {
        graphData.forEach((item, index) => {
            sums.participants += item.participants;
        })
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        //By Region
        const region_array = []
        const region_columns = ["Region", "Participants"]
        region_array.push(region_columns)
        //By Postal Code
        const postal_array = []
        const postal_columns = ["Region", "Postal Code", "Participants"]
        postal_array.push(postal_columns)
        //By Institute Code
        const institute_array = []
        const institute_columns = ["Region", "Postal Code", "Institution Code", "Participants"]
        institute_array.push(institute_columns)

        let max_column_width = 10;
        regions.forEach((region) => {
            if (region === "All Regions") {
                pageData[region].forEach((item) => {
                    region_array.push([item.title, item.participants.toString()])
                })
            } else {
                pageData[region].forEach((item) => {
                    const postal_code = item.title;
                    postal_array.push([region, postal_code, item.participants.toString()])
                    pageData[postal_code].forEach((subItem) => {
                        const institute = subItem.title;
                        institute_array.push([region, postal_code, institute, subItem.participants.toString()])
                        max_column_width = Math.max(max_column_width, institute.length)
                    })
                })
            }
        })
        const worksheets_data = [
            {
                name: "Participants By Region",
                columns: region_columns,
                array: region_array,
            },
            {
                name: "Participants By Postal Code",
                columns: postal_columns,
                array: postal_array,
            },
            {
                name: "Participants By Institution Code",
                columns: institute_columns,
                array: institute_array,
            },
        ]
        worksheets_data.forEach((data) => {
            const {name, columns, array} = data;
            const worksheet = XLSX.utils.aoa_to_sheet(array);
            worksheet["!cols"] = columns.map((column, index) => {
                return { wch: max_column_width}
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, name);
        })
        XLSX.writeFile(workbook, "Enhancement Adoption - By Region.xlsx");
    }

    return (
        pageData ?
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultRegions, defaultPostal]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal]}
                        headers = {[regionHeader, postalHeader]}
                        colourize = {[false, false]}
                        actions = {[X, X, X]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal]}
                        footers = {[CustomSelect, CustomSelect]}
                        footersData = {[
                            {
                                title: "Add Region",
                                data: additionalRegions,
                            },
                            {
                                title: "Add Postal",
                                data: additionalPostal,
                            },
                        ]}
                        footersCallbacks = {[onAddRegion, onAddPostal]}
                        defaultCheckIndices = {[0, undefined]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper container mt={10} mb={5}>
                        <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                            {header === "" ? "Select a region to display data" : header}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardContent>
                                        <BarChart
                                            labels={[...graphData.map((item) => item.title)]}
                                            values={[...graphData.map((item) => item.participants)]}
                                            color={true}
                                            threshold={sums.participants*0.1}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardContent>
                                        {
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <CustomTableCell align="center">
                                                            <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                { graphData.length > 0 && regions.includes(graphData[0].title) ?
                                                                    "Region" : graphData.length > 0 && Object.keys(instituteCodes).includes(graphData[0].title) ?
                                                                        "Postal Code" : "Institution Code"
                                                                }
                                                            </DefaultColouredTypography>
                                                        </CustomTableCell>
                                                        <CustomTableCell align="center">
                                                            <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                # of participants
                                                            </DefaultColouredTypography>
                                                        </CustomTableCell>
                                                        <CustomTableCell align="center">
                                                            <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                % of participants
                                                            </DefaultColouredTypography>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        graphData.map((item, index) => (
                                                            <CustomTableRow key={index}>
                                                                <CustomTableCell align="center">{item.title}</CustomTableCell>
                                                                <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        ))
                                                    }
                                                    <CustomTableRow key={"sum"}>
                                                        <CustomTableCell align="center">
                                                            <Bold>Total</Bold>
                                                        </CustomTableCell>
                                                        <CustomTableCell align="center">
                                                            <Bold>{sums.participants}</Bold>
                                                        </CustomTableCell>
                                                        <CustomTableCell align="center">
                                                            <Bold>{Math.round(sums.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%</Bold>
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                </TableBody>
                                            </Table>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <ColouredTypography variant="body1" color={"#ff69b4"} weight={"bold"} textAlign={"center"}>
                            {`Requires Attention`}
                            <ColouredTypography variant="body1" component={"span"}>
                                , if the number of participants in the region is
                                <ColouredTypography variant="body1" weight={"bold"} component={"span"}>
                                    {` less than 10% `}
                                </ColouredTypography>
                                of the total participants.
                            </ColouredTypography>
                        </ColouredTypography>
                        <Box mt={5} mr={5} align={"right"}>
                            <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                Download Data
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
        :
        <Paper container>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ByRegionTab;