import React from "react";
import Link from "@material-ui/core/Link";

const lc_en = {
    home__analytics_dashboard: "Analytics Dashboard",
    main__overview: "Overview",
    contact_us: "Contact Us",
    home__company_percentile: "Company Percentile",
    home__overall_dq_score: "Overall DQ Score",
    home__cyber_risk_vulnerability: "Cyber-Risk Vulnerability",
    home__cyber_risk: "Cyber-Risks",
    home__dq_microbadges: "DQ MicroBadges",
    home__dq_modules: "DQ Modules",
    home__dq_assessments: "DQ Assessments",
    home__average_score: "Average Score",
    home__company_national: "(Company/National)",
    home__school_national: "(School/National)",
    home__completion_rate: "Completion Rate",
    main__dark_mode: "Dark Mode",
    main__sign_out: "Sign Out",
    main__change_password: "Change Password",
    home__details: "Details",
    main__footer_support: "Support",
    main__footer_help_center: "Help Center",
    main__employees: "Employees",
    main__list: "List",
    main__pending_approvals: "Pending Approvals",
    main__licenses: "Licenses",
    main__orders: "Orders",
    main__employee_list: "Employee List",
    main__name: "Name",
    employees__list: "List",
    employees__approve_all: "Approve All",
    employees__approve: "Approve",
    employees__reject: "Reject",
    licenses__total_licenses: "Total Licenses",
    licenses__used_licenses: "Used Licenses",
    licenses__unused_licenses: "Unused Licenses",
    licenses__add_users: "Add Users",
    licenses__download_csv: "Download CSV",
    licenses__upload_csv: "Upload CSV",
    licenses__download_the_csv_file: "1. Download the CSV file.",
    licenses__fill_out_the_csv_file_completely: "2. Fill out the CSV file completely. Do not leave any fields blank. You will need your employees first and last name, department, job title, birthdate, email address, and gender.",
    licenses__fill_out_the_csv_file_completely__school: "2. Fill out the CSV file completely. Do not leave any fields blank. You will need your students' first and last name, class name, class level, birthdate, parent email address, and gender.",
    licenses__upload_the_csv_file: "3. Upload the CSV file.",
    licenses__the_system_will_then_send_out_activation_emails: "4. The system will then send out activation emails to the users you listed in the CSV file. Once the user goes to the email, they will login with the activation link and set the password to proceed further.",
    licenses__the_system_will_then_send_out_activation_emails__school: "4. The system will then send out activation emails to the student’s parent you listed in the CSV file. Once the parent receives the email, they will need to login with the activation link and reset a new password for their child to proceed further. Please remember to have the parent pass the username and password to the child to login.",
    licenses__as_long_as_you_have_unused_licenses_p1: '5. As long as you have unused licenses, you can just add new accounts to the old CSV file and upload it. You can also adjust any user information in the CSV file except the email addresses. For changes to email addresses, please contact us at',
    licenses__as_long_as_you_have_unused_licenses_p2: '.',
    licenses__errors: 'Errors',
    orders__orders: "Orders",
    orders__order_id: "Order ID",
    orders__licenses_count: "Licenses Count",
    orders__status: "Status",
    orders__withdraw: "Withdraw",
    orders__not_applicable: "Not Applicable",
    orders__approved: "Approved",
    orders__create_order: "Create Order",
    orders__create: "Create",
    orders__create_new_order: "Create New Order",
    orders__order_details: "Order Details",
    contact_us__message: "Message",
    const__user_access_level_na: "N/A",
    const__user_access_level_no_access: "No Access",
    const__user_access_level_partial_access_admin: "Partial Access (Admin)",
    const__user_access_level_full_access_admin: "Full Access (Admin)",
    const__user_access_level_partial_access_analytics: "Partial Access (Analytics)",
    const__user_access_level_full_access_analytics: "Full Access (Analytics)",
    const__org_type_service: "SERVICE",
    const__org_type_company: "COMPANY",
    const__org_type_school: "SCHOOL",
    const__licenses_order_status_not_set: "Not Set",
    const__licenses_order_status_pending: "Pending",
    const__licenses_order_status_approved: "Approved",
    const__licenses_order_status_rejected: "Rejected",
    const__licenses_order_status_withdrawn: "Withdrawn",
    home__company_avg: "Company Avg.",
    home__national_avg: "National Avg.",
    home__global_avg: "Global Avg.",
    home__singtel_avg: "Singtel Avg.",
    main__footer_privacy: "Privacy",
    main__footer_terms_of_service: "Terms of Service",
    main__footer_all_rights_reserved: "DQ LAB Pte Ltd. All rights reserved.",
    home__top: "Top",
    home__risk: "Risk",
    main__dq_stats: "DQ Stats",
    main__profiles: "Profiles",
    main__stats: "Stats",
    main__students: "Students",
    main__pending_activation: "Pending Activation",
    employees__identifier: "Identifier",
    employees__name: "Name",
    employees__gender: "Gender",
    employees__dq_score: "DQ Score",
    employees__grade: "Grade",
    employees__actions: "Actions",
    employees__email: "Email",
    employees__first_name: "First Name",
    employees__last_name: "Last Name",
    employees__country: "Country",
    employees__postal_code: "Postal Code",
    employees__employee: "Employee",
    employees__student: "Student",
    employees__rows_per_page: "Rows per page:",
    profiles__profile_list: "Profiles List",
    profiles__identifier: "Identifier",
    profiles__first_name: "First Name",
    profiles__last_name: "Last Name",
    profiles__country: "Country",
    profiles__year_of_birth: "Year of Birth",
    profiles__gender: "Gender",
    profiles__email: "Email",
    profiles__org: "Organization",
    profiles__rows_per_page: "Rows per page:",
    stats_dq_stats: "DQ Stats",
    stats_total_users: "Total Users",
    stats_users_without_demographics: "Users without Demographics",
    stats_male_users: "Male Users",
    stats_female_users: "Female Users",
    stats_users_by_country: "Users By Country",
    stats_country_name: "Country Name",
    stats_users: "Users",

}


const lc_ko = {
    home__analytics_dashboard: "분석 대시보드",
    main__overview: "개요",
    contact_us: "문의하기",
    home__company_percentile: "기업 백분위",
    home__overall_dq_score: "DQ 총점",
    home__cyber_risk_vulnerability: "디지털 위험 취약도",
    home__cyber_risk: "디지털 위험",
    home__dq_microbadges: "마이크로배지",
    home__dq_modules: "모듈",
    home__dq_assessments: "평가",
    home__average_score: "평균 점수",
    home__company_national: "(기업/국가)",
    home__completion_rate: "완료율",
    main__dark_mode: "다크모드",
    main__sign_out: "로그아웃",
    main__change_password: "비밀번호 변경",
    home__details: "상세정보",
    main__footer_support: "지원",
    main__footer_help_center: "문의 센터",
    main__employees: "직원",
    main__list: "목록",
    main__pending_approvals: "승인 보류",
    main__licenses: "라이선스",
    main__orders: "주문",
    main__employee_list: "직원 목록",
    main__name: "이름",
    employees__list: "목록",
    employees__approve_all: "모두 승인하기",
    employees__approve: "승인하기",
    employees__reject: "거부하기",
    licenses__total_licenses: "전체 라이선스",
    licenses__used_licenses: "사용된 라이선스",
    licenses__unused_licenses: "사용되지 않은 라이선스",
    licenses__add_users: "사용자 추가하기",
    licenses__download_csv: "csv 다운로드",
    licenses__upload_csv: "csv 업로드",
    licenses__download_the_csv_file: "1. csv 파일을 다운로드 하세요.",
    licenses__fill_out_the_csv_file_completely: "2. csv 파일의 필수 정보를 하나도 빠짐 없이 기입하세요. 직원의 성명(성과 이름), 부서, 직함, 생년월일, 이메일 주소, 성별이 필요합니다.",
    licenses__upload_the_csv_file: "3. csv 파일을 업로드하세요.",
    licenses__the_system_will_then_send_out_activation_emails: "4. csv 파일에 포함된 직원에게 활성화 메일을 전송됩니다. 다음 단계 진행을 위해 이메일로 이동하여 활성화 링크를 통해 로그인하고 암호를 설정하세요.",
    licenses__as_long_as_you_have_unused_licenses_p1: '5. 사용하지 않은 라이선스가 있을 시, 이전 csv 파일에 새 계정을 추가하고 업로드할 수 있습니다. 또한, 이메일 정보를 제외한 나머지 정보는 수정이 가능합니다. 이메일 변경을 원하시면',
    licenses__as_long_as_you_have_unused_licenses_p2: '로 문의하십시오.',
    licenses__errors: '오류',
    orders__orders: "주문",
    orders__order_id: "주문 아이디",
    orders__licenses_count: "라이선스 개수",
    orders__status: "현황",
    orders__withdraw: "철회하기",
    orders__not_applicable: "적용할 수 없음",
    orders__approved: "승인됨",
    orders__create_order: "주문하기",
    orders__create: "만들기",
    orders__create_new_order: "새 주문하기",
    orders__order_details: "주문 상세정보",
    contact_us__message: "메시지"
}


export const LC_VALUES_DASH = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::app:: keys are in sync');
    } else {
        console.log('lc_values::app:: FATAL ISSUE : keys not in sync');
    }
}

//validate_keys_syncing();

