import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent, Box
} from "@material-ui/core";

import { Tab } from "../../../components"

import { spacing } from "@material-ui/system";

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

import Dq24Tab from "./components/Dq24.tab";
import ProgramOverviewTab from "./components/ProgramOverview.tab";
import AboutReportTab from "./components/AboutReport.tab";
import DqImpactRatingTab from "./components/DqImpactRating.tab";
import {Home} from "./../../../pages";
import createTheme from "../../../theme";

import {
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
    jssPreset,
} from "@material-ui/core/styles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const TabsTitle = [
    "DQ Impact Rating",
    "Program Overview",
    "Modules and Dq24",
    "About the report"
]

const TabsComponents = [
    <DqImpactRatingTab/> ,
    <ProgramOverviewTab/>,
    <Dq24Tab/>,
    <AboutReportTab/>,
]
function Dashboard() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {

    }, []);

    return (
        <React.Fragment>
            <Helmet title="Program Overview" />
            <Box mx={"3vw"}>
                <Tab
                    titles = {TabsTitle}>
                    {TabsComponents}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default Dashboard;
