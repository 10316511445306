import React, {useEffect} from 'react';
import { Route, Redirect, useHistory, useLocation  } from 'react-router-dom';
import { useAuth } from "../../context/auth";
import { store } from 'react-notifications-component';
import {LOCAL_STORAGE_KEYS, USER_ACCESS_LEVEL} from "../../utils/constants";
import {logoutUser} from "../../services/auth.service";
import {urlRewriteUtil} from "../Wrapper/common.wrapper";


const PrivateRouteWithLayout = props => {

    const history = useHistory();
    const location = useLocation();
    const { layout: Layout, component: Component, ...rest } = props;
    const { authToken, authUser, setAuthToken, setAuthUser } = useAuth();

    useEffect( () => {
        urlRewriteUtil(location, history);
    }, [] )

    useEffect(()=> {
        if (!authUser) {
            store.addNotification({
                title: "Forbidden",
                message: "you are not authorized to access this page, \nPlease login using valid credentials!",
                type: "danger",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2000,
                    showIcon: true
                }
            });
        }
        if (authUser && !authUser.is_email_verified) {
            store.addNotification({
                title: "Email Not Verified",
                message: "you are not a legitimate user in our system, please verify your email address first",
                type: "danger",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2000,
                    showIcon: true
                }
            });
        }

        // check if user have the access to this portal
        if (authUser) {
            if (![USER_ACCESS_LEVEL.FULL_ACCESS_ANALYTICS_PORTAL, USER_ACCESS_LEVEL.PARTIAL_ACCESS_ANALYTICS_PORTAL].includes(authUser.access_level)) {
                console.log('mmmmmaannn, ')
                store.addNotification({
                    title: "Forbidden",
                    message: "You are not allowed to access this portal, \nPlease login with a valid user.",
                    type: "danger",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
                logoutAction().then(r=>console.log('user forced logged out'))
            }
        }
    }, [authUser]);

    const logoutAction = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            // history.push('/login');
        } catch (e) {
            // already toasted the error
        }
    }

    return (
        <Route
            {...rest}
            render={matchProps => (
                authToken && authUser ? (
                    authUser.is_email_verified || ['/auth/confirm/email', '/logout'].includes(matchProps.location.pathname) ? <Layout>
                            <Component {...matchProps} />
                        </Layout>
                        : <Redirect
                            to={{ pathname: "/auth/confirm/email", referer: matchProps.location}}
                        />
                ) : (
                    <Redirect
                        to={{ pathname: "/login", referer: matchProps.location}}
                    />
                )
            )}
        />
    );

};

export default PrivateRouteWithLayout;
