import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tab } from "../../../components"

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import AcquisitionTab from "./components/Acquisition.tab";
import ImprovementTab from "./components/Improvement.tab";
import IntentionTab from "./components/Intention.tab";
import {getEnhancementEfficacy} from "../../../services/partner.service";
import styled from "styled-components/macro";
import {Box, Paper, Typography as MuiTypography} from "@material-ui/core";
import {spacing} from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

function Efficacy() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Enhancement Efficacy Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementEfficacy(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Enhancement Efficacy --> ', result.data);
                setPageData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement efficacy data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const TabsComponents = pageData ? [
        {
            title: "Skill Acquisition",
            tag: <AcquisitionTab data={pageData["Acquisition"]}/>,
        },
        {
            title: "Skill Improvement",
            tag: <ImprovementTab data={pageData["Improvement"]}/>,
        },
        {
            title: "Intention for change",
            tag: <IntentionTab data={pageData["Intention"]}/>,
        },
    ] : [];

    return (
        <React.Fragment>
            <Helmet title="Enhancement Efficacy"/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        Section 3: Enhancement
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the Potential Areas for Enhancement of the Program
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`3.3 Efficacy Enhancement: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            Check the following detailed analyses of the Program efficacy.
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                {
                    pageData
                    ?
                        <Tab titles={TabsComponents.map((component) => component.title)}>
                            {TabsComponents.map((component) => component.tag)}
                        </Tab>
                    :
                        <Paper container>
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                        </Paper>
                }
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Efficacy;