import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Bar } from "react-chartjs-2";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  padding: 0px;
  height: 300px;
  width: 100%;
`;

function BarChart({theme, labels, values, header = "", legend = "true", line_values= []}) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  let data = {
    labels: labels,
  // #FF1B0A
    datasets: [
      {
        label: 'Participants',
        backgroundColor: theme.palette.tertiary.main,
        borderColor: theme.palette.tertiary.main,
        hoverBackgroundColor: theme.palette.tertiary.main,
        hoverBorderColor: theme.palette.tertiary.main,
        data: values,
        barPercentage: 1,
        categoryPercentage: 0.5,
        order: 1,
      },
    ],
  };


  const line_dataset = {
        type: "line",
        label: "Exposure",
        data: line_values,
        fill: false,
        borderColor: "#233044",
        lineTension: 0.1,
        borderWidth: 4,
        order: 0,
    };

  if(line_values.length > 0)
    data.datasets.push(line_dataset)

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: legend,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          // display: false,
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            maxRotation: 0,
          }
        },
      ],
    },
  };

  return (
      <>
        <ChartWrapper>
          <Typography variant="h4" gutterBottom align={'center'}>
            {header}
          </Typography>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </>


  );
}

export default withTheme(BarChart);
