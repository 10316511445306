import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Box,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent,
    Link
} from "@material-ui/core";

import { Tab } from "../../../components"
import TabChild from "../../../components/Tabs/header";

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import DigitalSkillsTab from "./components/DigitalSkills.tab";
import CyberRisksTab from "./components/CyberRisks.tab";
import {spacing} from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const TabsComponents = (pageLc, org_type) => [
    {
        title: "Digital Skills Level",
        link: `/${pageLc}/${org_type}/home/skills`,
        tag: <DigitalSkillsTab/>,
    },
    {
        title: "Cyber-Risks Level",
        link: `/${pageLc}/${org_type}/home/cyber`,
        tag: <CyberRisksTab/>,
    },

]

const tabsIndices = {
    "skills": 0,
    "cyber": 1,
}

function Baselining() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const org_type = location.pathname.split('/')[2];
    const tab_name = location.pathname.split('/')[4];
    const org_code = authUser.org_code;

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Baselining" />
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2" fontWeight="fontWeightBold">
                        Section 1: Baselining
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the Levels of Digital Skills and Cyber-Risks of the Program's Target Population
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents(pageLc, org_type).filter((component) => !(org_code.toLowerCase() === "amf" && component.title.includes("Cyber"))).map((component) => component.title)}
                    links={TabsComponents(pageLc, org_type).filter((component) => !(org_code.toLowerCase() === "amf" && component.title.includes("Cyber"))).map((component) => component.link)}
                    value={tabsIndices[tab_name]}
                >
                    {TabsComponents(pageLc, org_type).filter((component) => !(org_code.toLowerCase() === "amf" && component.title.includes("Cyber"))).map((component) => component.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Baselining;