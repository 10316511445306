import {GET_APP_LOCALIZE_TEXT} from "./dq_lc_service/lc_service";

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_analytics_auth_token',
    auth_user: 'dq_analytics_auth_user',
    active_org_type: 'dq_analytics_active_org_type',
    active_org_code: 'dq_analytics_active_org_code',
    ACTIVE_LOCALE: 'active_locale'
}

export const THEMES = {
    DEFAULT: "DEFAULT",
    DARK: "DARK",
    LIGHT: "LIGHT",
    BLUE: "BLUE",
    GREEN: "GREEN",
    INDIGO: "INDIGO",
};

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
};

export const USER_ACCESS_LEVEL_VERBOSE = (locale) => { return {
    0: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_na')}`,
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_no_access')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_partial_access_admin')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_full_access_admin')}`,
    4: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_partial_access_analytics')}`,
    5: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_full_access_analytics')}`
}};

export const NESTED_ACL_KEYS = {
    USERS_UPDATE_ENTITY: 'admin_users_update_entity',
    USERS_ADD: 'admin_users_add',
    USERS_REMOVE: 'admin_users_remove',
}

export const ORG_TYPE = {
    DEFAULT: 1,
    COMPANY: 2,
    SCHOOL: 3
}

export const ORG_TYPE_VERBOSE = (locale) => { return {
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_service')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_company')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_school')}`
}};

export const LICENSES_ORDER_STATUS_VERBOSE = (locale) => { return {
    0: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_not_set')}`,
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_pending')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_approved')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_rejected')}`,
    4: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_withdrawn')}`
}};

export const LICENSES_ORDER_STATUS = {
    NOT_SET: 0,
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    WITHDRAWN: 4,
};

export const LOCALES_ENABLED = [
    'en',
    'ko'
]
export const DEFAULT_LOCALE = 'en';

export const DQ24_Titles = {
    "DQ1": "Digital Citizen Identity",
    "DQ2": "Balanced Use of Technology",
    "DQ3": "Behavioural Cyber-Risk Management",
    "DQ4": "Personal Cyber Security Management",
    "DQ5": "Digital Empathy",
    "DQ6": "Digital Footprint Management",
    "DQ7": "Media and Information Literacy",
    "DQ8": "Privacy Management",
    "DQ9": "Digital Co-Creator Identity",
    "DQ10": "Healthy Use of Technology",
    "DQ11": "Content Cyber-Risk Management",
    "DQ12": "Network Security Management",
    "DQ13": "Self-Awareness and Management",
    "DQ14": "Online Communication and Collaboration",
    "DQ15": "Content Creation and Computational Literacy",
    "DQ16": "Intellectual Property Rights Management",
    "DQ17": "Digital Changemaker Identity",
    "DQ18": "Civic Use of Technology",
    "DQ19": "Commercial and Community Cyber-Risk Management",
    "DQ20": "Organisational Cyber Security Management",
    "DQ21": "Relationship Management",
    "DQ22": "Public and Mass Communication",
    "DQ23": "Data and AI Literacy",
    "DQ24": "Participatory Rights Management",
};

export const GRADE_LABELS = {
    0: "Requires Attention",
    85: "Below Average",
    100: "Above Average",
    115: "Excellent",
}