import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const SpacedTypography = styled(MuiTypography)(spacing);

const SpacedUl = styled.ul(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;
  
  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const Typography = styled(SpacedTypography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`
const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

const ColoredUl = styled(SpacedUl)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
`

function AboutReportTab(props) {
    return (<Card mb={3}>
        <CardContent>
            <Typography mb={5} variant="body1" color="grey" wrapped="true" size="14px">
                <Typography component="span" variant="h6">
                    <Bold>
                        {`The DQ Impact Rating Report (the Report) `}
                    </Bold>
                </Typography>
                is a summary report for an education/training program that teaches digital skills to understand its impact.
                The DQ Impact Rating is a compound score that indicates the level of content quality of the program and the impact generated so far in
                advancing digital skills of nations. It is a standardized impact measure based on the global benchmark using the
                IEEE DQ Global Standards for digital literacy, digital skills, and digital readiness (IEEE 3527.1).
                The program was reviewed and analysed by the DQ Institute researchers based on the materials submitted by the Program owner
                and/or publicly available materials.
            </Typography>
            <Typography mb={5} variant="h6" color="grey" wrapped="true">
                <Bold>
                    The report is designed for:
                </Bold>
            </Typography>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                The program owner
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        to understand the levels of their program's quality and implementation strategies
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        to identify the areas of strengths and for further improvement of the program
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                Key stakeholders including policy makers, and funders/investors
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        to have useful data-driven assessment on the program's quality and impact to facilitate their decision making on enhancing online safety and digital skills of target population.
                    </Typography>
                </li>
            </ColoredUl>
            <Typography mt={5} variant="body1" color="grey" wrapped="true" size="14px">
                The report composed of three sections:
            </Typography>
            <ColoredUl mb={5} custom_color={'grey'}>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>Section 1: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{`BASELINING `}</Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - Understanding the levels of Digital Skills and Cyber-Risks of the Program's Target Population
                    </Typography>
                </li>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>Section 2: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{`ASSESSMENT `}</Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - Understanding the DQ Impact Rating of the Program
                    </Typography>
                </li>
                <li>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        <Bold>Section 3: </Bold>
                    </Typography>
                    <Typography component="span" variant="body1" wrapped="true" size="14px">
                        <Bold>{`ENHANCEMENT `}</Bold>
                    </Typography>
                    <Typography component="span" variant="body1" color="grey" wrapped="true" size="14px">
                        - Understanding the Potential Areas for Enhancement of the Program
                    </Typography>
                </li>
            </ColoredUl>
            <Typography mb={5} variant="h6" color="grey" wrapped="true">
                <Bold>
                    Key Terms:
                </Bold>
            </Typography>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                ADII
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        ADII is the Australian Digital Inclusion Index. ADII scores range from 0 to 100. The higher the score, the greater the level of digital inclusion. ADII scores are relative: they allow comparisons across different social groups and geographic areas, and over time.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        Each of the Index dimensions – Access, Affordability and Digital Ability – are equally weighted to derive the total Index score.
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        Total Index scores have been classified into four groups: Highly excluded (45 or below), Excluded (above 45 and below 61), Included (61 and below 80) and Highly included (80 and above).
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                Skill Acquisition
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        when a module or assessment achieves a certain level or higher.
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                Skill Improvement
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        is judged to have improved when it has improved to some extent as a result of comparing the pre/post questions of the module.
                    </Typography>
                </li>
            </ColoredUl>
            <Typography variant="body1" color="grey" wrapped="true" size="14px">
                Intention for Change
            </Typography>
            <ColoredUl custom_color={'grey'}>
                <li>
                    <Typography variant="body1" color="grey" wrapped="true" size="14px">
                        proceeds through the program and, at the end of each module, asks the user if this module makes him think about changing his/her behavior.
                    </Typography>
                </li>
            </ColoredUl>
        </CardContent>
    </Card>);
}

export default AboutReportTab;