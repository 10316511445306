import React from "react";
import {
    DateRangePicker,
    DateRangeDelimiter,
    LocalizationProvider
} from "@material-ui/pickers";

import {
    TextField,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography,
    CardContent as MuiCardContent,
    Box,
    Paper,
    Container,

} from "@material-ui/core";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system"; // choose your lib

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: white;
`;


const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
  height: 100%;
`
export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

export default function BasicDateRangePicker() {
    const [selectedDate, handleDateChange] = React.useState([null, null]);

    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <DateRangePicker
                startText="Start-Date"
                endText="End-Date"
                value={selectedDate}
                onChange={date => handleDateChange(date)}
                renderInput={(startProps, endProps) => {
                    return <>
                        <TextField
                            label="Size"
                            id="outlined-size-small"
                            defaultValue="Small"
                            variant="outlined"
                            size="small"
                            {...startProps}
                            helperText={""}

                        />
                        -
                        <TextField
                            label="Size"
                            id="outlined-size-small"
                            defaultValue="Small"
                            variant="outlined"
                            size="small"
                            {...endProps}
                            helperText={""}
                        />
                    </>
                }}
                // renderInput={({ inputRef, inputProps, InputProps }) => (
                //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
                //         <input ref={inputRef} {...inputProps} value={`${selectedDate[0]} - ${selectedDate[1]}`} />
                //         {InputProps?.endAdornment}
                //     </Box>
                // )}
            />
        </LocalizationProvider>
    );
}