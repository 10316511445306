import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent, Box,
} from "@material-ui/core";

import { Tab } from "../../../components"


import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import ComprehensiveTab from "./components/Comprehensive.tab";
// import CredibilityTab from "./components/Credibility.tab";
// import AppropriateTab from "./components/Appropriate.tab";

import {spacing} from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const TabsComponents = [
    {
        title: "Comprehensiveness",
        tag: <ComprehensiveTab/>,
    },
    // {
    //     title: "Credibility",
    //     tag: <CredibilityTab/>,
    //
    // },
    // {
    //     title: "Appropriateness",
    //     tag: <AppropriateTab/>,
    // },
]

function Enhancement() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (value) => {
        setCurrentTab(value)
    };

    useEffect( () => {

    }, []);

    return (
        <React.Fragment>
            <Helmet title="Enhancement Content"/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        Section 3: Enhancement
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the Potential Areas for Enhancement of the Program
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`3.1 Content Enhancement: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            How well does the Program help improve participants' digital competence across knowledge,
                            skills, and attitudes?
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents.map((component) => component.title)}
                    onChange={handleChange}
                >
                {TabsComponents.map((component) => component.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Enhancement;
