import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getDigitalSkillsData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
} from "@material-ui/core";

import {
    X
} from "react-feather";


import {CustomSelect} from "./Select.comp"

import {Badge} from "./Badge.comp"

import { spacing } from "@material-ui/system";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import RadarChart from "./RadarChart";
import LineChart from "./LineChart";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: white;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitCountries(countries) {

    const sublist = []
    const length = countries.length;
    sublist.push(countries.slice(0, Math.ceil(length/2)));
    sublist.push(countries.slice(Math.ceil(length/2), length));
    return sublist;
}

function getCountriesAverage(countries, avgData) {
    if(countries.length === 0)
        return 0;
    //Else
    let sum = 0;
    countries.forEach((country, index) => {
        sum += Number(avgData[index])
    })
    const avg = (sum / countries.length).toFixed(2);
    return avg;
}

function DigitalSkillsTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const emptyArray = []
    const [defaultCountries, setDefaultCountries] = useState(emptyArray);
    const [additionalCountries, setAdditionalCountries] = useState(emptyArray);
    const [indicators, setIndicators] = useState(emptyArray);

    const [selectedIndicator, setSelectedIndicator] = useState(undefined);
    const [selectedCountries, setSelectedCountries] = useState(emptyArray);

    const addItemToState = (setState, item) => {
        setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        loadPageData().then(d => console.log('Digital Skills Data Fetched!', d));
    }, []);


    function initializeData(data) {
        const first_item = data.countries[0]
        setSelectedCountries([...data.countries])
        setSelectedIndicator(data.categories[0]);
        setDefaultCountries([...data.countries])
        setAdditionalCountries([]);
        setIndicators(data.categories);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getDigitalSkillsData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Digital Skills --> ', result.data);
                initializeData(result.data)
            }

        } catch (e) {
            console.log("Some error occurred while fetching digital skills data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: "Company Average",
            value: pageData && selectedIndicator ? pageData.data[selectedIndicator].program_average : 0,
        },
        {
            title: "National Average",
            value: pageData && selectedIndicator ? pageData.data[selectedIndicator].national_average : 0,
        },
        {
            title: "Global Average",
            value: pageData && selectedIndicator ? pageData.data[selectedIndicator].global_average : 0,
        }
    ]

    function onAddCountry(event, setState) {
        const country = event.target.value;
        removeItemFromState(setAdditionalCountries, country)
        addItemToState(setDefaultCountries, country)
        onSelectCountry(country, true)

        setState((prevState) => ({
            ...prevState,
            [country]: true,
        }));
    }

    function onRemoveCountry(country, setState) {

        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country)
        onSelectCountry(country, false)

        setState((prevState) => ({
            ...prevState,
            [country]: false,
        }));
    }

    function onSelectCountry(item, isChecked) {
        if(isChecked) {
            if(!selectedCountries.includes(item)) {
                addItemToState(setSelectedCountries, item)
            }
        }
        else
        {
            if(selectedCountries.includes(item)) {
                removeItemFromState(setSelectedCountries, item)
            }
        }
    }

    function onSelectIndicator(item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedIndicator(item)
            setState(Object.fromEntries(indicators.map(
                (indicator) => indicator == item ? [indicator, true] : [indicator, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    const program_name = "Name of program"

    const radarRef = React.createRef();

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryData={indicators}
                            secondaryData={[]}
                            onCheckPrimary={onSelectIndicator}
                            onCheckSecondary={()=> {}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}}>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        {selectedIndicator} Covered By (
                                        <ColouredTypography component="span" variant={"h4"} color="orange">
                                            {program_name}
                                        </ColouredTypography>)
                                    </ColouredTypography>
                                    <Grid container spacing={3} align={"center"}>
                                        {badge_data.map((badge) => (
                                                <Grid item xs={12} sm={6} md={12} lg={4}>
                                                    <Badge
                                                        title={badge.title}
                                                        value={badge.value}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                Scores by DQ Indicators
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <RadarChart
                                                ref={radarRef}
                                                data={{
                                                    labels: selectedIndicator ? pageData.data[selectedIndicator].indicators : [],
                                                    graphData: pageData.countries.map((country, index) => {
                                                        return {
                                                            index: defaultCountries.indexOf(country),
                                                            country: country,
                                                            data: [...pageData.data[selectedIndicator].countries_data[country].indicator_scores],
                                                            show: selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                Annual Score Trend
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <LineChart
                                                chartRef={radarRef}
                                                data={{
                                                    labels: pageData.data[selectedIndicator].years,
                                                    graphData: pageData.countries.map((country, index) => {
                                                        return {
                                                            index: defaultCountries.indexOf(country),
                                                            country: country,
                                                            data: [...pageData.data[selectedIndicator].countries_data[country].annual_scores],
                                                            show: selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            : <LoaderWithBackDrop loading={loading}/>
    );
}

export default DigitalSkillsTab;