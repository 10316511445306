import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import ColoredLinearProgress from "./ColouredLinearProgress.comp";
import XLSX from "xlsx";
import {LinearProgressWithLabel} from "./LinearProgressWithLabel.comp";
import {alphaNumericSort, getUniqueValues, predefinedSort} from "../../../../utils/utils";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
    },
    body: {
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const tabName = "Intention for change";
const tabDescription = "Proportion of participants who have shown the intention to positively change behaviour in one or more DQ competences after completing the Program."

function IntentionTab(props) {

    const {categories: panelCategories, age_groups: panelAgeGroup, data: data} = props.data;

    panelAgeGroup.sort(predefinedSort(["Unspecified", "Below 9 Years Old", "10-12 Years Old", "13-14 Years Old", "15-16 Years Old", "Above 16 Years Old"]));

    const selectAllAgesOption = "All Ages"

    const primarySideHeader = "Select a Category"
    const secondarySideHeader= "Select an Age Group"

    const [selectedPrimary, setSelectedPrimary] = useState(panelCategories[0])
    const [selectedSecondary, setSelectedSecondary] = useState([...panelAgeGroup])

    function onSelectPrimary (selected_item, is_checked, state, setState) {
        if(is_checked)
        {
            setSelectedPrimary(selected_item)
            setState(Object.fromEntries(panelCategories.map(
                (category) => category == selected_item ? [category, true] : [category, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [selected_item]: true,
            }));
        }
    }

    function onSelectSecondary (selected_item, is_checked) {
        if(is_checked) {
            if(selected_item == selectAllAgesOption)
            {
                setSelectedSecondary([...panelAgeGroup])
            }
            else
            {
                if(selectedSecondary.includes(selected_item)) //if already selected, clear all
                {
                    setSelectedSecondary([selected_item])
                }
                else
                {
                    setSelectedSecondary([...selectedSecondary, selected_item])
                }
            }
        }
        else
        {
            if(selected_item == selectAllAgesOption)
            {
                setSelectedSecondary([])
            }
            else {
                if (selectedSecondary.includes(selected_item)) {
                    let copyArray = [...selectedSecondary]
                    copyArray.splice(copyArray.indexOf(selected_item), 1)
                    setSelectedSecondary(copyArray)
                }
            }
        }
    }

    const categories = getUniqueValues(selectedSecondary.map((age_group) => Object.keys(data[selectedPrimary][age_group])))

    const modules = getUniqueValues(selectedSecondary.map((age_group) => getUniqueValues(categories.map((category) => Object.keys(data[selectedPrimary][age_group][category])))))

    modules.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["Composite Total"]))

    const subCategories = categories.map((category) => {
        return getUniqueValues(selectedSecondary.map((age_group) => getUniqueValues(modules.map((module) => Object.keys(data[selectedPrimary][age_group][category][module]))))).sort()
    })

    //console.log(categories, modules, subCategories)

    const apiData = {}
    categories.forEach((category, cat_index) => {
        apiData[category] = {}
        modules.forEach((module) => {
            apiData[category][module] = {}
            subCategories[cat_index].forEach((subcat) => {
                apiData[category][module][subcat] = selectedSecondary.map((secondary) => data[selectedPrimary][secondary][category][module][subcat] ? data[selectedPrimary][secondary][category][module][subcat] : 0).reduce((a, b) => a + b, 0);
            })
        })
    })


    //calculating sums

    const sumData = {}

    modules.forEach((module) => {
        const category = categories[0];
        const subcategories = subCategories[0];
        let sum = 0;
        subcategories.forEach((subCategory) => sum += apiData[category][module][subCategory])
        sumData[module] = sum;
    })

    //Initializing table data

    let tableData = categories.map((cat) => {
        return {
            category: cat,
            data: [],
        }
    });

    //filling data from api

    for(let i = 0; i < categories.length; i++)
    {
        let category = categories[i]
        for(let j=0; j < subCategories[i].length; j++)
        {
            let subCategory = subCategories[i][j];
            let module_values = modules.map((module) => apiData[category][module][subCategory])
            let module_percents = modules.map((module) => Math.round(apiData[category][module][subCategory]/sumData[module]*100))
            //Pushing Data into Final Table
            tableData[i].data.push(
                {
                    subcategory: subCategory,
                    values: [...module_values],
                    percents: [...module_percents],
                }
            )
        }
    }

    const tableHeaderData = []

    modules.forEach((module) => {
        tableHeaderData.push({
            header: module,
            total: sumData[module]
        })
    })

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const subcategories = categories;
        panelCategories.forEach((category) => {
            const final_array = []
            let max_column_widths = [];
            subcategories.forEach((subcategory) => {
                final_array.push([subcategory], [], [])
                const headers = Object.keys(data[panelCategories[0]][panelAgeGroup[0]][subcategory]["Composite Total"])
                const columns = ["Age Group", "Skill Code", ...headers]
                columns.forEach((column, index) => {
                    max_column_widths.splice(index, max_column_widths[index] ? 1 : 0, Math.max(column.length, max_column_widths[index] ? max_column_widths[index] : 0))
                })
                final_array.push(columns)
                panelAgeGroup.forEach((age_group) => {
                    const codes = Object.keys(data[category][age_group][subcategory])
                    codes.forEach((code) => {
                        final_array.push([age_group, code, ...headers.map((header) => data[category][age_group][subcategory][code][header])])
                    })
                })
                final_array.push([], [])
            })
            const worksheet = XLSX.utils.aoa_to_sheet(final_array);
            worksheet["!cols"] = max_column_widths.map((col_width) => {
                return { wch: Math.max(10, col_width) }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, category);
        })
        XLSX.writeFile(workbook, "Enhancement Efficacy - Skill Intention For Change .xlsx");
    }


    return (
        <Paper container={"true"}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <SidePanel
                        primaryHeader={primarySideHeader}
                        secondaryHeader={secondarySideHeader}
                        primaryData={[...panelCategories]}
                        secondaryData={[selectAllAgesOption, ...panelAgeGroup]}
                        onCheckPrimary={onSelectPrimary}
                        onCheckSecondary={onSelectSecondary}
                        defaultCheckIndexPrimary={0}
                        defaultCheckIndexSecondary={0}
                        secondarySingleSelectIndex={0}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={9}>
                    <Paper container={"true"} mt={10} mb={5}>
                        <ColouredTypography variant={"body1"} size={"16px"} customcolor={"dimgrey"} textAlign={"center"} mb={5} mx={5}>
                            {tabDescription}
                        </ColouredTypography>
                        <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                            {`${tabName} by ${selectedPrimary} of ${selectedSecondary.length == panelAgeGroup.length ? selectAllAgesOption : (selectedSecondary.length == 0 ? "No Age Group" : selectedSecondary)}`}
                        </Typography>
                        <Box mr={10}>
                            <Grid container spacing={3} style={{alignItems: "center"}}>
                                <Grid item xs={12} sm={2} md={2}>
                                </Grid>
                                <Grid item xs={12} sm={10} md={10}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            {
                                                <TableRow>
                                                    <CustomTableCell align="center">
                                                        <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                        </DefaultColouredTypography>
                                                    </CustomTableCell>
                                                    {
                                                        tableHeaderData.map(
                                                            (item) => {
                                                                return (
                                                                    <CustomTableCell align="center">
                                                                        <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                                            {item.header}
                                                                        </DefaultColouredTypography>
                                                                    </CustomTableCell>
                                                                )
                                                            })
                                                    }
                                                </TableRow>
                                            }
                                        </TableHead>
                                        <TableBody>
                                            {
                                                <CustomTableRow key={"totals"}>
                                                    <CustomTableCell align="center">{"Totals"}</CustomTableCell>
                                                    {
                                                        tableHeaderData.map((item, index) => (
                                                            <CustomTableCell align="center">{item.total}</CustomTableCell>
                                                        ))
                                                    }
                                                </CustomTableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Spacer my={5}/>
                            {
                                tableData.map((item) => {
                                    return <Grid container spacing={3} style={{alignItems: "center"}}>
                                        <Grid item xs={12} sm={2} md={2}>
                                            <Typography variant="h4" align={'center'}>
                                                {item.category}
                                            </Typography>
                                            <Spacer my={10}/>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableBody>
                                                    {
                                                        item.data.map((subItem, index) => (
                                                            <CustomTableRow key={index}>
                                                                <CustomTableCell align="center">{subItem.subcategory}</CustomTableCell>
                                                                {
                                                                    subItem.percents.map((value) => {
                                                                        return <CustomTableCell align={"center"}>
                                                                            <LinearProgressWithLabel value={isNaN(value) ? 0 : value}>
                                                                            </LinearProgressWithLabel>
                                                                        </CustomTableCell>
                                                                    })
                                                                }
                                                            </CustomTableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                                <Spacer my={10}/>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                })
                            }
                            <Box align={"right"}>
                                <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                    Download Data
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default IntentionTab;