import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {
    getAppropriatenessData,
} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import HorizontalBarChart from "./HorizontalBarChart";
import {useAuth} from "../../../../context/auth";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Grid = styled(MuiGrid)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function AgeTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Appropriateness Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getAppropriatenessData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Appropriateness --> ', result.data);
                setPageData(result.data);
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching appropriateness data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: "Content Score",
            value: pageData ? pageData.content_score : "0",
        },
        {
            title: "Appropriateness Score",
            value: pageData ? pageData.appropriate_score : "0",
        },
    ]

    const mainChart = {
        header: "",
        labels: pageData ? pageData.labels : ["DQ 1", "DQ 2", "DQ 3", "DQ 4", "DQ 5", "DQ 6", "DQ 7", "DQ 8", "DQ 20", "DQ 21", "DQ 23", "DQ 24"],
        data: pageData ? pageData.data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }

    function headers_callback(value, index) {
        if(index == 0)
            return "Less Coverage";
        else if(index == 5)
            return "Full Coverage";
        else
            return undefined;
    }


    return (
        pageData ?
        <Paper>
            <Card>
                <CardContent>
                    <Box sx={{ flexGrow: 1 }} align={"right"}>
                        <Grid container spacing={5} align={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} align={"center"} textAlign={"center"}>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    {badge_data.map((badge) => <Spacer mx={"0.5vw"}>
                                            <Badge
                                                title={badge.title}
                                                value={badge.value}
                                            />
                                        </Spacer>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ColouredTypography mt={5} mb={5} variant={"body1"} align={"center"} color={"dimgrey"} size={"16px"} textAlign={"justify"}>
                                    Are the Program's learning experiences (e.g. learning objectives, messages and delivery) age
                                    appropriate for target audience?
                                </ColouredTypography>
                            </Grid>
                        </Grid>
                        <Spacer mb={5}/>
                        <ColouredTypography variant={"h4"} textAlign={"center"}>
                            Curriculum Coverage
                        </ColouredTypography>
                        <Spacer mb={10}/>
                        <Grid container spacing={5} align={"center"} alignItems={"center"}>
                            {/*<Grid item xs={3}>*/}

                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <HorizontalBarChart
                                    labels={mainChart.labels}
                                    values={mainChart.data}
                                    color={true}
                                    threshold={2.5}
                                    datalabels={true}
                                    percentage={false}
                                    xtickslabels={true}
                                    suggestedMax={5}
                                    barsGap={80}
                                    headers_callback={headers_callback}
                                />
                            </Grid>
                        </Grid>
                        <Button mt={5} variant="contained">
                            Download Data
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
        :
        <Paper container>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default AgeTab;