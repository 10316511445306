import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    PreRegister as PreRegisterPage,
    RegisterCompany as RegisterCompanyPage,
    RegisterSchool as RegisterSchoolPage,
    Home as HomePage,
    ChangePassword as ChangePasswordPage,
    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ConfirmEmail as ConfirmEmailPage,

    ContactUs as ContactUsPage,
    Employees as EmployeesPage,
    Classes as EmployeeClassesPage,
    EmployeesPendingApprovals as EmployeesPendingApprovalsPage,
    EmployeesPendingActivation as EmployeesPendingActivationPage,
    Licenses as LicensesPage,
    Orders as OrdersPage,
    UI_Config_Areas as UI_Config_AreasPage,
    UI_Config_Badges as UI_Config_BadgesPage,
    Users_List as Users_ListPage,
    Settings as SettingsPage,
    Callbacks as CallbacksPage,

    ProfilesListPage,
    DqStatsPage,

    NotFound as NotFoundPage
} from './pages';
import {
    Home as Mock_HomePage,
    Baselining as Mock_Baselining,
    BaseliningSkills as Mock_Baselining_Skills,
    BaseliningCyber as Mock_Baselining_Cyber,
    AssessmentContent as Mock_Assessment_Content,
    AssessmentAdoption as Mock_Assessment_Adoption,
    AssessmentEfficacy as Mock_Assessment_Efficacy,
    EnhancementContent as Mock_Enhancement_Content,
    EnhancementAdoption as Mock_Enhancement_Adoption,
    EnhancementEfficacy as Mock_Enhancement_Efficacy,
} from "./pages/PartnerDashboard";

import {
    BaseliningSkills as School_Baselining_Skills,
    BaseliningCyber as School_Baselining_Cyber,
} from "./pages/SchoolDashboard";

import {
    BaseliningSkills as Company_Baselining_Skills,
    BaseliningCyber as Company_Baselining_Cyber,
} from "./pages/CompanyDashboard";

import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED, ORG_TYPE} from "./utils/constants";

const Routes = () => {

    let possible_locales = ""
    LOCALES_ENABLED.forEach((locale, index) => {
        if(index !== 0)
            possible_locales += "|"
        possible_locales += locale
    })
    possible_locales = '(' + possible_locales + ')'

    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);

    return (
        <Switch>
            <Redirect
                exact
                from={`/:lc${possible_locales}`}
                to={`/:lc${possible_locales}/home`}
            />
            {org_type+'' === ORG_TYPE.DEFAULT+'' ?
                <Redirect
                    exact
                    from={`/:lc${possible_locales}/home`}
                    to={`/:lc${possible_locales}/partner/home`}
                />
                :
                org_type+'' === ORG_TYPE.SCHOOL+'' ?
                    <Redirect
                        exact
                        from={`/:lc${possible_locales}/home`}
                        to={`/:lc${possible_locales}/school/home`}
                    />
                    :
                    org_type+'' === ORG_TYPE.COMPANY+'' ?
                        <Redirect
                            exact
                            from={`/:lc${possible_locales}/home`}
                            to={`/:lc${possible_locales}/company/home`}
                        />
                        :
                        null
            }
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/login`}
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/register`}
            />
            <PreAuthRouteWithLayout
                component={PreRegisterPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/pre_register`}
            />
            <PreAuthRouteWithLayout
                component={RegisterSchoolPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/register_school`}
            />
            <PreAuthRouteWithLayout
                component={RegisterCompanyPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/register_company`}
            />
            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/account/recover`}
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path={`/:lc${possible_locales}/auth/reset/password`}
            />
            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/auth/confirm/email`}
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MinimalLayout}
                path={`/:lc${possible_locales}/logout`}
            />

            <PrivateRouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/home`}
            />
            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/password/change`}
            />


            <PrivateRouteWithLayout
                component={ContactUsPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/contact_us`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/employees`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPendingApprovalsPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/employees_approval`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPendingActivationPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/employees_activations`}
            />

            <PrivateRouteWithLayout
                component={LicensesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/licenses`}
            />

            <PrivateRouteWithLayout
                component={OrdersPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/orders`}
            />

            <PrivateRouteWithLayout
                component={ProfilesListPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/non_org_profiles`}
            />

            <PrivateRouteWithLayout
                component={DqStatsPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/non_org_stats`}
            />

            <PrivateRouteWithLayout
                component={UI_Config_AreasPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/ui_config/area`}
            />

            <PrivateRouteWithLayout
                component={UI_Config_BadgesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/ui_config/badge`}
            />

            <PrivateRouteWithLayout
                component={Users_ListPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/users/list`}
            />

            <PrivateRouteWithLayout
                component={CallbacksPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/callbacks`}
            />

            <PrivateRouteWithLayout
                component={SettingsPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/settings`}
            />

            {/* Company Dashboard Endpoints */}

            <Redirect
                exact
                from={`/:lc${possible_locales}/company`}
                to={`/:lc${possible_locales}/company/home`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/company/home`}
                to={`/:lc${possible_locales}/company/home/skills`}
            />

            <PrivateRouteWithLayout
                component={Company_Baselining_Skills}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/company/home/skills`}
            />

            <PrivateRouteWithLayout
                component={Company_Baselining_Cyber}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/company/home/cyber`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/employees/progress`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/employees/scores`}
            />

            {/* School Dashboard Endpoints */}

            <Redirect
                exact
                from={`/:lc${possible_locales}/school`}
                to={`/:lc${possible_locales}/school/home`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/school/home`}
                to={`/:lc${possible_locales}/school/home/skills`}
            />

            <PrivateRouteWithLayout
                component={School_Baselining_Skills}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/school/home/skills`}
            />

            <PrivateRouteWithLayout
                component={School_Baselining_Cyber}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/school/home/cyber`}
            />

            <PrivateRouteWithLayout
                component={EmployeeClassesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/students/classes`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/students/list`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPendingActivationPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/students/activation`}
            />

            <PrivateRouteWithLayout
                component={EmployeesPendingApprovalsPage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/students/approval`}
            />

            {/* Partner Dashboard Endpoints */}

            <PrivateRouteWithLayout
                component={Mock_HomePage}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/partner/program_overview`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/partner`}
                to={`/:lc${possible_locales}/partner/program_overview`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/partner/home`}
                to={`/:lc${possible_locales}/partner/home/skills`}
            />

            <PrivateRouteWithLayout
                component={Mock_Baselining_Skills}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/partner/home/skills`}
            />

            <PrivateRouteWithLayout
                component={Mock_Baselining_Cyber}
                exact
                layout={MainLayout}
                path={`/:lc${possible_locales}/partner/home/cyber`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/partner/assessment`}
                to={`/:lc${possible_locales}/partner/assessment/content`}
            />

            <PrivateRouteWithLayout
                component={Mock_Assessment_Content}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/assessment/content`}
            />

            <PrivateRouteWithLayout
                component={Mock_Assessment_Adoption}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/assessment/reach`}
            />

            <PrivateRouteWithLayout
                component={Mock_Assessment_Efficacy}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/assessment/efficacy`}
            />

            <Redirect
                exact
                from={`/:lc${possible_locales}/partner/enhancement`}
                to={`/:lc${possible_locales}/partner/enhancement/content`}
            />

            <PrivateRouteWithLayout
                component={Mock_Enhancement_Content}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/enhancement/content`}
            />

            <PrivateRouteWithLayout
                component={Mock_Enhancement_Adoption}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/enhancement/reach`}
            />

            <PrivateRouteWithLayout
                component={Mock_Enhancement_Efficacy}
                layout={MainLayout}
                exact
                path={`/:lc${possible_locales}/partner/enhancement/efficacy`}
            />

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />
        </Switch>
    );
};

export default Routes;
