import React from "react";
import {
    DateRangePicker,
    DateRangeDelimiter,
    LocalizationProvider
} from "@material-ui/pickers";

import {
    TextField,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography,
    CardContent as MuiCardContent,
    Box,
    Select,
    Paper,
    Container, MenuItem, FormControl, InputLabel, makeStyles,

} from "@material-ui/core";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginTop: 5
    },
    selectEmpty: {
    },
}));

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: white;
`;


const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size :"default"};
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
  height: 100%;
`

export const CustomSelect = (props) => {
    const classes = useStyles();

    const {title, data, onChange} = props;
    const [selected, setSelected] = React.useState('');

    const handleChange = (event) => {
        //setSelected(event.target.value);
        onChange(event);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
            <Select
                value={selected}
                onChange={handleChange}
                label={title}
            >
                {data.map((dataItem) => <MenuItem value={dataItem}>{dataItem}</MenuItem>)}
            </Select>
        </FormControl>
    );
}