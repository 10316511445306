import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";




export const AreaUIConfServices = {
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/area/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj);
    }
}

export const BadgeUIConfServices = {
    list: (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },
    update: (item) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/ui_config/badge/update',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = item;
        return invokeApi(requestObj);
    }
}

export const AccountServices = {

    list_accounts: async (org_code) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/list',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code
        };
        return invokeApi(requestObj);
    },

    add_account: async (org_code, email_id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/add',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            email_id
        };
        return invokeApi(requestObj);
    },

    remove_account: async (org_code, member_id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/org/accounts/remove',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = {
            org_code,
            member_id
        };
        return invokeApi(requestObj);
    },

}
