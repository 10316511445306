import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tab } from "../../../components"

import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


import ByRegionTab from "./components/ByRegion.tab";
import ByMonthlyTab from "./components/ByMonthly.tab";
import ByModuleTab from "./components/ByModule.tab";
import ByGenderTab from "./components/ByGender.tab";
import ByAgeTab from "./components/ByAge.tab";
import ByADIITab from "./components/ByADII.tab";
import {getEnhancementAdoption} from "../../../services/partner.service";
import {Box, Paper, Typography as MuiTypography} from "@material-ui/core";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";



const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

function Adoption() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const TabsComponents = [
        {
            title: "By Region",
            tag: <ByRegionTab/>,
        },
        {
            title: "By Month",
            tag: <ByMonthlyTab/>,
        },
        {
            title: "By Module",
            tag: <ByModuleTab/>,
        },
        {
            title: "By Gender",
            tag: <ByGenderTab/>,
        },
        {
            title: "By Age",
            tag: <ByAgeTab/>,
        },
        {
            title: "By ADII",
            tag: <ByADIITab/>,
        },
    ]

    return (
        <React.Fragment>
            <Helmet title="Enhancement Adoption"/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        Section 3: Enhancement
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the Potential Areas for Enhancement of the Program
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`3.2 Adoption Enhancement: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            Check the following detailed analyses of the Program reach and find the areas for improvement. The potential areas that require attention to enhance the reach are highlighted in pink.
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents.map((component) => component.title)}
                >
                    {TabsComponents.map((component) => component.tag)}
                </Tab>
            </Box>
        </React.Fragment>
    );
}

export default Adoption;