import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider, Grid, LinearProgress as MuiLinearProgress,
    Typography as MuiTypography,
} from "@material-ui/core";

import DqModuleBadge from './DqModuleBadge.comp';

import { spacing } from "@material-ui/system";
import {toastWarning} from "../../../../utils/utils";
import Button from "@material-ui/core/Button";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {dashboardModulesInfoService} from "../../../../services/stats.service";
import {useAuth} from "../../../../context/auth";
import Box from "@material-ui/core/Box";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../../../utils/constants";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const LinearProgress = styled(MuiLinearProgress)`
  height: 20px;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="70%" mr={3}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="h5">{props.label}</Typography>
            </Box>
        </Box>
    );
}

const MicroBadgeInfoTable = () => {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const [modulesData, setmodulesData] = useState([]);
    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);

    useEffect( () => {
        loadModulesInfoData().then(d => console.log('licenses packs data loaded!', d));
    }, []);

    const loadModulesInfoData = async () => {

        try {

            const result = await dashboardModulesInfoService(authUser.org_code, pageLc);
            console.log('api::d10__resp -> ', result.data)
            if (result.data && result.data.modules) {
                setmodulesData(result.data.modules);
            }

        } catch (e) {
            // error already toasted
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom align={'center'}>
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__dq_assessments')}`}
            </Typography>

            <Card mb={3}>
                <CardContent>
                    <Grid container spacing={6}>
                        <Grid item sm={4}>
                            <DefaultColouredTypography variant="h6" mb={8} ml={6} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__dq_modules')}`}
                            </DefaultColouredTypography>
                        </Grid>
                        <Grid item sm={4}>
                            <DefaultColouredTypography variant="h6" mb={1} ml={4} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__average_score')}`}
                            </DefaultColouredTypography>
                            <Typography variant="h6" mb={4} ml={1} align={'left'} fontWeight="fontWeightBold">
                                {
                                    org_type+'' === ORG_TYPE.SCHOOL+'' ?
                                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__school_national')}`
                                    :
                                        `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__company_national')}`
                                }
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <DefaultColouredTypography variant="h6" mb={8} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__completion_rate')}`}
                            </DefaultColouredTypography>
                        </Grid>
                    </Grid>

                    <Divider style={{
                        height: '4px',
                        marginTop: '-25px',
                        marginBottom: '20px'
                    }} />

                    {
                        modulesData && modulesData.map( module => (
                            <Grid
                                style={{
                                    padding: '16px 0px'
                                }}
                                container spacing={6} alignItems={"center"}>
                                <Grid item sm={4}>
                                    <DqModuleBadge
                                        short_name={module.badge_code}
                                        full_name={module.full_name}
                                        logo={module.icon_url}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography variant="h5" ml={6} align={'left'}>
                                        {module.avg_score}
                                    </Typography>
                                </Grid>
                                <Grid item sm={4}>
                                    {
                                        <div style={{
                                            height: '30px',
                                            marginTop: '9px',
                                            textAlign: 'left'
                                        }}>
                                            <LinearProgressWithLabel
                                                variant="determinate"
                                                value={module.certified_rate}
                                                label={`${module.certified_count}/${module.total_count} (${module.certified_rate.toFixed(2)}%)`}
                                                color={'Secondary'}
                                            />
                                        </div>
                                    }
                                </Grid>
                                    <Typography variant="h6" ml={6} align={'left'}>
                                    {module.full_name}
                                    </Typography>
                            </Grid>
                        ))
                    }

                </CardContent>
            </Card>

        </React.Fragment>

    );
};

export default MicroBadgeInfoTable;
