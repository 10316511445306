import React from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
    id += 1;
    return { id, name, calories, fat, carbs, protein };
}

const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    btn: {
        marginTop: 16
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function ProfilesDataPreviewModal(props) {

    const { open, processData, columns, rows, handleClose } = props

    const classes = useStyles();

    const uploadAction = () => {
        processData();
    }

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>

                        <h2 id="transition-modal-title">{'CSV Profile | Data Preview  of (' + rows.length + ') Records'}</h2>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={uploadAction}
                        >
                            Yes, Upload Data
                        </Button>

                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map( col =>  <TableCell>{col.name}</TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.map(
                                            (item, index) => <TableRow key={index}>
                                                { item.map(cellVal => <TableCell>{cellVal}</TableCell> ) }
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Paper>

                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default ProfilesDataPreviewModal;