import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {getCyberRisksData} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
    Icon, makeStyles,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "./Badge.comp";
import LineChart from "./LineChart";
import {CustomSelect} from "./Select.comp";
import {X} from "react-feather";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>


function splitCountries(countries) {

    const sublist = []
    const length = countries.length;
    sublist.push(countries.slice(0, Math.ceil(length/2)));
    sublist.push(countries.slice(Math.ceil(length/2), length));
    return sublist;
}

function getCountriesAverage(countries, avgData) {
    if(countries.length === 0)
        return 0;
    //Else
    let sum = 0;
    countries.forEach((country, index) => {
        sum += Number(avgData[index])
    })
    const avg = (sum / countries.length).toFixed(2);
    return avg;
}

function CyberRisksTab() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const emptyArray = []
    const [defaultCountries, setDefaultCountries] = useState(emptyArray);
    const [additionalCountries, setAdditionalCountries] = useState(emptyArray);
    const [indicators, setIndicators] = useState(emptyArray);

    const [selectedIndicator, setSelectedIndicator] = useState(undefined);
    const [selectedCountries, setSelectedCountries] = useState(emptyArray);

    const addItemToState = (setState, item) => {
        setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        loadPageData().then(d => console.log('Cyber Risks Data Fetched!', d));
    }, []);


    function initializeData(data) {
        console.log("I was reached")
        const categories = Object.keys(data.data)
        const countries = Object.keys(data.data[categories[0]].countries_data)
        setSelectedCountries([...countries])
        setSelectedIndicator(categories[0]);
        setDefaultCountries([...countries])
        setAdditionalCountries([]);
        setIndicators(categories);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getCyberRisksData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Cyber Risks --> ', result.data);
                initializeData(result.data)
            }

        } catch (e) {
            console.log("Some error occurred while fetching cyber risks data", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: "National Average",
            value: pageData && selectedIndicator ? pageData.data[selectedIndicator].national_average : 0,
        },
        {
            title: "Global Average",
            value: pageData && selectedIndicator ? pageData.data[selectedIndicator].global_average : 0,
        }
    ]

    function onAddCountry(event, setState) {
        const country = event.target.value;
        removeItemFromState(setAdditionalCountries, country)
        addItemToState(setDefaultCountries, country)
        onSelectCountry(country, true)

        setState((prevState) => ({
            ...prevState,
            [country]: true,
        }));
    }

    function onRemoveCountry(country, setState) {
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country)
        onSelectCountry(country, false)

        setState((prevState) => ({
            ...prevState,
            [country]: false,
        }));
    }

    function onSelectCountry(item, isChecked) {
        if(isChecked) {
            if(!selectedCountries.includes(item)) {
                addItemToState(setSelectedCountries, item)
            }
        }
        else
        {
            if(selectedCountries.includes(item)) {
                removeItemFromState(setSelectedCountries, item)
            }
        }
    }

    function onSelectIndicator(item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedIndicator(item)
            setState(Object.fromEntries(indicators.map(
                (indicator) => indicator == item ? [indicator, true] : [indicator, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    const classes = useStyles();

    return (
        pageData ?
        <Paper>
            <Grid container justify="space-between" alignItems="stretch">
                <Grid className={classes.item} item xs={12} sm={3} md={3}>
                    <SidePanel
                        primaryData={indicators}
                        secondaryData={[]}
                        onCheckPrimary={onSelectIndicator}
                        onCheckSecondary={()=> {}}
                    />
                </Grid>
                <Grid className={classes.item} item xs={12} sm={9} md={9}>
                    <Card>
                        <CardContent>
                            <Box sx={{ flexGrow: 1 }}>
                                <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                    Have experience at least one cyber-risk in the past year (%)
                                </ColouredTypography>
                                <Grid container align={"center"}>
                                    {badge_data.map((badge) => (
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <Badge
                                                    title={badge.title}
                                                    value={badge.value}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                <Spacer mb={5}/>
                                <ColouredTypography align={"center"} variant={"h4"}>
                                    Annual Score Trend
                                </ColouredTypography>
                                <Spacer mb={10}/>
                                <LineChart
                                    data={{
                                        labels: pageData.data[selectedIndicator].years,
                                        graphData: (Object.keys(pageData.data[indicators[0]].countries_data)).map((country, index) => {
                                            return {
                                                index: defaultCountries.indexOf(country),
                                                country: country,
                                                data: [...pageData.data[selectedIndicator].countries_data[country].annual_scores],
                                                show: selectedCountries.includes(country) ? true : false,
                                            }
                                        })
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
        : <LoaderWithBackDrop loading={loading}/>
    );
}

export default CyberRisksTab;