import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import {getAssessmentAdoption} from "../../../services/partner.service";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography,
    CardContent as MuiCardContent,
    Box,
    Paper,
    Container, Button as MuiButton,

} from "@material-ui/core";


import {useAuth} from "../../../context/auth";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

import {SidePanel} from "../../../components/SidePanel/SidePanel.comp";
import {spacing} from "@material-ui/system";
import BasicDateRangePicker from "./components/DateRangePicker.comp";
import {Badge} from "./components/Badge.comp";
import BarChart from "./components/BarChart";
import XLSX from "xlsx";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: white;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);
const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const CheckboxItem = (props) => {
    return (
        <>

        </>
    );
}

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const adii = [
    "Highly Excluded",
    "Excluded",
    "Included",
    "Highly Included",
];

const genders = [
    "Female",
    "Male",
    "Other",
]

const age_groups = [
    "Below 9",
    "10-12",
    "13-14",
    "15-16",
    "16+",
]

function Adoption() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);
    const [pageData, setPageData] = useState(undefined);

    const [regions, setRegions] = useState([]);
    const [timePeriods, setTimePeriods] = useState([]);

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadPageData().then(d => console.log('Assessment Adoption Data Fetched!', d));
    }, []);

    const selectAllRegionsOption = "Entire Nation"
    const selectAllTimesOption = "All Years"

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }


    function initializeData(data) {
        setSelectedRegions(data.regions)
        setSelectedTimes(data.time_periods)
        setRegions(data.regions)
        setTimePeriods(data.time_periods)
        setPageData(data);
        //setTimePeriods([...data.time_periods, <BasicDateRangePicker/>])
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentAdoption(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Assessment Adoption --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage("No data found");
            }

        } catch (e) {
            console.log("Some error occurred while fetching assessment adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const primarySideHeader = "Select a Region"
    const secondarySideHeader= "Select Time Period"

    function onSelectRegion (item, isChecked, state, setState) {

        if(isChecked) {
            if(item == selectAllRegionsOption)
            {
                setSelectedRegions([...regions])
            }
            else
            {
                if(selectedRegions.includes(item)) //if already selected, then all regions was selected before, clear all
                {
                    setSelectedRegions([item])
                }
                else
                {
                    setSelectedRegions([...selectedRegions, item])
                }
            }
        }
        else
        {
            if(item == selectAllRegionsOption)
            {
                setSelectedRegions([])
            }
            else {
                if (selectedRegions.includes(item)) {
                    let copyArray = [...selectedRegions]
                    copyArray.splice(copyArray.indexOf(item), 1)
                    setSelectedRegions(copyArray)
                }
            }
        }
    }

    function onSelectTimePeriod (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item == selectAllTimesOption)
            {
                setSelectedTimes([...timePeriods])
            }
            else
            {
                setSelectedTimes([item])
            }
            setState(Object.fromEntries([selectAllTimesOption, ...timePeriods].map(
                (time) => time == item ? [time, true] : [time, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
        // if(isChecked) {
        //     if(item == selectAllTimesOption)
        //     {
        //         setSelectedTimes([...timePeriods])
        //     }
        //     else
        //     {
        //         if(selectedTimes.includes(item)) //if already selected, then all regions was selected before, clear all
        //         {
        //             setSelectedTimes([item])
        //         }
        //         else
        //         {
        //             setSelectedTimes([...selectedTimes, item])
        //         }
        //     }
        // }
        // else
        // {
        //     if(item == selectAllTimesOption)
        //     {
        //         setSelectedTimes([])
        //     }
        //     else {
        //         if (selectedTimes.includes(item)) {
        //             let copyArray = [...selectedTimes]
        //             copyArray.splice(copyArray.indexOf(item), 1)
        //             setSelectedTimes(copyArray)
        //         }
        //     }
        // }
    }

    const badge_data = [
        {
            title: "Total Participation",
            value: pageData ? selectedRegions.map((region) =>
                    selectedTimes.map((time) =>
                        pageData.data[region][time].total_participation
                    ).reduce((total, current) =>
                        total + current
                    , 0)
                ).reduce((total, current) =>
                    total + current
                , 0)
            : 0,
        },
    ]

    const mainChart = {
        header: "",
        labels: months,
        data: pageData ? selectedRegions.map((region) =>
                selectedTimes.map((time) =>
                    pageData.data[region][time].participants_by_month
                ).reduce((total, current) =>
                        total.map((v, i) => v + current[i])
                    , new Array(12).fill(0))
            ).reduce((total, current) =>
                    total.map((v, i) => v + current[i])
                , new Array(12).fill(0))
            : new Array(12).fill(0),
        line_data: pageData ? selectedRegions.map((region) =>
                selectedTimes.map((time) =>
                    pageData.data[region][time].exposure_by_month
                ).reduce((total, current) =>
                        total.map((v, i) => v + current[i])
                    , new Array(12).fill(0))
            ).reduce((total, current) =>
                    total.map((v, i) => v + current[i])
                , new Array(12).fill(0))
            : new Array(12).fill(0),
    }

    const secCharts = [
        {
            header: "ADII",
            labels: adii.map((item) => item.split(' ')),
            data: pageData ? selectedRegions.map((region) =>
                    selectedTimes.map((time) =>
                        pageData.data[region][time].participants_by_adii
                    ).reduce((total, current) =>
                            total.map((v, i) => v + current[i])
                        , new Array(4).fill(0))
                ).reduce((total, current) =>
                        total.map((v, i) => v + current[i])
                    , new Array(4).fill(0))
                : new Array(4).fill(0),
        },
        {
            header: "By Gender",
            labels: genders,
            data: pageData ? selectedRegions.map((region) =>
                    selectedTimes.map((time) =>
                        pageData.data[region][time].participants_by_gender
                    ).reduce((total, current) =>
                            total.map((v, i) => v + current[i])
                        , new Array(3).fill(0))
                ).reduce((total, current) =>
                        total.map((v, i) => v + current[i])
                    , new Array(3).fill(0))
                : new Array(3).fill(0),
        },
        {
            header: "By Age",
            labels: age_groups,
            data: pageData ? selectedRegions.map((region) =>
                    selectedTimes.map((time) =>
                        pageData.data[region][time].participants_by_age
                    ).reduce((total, current) =>
                            total.map((v, i) => v + current[i])
                        , new Array(5).fill(0))
                ).reduce((total, current) =>
                        total.map((v, i) => v + current[i])
                    , new Array(5).fill(0))
                : new Array(5).fill(0),
        },
    ];

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        //By Month
        const month_array = []
        const month_columns = ["Region", "Year", ...months]
        month_array.push(month_columns)
        //By ADII
        const adii_array = []
        const adii_columns = ["Region", "Year", ...adii]
        adii_array.push(adii_columns)
        //By Gender
        const gender_array = []
        const gender_columns = ["Region", "Year", ...genders]
        gender_array.push(gender_columns)
        //By Age
        const age_array = []
        const age_columns = ["Region", "Year", ...age_groups]
        age_array.push(age_columns)

        let max_column_width = 10;
        regions.forEach((region) => {
            timePeriods.forEach((time) => {
                //By Month
                month_array.push([region, time, ...pageData.data[region][time].participants_by_month])
                //By ADII
                adii_array.push([region, time, ...pageData.data[region][time].participants_by_adii])
                //By Gender
                gender_array.push([region, time, ...pageData.data[region][time].participants_by_gender])
                //By Age
                age_array.push([region, time, ...pageData.data[region][time].participants_by_age])
            })
            //Max Column Length of Region
            max_column_width = Math.max(max_column_width, region.length)
        })
        const worksheets_data = [
            {
                name: "Participants By Month",
                columns: month_columns,
                array: month_array,
            },
            {
                name: "Participants By ADII",
                columns: adii_columns,
                array: adii_array,
            },
            {
                name: "Participants By Gender",
                columns: gender_columns,
                array: gender_array,
            },
            {
                name: "Participants By Age",
                columns: age_columns,
                array: age_array,
            },
        ]
        worksheets_data.forEach((data) => {
            const {name, columns, array} = data;
            const worksheet = XLSX.utils.aoa_to_sheet(array);
            worksheet["!cols"] = columns.map((column, index) => {
                return { wch: index == 0 ? max_column_width : Math.max(10, column.length)}
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, name);
        })
        XLSX.writeFile(workbook, "Assessment Adoption.xlsx");
    }

    return (
        <>
            <Helmet title="Assessment Adoption"/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        Section 2: Assessment
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        Understanding the DQ Impact Rating of the Program
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.2 Reach Assessment: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            How widely, efficiently, and inclusively the program has been adopted by target population
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
            </Box>
            {
                pageData ?
                    <>
                        <Paper container>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3}>
                                    <SidePanel
                                        primaryHeader={primarySideHeader}
                                        secondaryHeader={secondarySideHeader}
                                        primaryData={[selectAllRegionsOption, ...regions]}
                                        secondaryData={[selectAllTimesOption, ...timePeriods]}
                                        onCheckPrimary={onSelectRegion}
                                        onCheckSecondary={onSelectTimePeriod}
                                        primarySingleSelectIndex={0}
                                        secondarySingleSelectIndex={0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={9}>
                                    <Card>
                                        <CardContent>
                                            <Box sx={{flexGrow: 1}} align={"right"}>
                                                <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"}
                                                                    align={"center"} alignText={"justify"} my={5}>
                                                    {/*How many individuals have been introduced to the Program (<Box component={"span"} fontWeight={"fontWeightBold"}>exposure</Box>) and have completed at least one*/}
                                                    {/*Module of the Program (<Box component={"span"} fontWeight={"fontWeightBold"}>participation</Box>)?*/}
                                                    How many individuals have completed at least one Module of the Program (<Box
                                                    component={"span"} fontWeight={"fontWeightBold"}>participation</Box>)?
                                                </ColouredTypography>
                                                <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                                    Participation
                                                </ColouredTypography>
                                                <Grid container spacing={3} align={"center"} alignItems={"center"}>
                                                    {badge_data.map((badge) => (
                                                            <Grid item xs={12} sm={5} md={12} lg={3}>
                                                                <Badge
                                                                    title={badge.title}
                                                                    value={badge.value}
                                                                />
                                                            </Grid>
                                                        )
                                                    )}
                                                    <Grid item xs={12} sm={7} md={12} lg={9}>
                                                        <BarChart
                                                            labels={mainChart.labels}
                                                            values={mainChart.data}
                                                            //line_values={mainChart.line_data}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"}
                                                                    align={"center"} alignText={"justify"} my={5}>
                                                    Has the Program been used inclusively across different gender, region, and
                                                    SES group?
                                                </ColouredTypography>
                                                <Spacer my={5}/>
                                                <Grid container spacing={5} align={"center"} alignItems={"center"}>
                                                    {secCharts.map((chart) => (
                                                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                                                <Paper variant={"elevation"}>
                                                                    <BarChart
                                                                        header={chart.header}
                                                                        labels={chart.labels}
                                                                        values={chart.data}
                                                                        legend={false}
                                                                    />
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                                <Button mt={10} variant="contained" onClick={onClickDownload}>
                                                    Download Data
                                                </Button>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                :
                    <Paper container>
                        <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    </Paper>
            }
            <Box mx={"3vw"} my={"2vw"}>
                <ColouredTypography variant={"body1"} size={"16px"} align={"center"}>
                    {`Learn more the detailed results of the analysis, please check `}
                    <a href={"/partner/enhancement/reach"} style={{color: "#25b7db"}}>
                        Section 3.2. Enhancement Reach.
                    </a>
                </ColouredTypography>
            </Box>
            <LoaderWithBackDrop loading={loading}/>
        </>
    );
}

export default Adoption;
